import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useMemo } from "react";
import { Grid } from "@mui/material";
import { useLazyCountLegendQuery } from "shared/redux/endpoints/calendar";
import PropTypes from "prop-types";
import moment from "moment";
import { Loading } from "../Loading";
import { titleTypography } from "shared/styles/style";
import { descriptionTypography } from "shared/styles/style";
import InfoIcon, { TooltipIconColor, TooltipType } from "components/InfoIcon";
import { teacherInfoIconBlock } from "components/InfoIcon/teacherInfoIcon";
import makeStyles from "pages/style";

const timeRangeCalendar = [
  {
    label: "Lesson",
    color: "#2576B9",
  },
  {
    label: "PT demo",
    color: "#EABEFF",
  },
  {
    label: "Progress report",
    color: "#F19ED2",
  },
  {
    label: "Daily report",
    color: "springgreen",
  },
  {
    label: "Report",
    color: "springgreen",
  },
  {
    label: "Other Task",
    color: "orange",
  },
  {
    label: "Feedback",
    color: "#0DCAF0",
  },
  {
    label: "GradeBook report",
    color: "aqua",
  },
  {
    label: "Test and EOCC",
    color: "#F4CE14",
  },
];

function Position({ start, end }) {
  const classes = makeStyles();
  const [trigger, { data, isFetching }] = useLazyCountLegendQuery();

  useEffect(() => {
    trigger({ start: moment(start).format("DD-MM-YYYY"), end: moment(end).format("DD-MM-YYYY") });
  }, [start, end]);

  const groupedData = useMemo(() => {
    if (data?.data) {
      const dailyReport = data?.data.find((item) => item.label === "Daily report");
      const progressReport = data?.data.find((item) => item.label === "Progress report");
      const gradebookReport = data?.data.find((item) => item.label === "GradeBook report");

      return [
        ...data?.data.filter(
          (item) =>
            item.label !== "Daily report" &&
            item.label !== "Progress report" &&
            item.label !== "GradeBook report"
        ),
        {
          label: "Report",
          number: dailyReport?.number + progressReport?.number + gradebookReport?.number,
        },
      ].map((item) => ({
        ...item,
        label: item.label === "Student at risk task" ? "Other Task" : item.label,
      }));
    }
    return [];
  }, [data]);

  return (
    <MDBox>
      <InfoIcon
        type={TooltipType.TYPE_1}
        teacherInfoIconBlock={teacherInfoIconBlock.BREAKDOWN_UNDER_SMALL_CALENDAR}
        color={TooltipIconColor.BLUE}
        className={classes.containerInfoIconWithHeading}
      >
        <MDBox>
          <MDTypography
            sx={({ breakpoints }) => ({
              ...titleTypography(breakpoints),
              lineHeight: 1.35,
              letterSpacing: "-0.8px",
              textAlign: "left",
            })}
          >
            Selected time range calendar
          </MDTypography>
        </MDBox>
      </InfoIcon>
      <MDBox
        sx={({ breakpoints }) => ({
          width: "100%",
          padding: "4px",
          marginTop: "25px",

          [breakpoints.down(1600)]: {
            marginTop: "16px",
          },
        })}
      >
        {isFetching ? (
          <MDBox>
            <Loading />
          </MDBox>
        ) : (
          groupedData.map((item, index) => {
            return (
              <Grid
                key={index}
                container
                sx={{ width: "100%", borderBottom: "1px solid #DEE2E6" }}
                alignItems="center"
                p={0.5}
              >
                <Grid xs="auto" item>
                  <MDBox
                    sx={({ breakpoints, functions: { pxToRem } }) => ({
                      width: pxToRem(24),
                      height: pxToRem(24),
                      borderRadius: pxToRem(8),
                      background:
                        timeRangeCalendar.find((_item) => _item.label === item.label)?.color ??
                        "black",

                      [breakpoints.down(1600)]: {
                        width: pxToRem(20),
                        height: pxToRem(20),
                        borderRadius: pxToRem(7),
                      },
                    })}
                  ></MDBox>
                </Grid>
                <Grid xs item ml={2}>
                  <MDTypography
                    sx={({ breakpoints }) => ({
                      ...descriptionTypography(breakpoints),
                      lineHeight: 1.625,
                      letterSpacing: "-0.8px",
                    })}
                  >
                    {item.label}
                  </MDTypography>
                </Grid>
                <Grid xs="auto" item>
                  <MDTypography
                    sx={({ breakpoints, typography }) => ({
                      ...descriptionTypography(breakpoints),
                      lineHeight: 1.25,
                      letterSpacing: "-0.8px",
                    })}
                  >
                    {("0" + item.number ?? 0).slice(-2)}
                  </MDTypography>
                </Grid>
              </Grid>
            );
          })
        )}
      </MDBox>
    </MDBox>
  );
}

Position.propTypes = {
  start: PropTypes.any,
  end: PropTypes.any,
};
export default Position;
