import { CircularProgress, Grid, Icon, Table, TableBody, TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import MDButton from "components/MDButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSubmitFeedbackMutation } from "shared/redux/endpoints/assistant";
import { useEffect, useState } from "react";
import moment from "moment";
import { useSubmitFeedbackHubMutation } from "shared/redux/endpoints/assistant";
import { useSelector } from "react-redux";
import { selectAuth } from "shared/redux/slices/authSlice";
import { useLazyGetOoolabPendingReportQuery } from "shared/redux/endpoints/sso";

export const colorsMap = [
  {
    status: "New",
    color: "#5E72E4",
  },
  {
    status: "New overdue",
    color: "#8392AB",
  },
  {
    status: "Overdue",
    color: "#8392AB",
  },
  {
    status: "In progress",
    color: "#11CDEF",
  },
  {
    status: "In progress overdue",
    color: "#2DCE89",
  },
  {
    status: "Completed overdue",
    color: "#F5365C",
  },
  {
    status: "Completed on time",
    color: "#DF25FD",
  },
  {
    status: "Cancelled",
    color: "#26355D",
  },
];

const TextareaAutosize = styled(BaseTextareaAutosize)(
  () => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'Lexend', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  border: 0;
  outline: none!important;
  background: #F9F9F9;
  margin-bottom: 12px;
`
);

const Item = ({ title = "", value = "", valueBold = false, colspan }) => {
  return (
    <MDBox component="td" colspan={colspan}>
      <MDTypography
        variant="span"
        sx={({ breakpoints, typography }) => ({
          fontFamily: typography.lexendFont,
          fontSize: 14,
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: 1.5,
          letterSpacing: "0.42px",
          color: "#8392AB",

          [breakpoints.down(1600)]: {
            fontSize: 10,
          },
        })}
      >
        {title}:{" "}
        <MDTypography
          variant="span"
          sx={({ breakpoints, typography }) => ({
            fontFamily: typography.lexendFont,
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: valueBold ? 700 : 400,
            lineHeight: 1.5,
            letterSpacing: "0.42px",
            color: "#8392AB",
            [breakpoints.down(1600)]: {
              fontSize: 10,
            },
          })}
        >
          {value}
        </MDTypography>
      </MDTypography>
    </MDBox>
  );
};

Item.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  valueBold: PropTypes.bool,
  colspan: PropTypes.number,
};

export const TodoDetail = ({ data, onClose, type, refetch }) => {
  const [trigger] = useLazyGetOoolabPendingReportQuery();
  const [submit, { isLoading, isSuccess }] = useSubmitFeedbackMutation();
  const [submitHub] = useSubmitFeedbackHubMutation();
  const [comment, setComment] = useState("");
  const mobileMatches = useMediaQuery("(max-width:768px)");
  const auth = useSelector(selectAuth);

  useEffect(() => {
    setComment(data?.feedback?.feedbackNoteEntity?.comment ?? "");
  }, [data]);

  return (
    <Grid item xs={mobileMatches ? 12 : 9} mt={mobileMatches ? 2 : 0}>
      <MDBox
        sx={({ breakpoints, functions: { pxToRem } }) => ({
          background: "#EDF2F7",
          padding: pxToRem(24),
          borderRadius: pxToRem(15),
          marginTop: 0,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",

          [breakpoints.down(1600)]: {
            marginLeft: 0,
            padding: pxToRem(16),
          },
        })}
      >
        <MDBox display="flex" justifyContent="space-between">
          <MDTypography
            sx={({ breakpoints, typography }) => ({
              fontFamily: typography.lexendFont,
              fontSize: 16,
              lineHeight: 1.625,
              fontWeight: 700,
              letterSpacing: "-0.8px",
              marginBottom: "20px",

              [breakpoints.down(1600)]: {
                fontSize: 13,
                marginBottom: "10px",
              },
            })}
          >
            Task detail
          </MDTypography>
          <MDBox
            sx={() => ({
              cursor: "pointer",
            })}
            onClick={onClose}
          >
            <Icon>close</Icon>
          </MDBox>
        </MDBox>
        {type === "Feedback" ? (
          <MDBox
            sx={({ breakpoints, functions: { pxToRem } }) => ({
              background: "#ffffff",
              padding: pxToRem(24),
              borderRadius: pxToRem(15),
              borderBottom: pxToRem(20),

              [breakpoints.down(1600)]: {
                padding: pxToRem(16),
                borderRadius: pxToRem(8),
                borderBottom: pxToRem(12),
              },
            })}
          >
            <MDBox
              sx={({ functions: { pxToRem } }) => ({
                padding: `${pxToRem(4)} ${pxToRem(8)}`,
                borderRadius: pxToRem(6),
                marginBottom: pxToRem(8),
                width: "fit-content",
                background: colorsMap.find((item) => item.status === data.status)?.color ?? "black",
              })}
            >
              <MDTypography
                sx={({ breakpoints, typography }) => ({
                  fontFamily: typography.lexendFont,
                  fontSize: 12,
                  lineHeight: 1,
                  fontWeight: 700,
                  color: "#FFFFFF",
                  textTransform: "uppercase",

                  [breakpoints.down(1600)]: {
                    fontSize: 9,
                  },
                })}
              >
                {data.status}
              </MDTypography>
            </MDBox>

            <MDTypography
              sx={({ breakpoints, typography }) => ({
                fontFamily: typography.lexendFont,
                fontSize: 16,
                lineHeight: 1.625,
                fontWeight: 700,
                letterSpacing: "-0.8px",
                marginBottom: "20px",
                color: "#0041A3",

                [breakpoints.down(1600)]: {
                  fontSize: 12,
                  marginBottom: "10px",
                },
              })}
            >
              Subject: {data?.feedback?.feedBackSubject}
            </MDTypography>
            <MDBox width="100%">
              <MDBox
                data-horizontal-scroll
                sx={({ functions: { pxToRem } }) => ({
                  overflowX: "scroll",
                  width: "calc(100% + 14px)",
                  background: "#fff",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                })}
              >
                <Table
                  sx={({ typography, functions: { pxToRem } }) => ({
                    color: "#333",
                    height: "100%",
                    width: "800px",
                    minWidth: "100%",
                    fontFamily: typography.lexendFont,
                    fontSize: 14,
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: pxToRem(0.42),
                  })}
                >
                  <TableBody
                    sx={({ functions: { pxToRem } }) => ({
                      maxHeight: pxToRem(454),
                      overflowY: "scroll",
                    })}
                  >
                    <TableRow
                      sx={({ breakpoints }) => ({
                        height: "37px",
                        textAlign: "left",
                        borderBottom: "1px solid #DEE2E6",
                        marginBottom: "16px",

                        [breakpoints.down(1600)]: {
                          height: "30px",
                          marginBottom: "12px",
                        },
                      })}
                    >
                      <Item title="Student Name" value={data?.feedback?.studentName} />
                      <Item title="Center" value={data?.feedback?.centerName} />
                      <Item title="Class" value={data?.feedback?.className} />
                    </TableRow>
                    <TableRow
                      sx={({ breakpoints }) => ({
                        height: "37px",
                        textAlign: "left",
                        borderBottom: "1px solid #DEE2E6",
                        marginBottom: "16px",

                        [breakpoints.down(1600)]: {
                          height: "30px",
                          marginBottom: "12px",
                        },
                      })}
                    >
                      <Item title="Complete status" />
                      <Item title="Tracking status" value={data?.feedback?.feedBackTrackingName} />
                    </TableRow>
                    <TableRow
                      sx={({ breakpoints }) => ({
                        height: "37px",
                        textAlign: "left",
                        borderBottom: "1px solid #DEE2E6",
                        marginBottom: "16px",

                        [breakpoints.down(1600)]: {
                          height: "30px",
                          marginBottom: "12px",
                        },
                      })}
                    >
                      <Item title="Category" value="Schedule" />
                      <Item title="Type of action" value="Request" />
                      <Item title="Feedback type" value={data?.feedback?.feedBackSubject} />
                    </TableRow>
                    <TableRow
                      sx={({ breakpoints }) => ({
                        height: "37px",
                        textAlign: "left",
                        borderBottom: "1px solid #DEE2E6",
                        marginBottom: "16px",

                        [breakpoints.down(1600)]: {
                          height: "30px",
                          marginBottom: "12px",
                        },
                      })}
                    >
                      <Item title="Assign to team CRO" value={data?.feedback?.modifiedByName} />
                      <Item title="Assign to Center" value="Hai Anh" />
                      <Item title="Verify by CM" value="No" />
                    </TableRow>
                    <TableRow
                      sx={({ breakpoints }) => ({
                        height: "37px",
                        textAlign: "left",
                        borderBottom: "1px solid #DEE2E6",
                        marginBottom: "16px",

                        [breakpoints.down(1600)]: {
                          height: "30px",
                          marginBottom: "12px",
                        },
                      })}
                    >
                      <Item title="Received date" value="12/02/2024" />
                      <Item title="Created by" value={data?.feedback?.createdByName} />
                      <Item
                        title="Deadline"
                        value={moment(data?.deadlineDate).format("DD-MM-YYYY HH:mm")}
                      />
                    </TableRow>
                    <TableRow
                      sx={({ breakpoints }) => ({
                        height: "37px",
                        textAlign: "left",
                        borderBottom: "1px solid #DEE2E6",
                        marginBottom: "16px",

                        [breakpoints.down(1600)]: {
                          height: "30px",
                          marginBottom: "12px",
                        },
                      })}
                    >
                      <Item title="Teacher" value="lyly@apollo.edu.vn" valueBold />
                      <Item title="Is random check" value="--" valueBold />
                      <Item title="Note" value="normal" />
                    </TableRow>
                    <TableRow
                      sx={({ breakpoints }) => ({
                        height: "37px",
                        textAlign: "left",
                        borderBottom: "1px solid #DEE2E6",
                        marginBottom: "16px",

                        [breakpoints.down(1600)]: {
                          height: "30px",
                          marginBottom: "12px",
                        },
                      })}
                    >
                      <Item
                        colspan={5}
                        title="Feedback details"
                        value={data?.feedback?.feedBackContent}
                      />
                    </TableRow>
                  </TableBody>
                </Table>
              </MDBox>
              <MDBox>
                <MDTypography
                  sx={({ breakpoints, typography, palette }) => ({
                    fontFamily: typography.lexendFont,
                    fontSize: 14,
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: 1.5,
                    letterSpacing: "0.42px",
                    color: palette.textColors.secondary,
                    marginBottom: "24px",

                    [breakpoints.down(1600)]: {
                      fontSize: 12,
                      marginBottom: "12px",
                    },
                  })}
                >
                  Comment:
                </MDTypography>

                <TextareaAutosize
                  aria-label="comment"
                  minRows={3}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </MDBox>
              <MDBox
                display="flex"
                justifyContent="flex-end"
                sx={{ fontFamily: "Lexend, 'sans-serif' !important" }}
              >
                <MDButton
                  sx={({ breakpoints, typography }) => ({
                    backgroundImage: "linear-gradient(121.78deg, #11CDEF 9.06%, #1171EF 88.15%)",
                    color: "#fff",
                    display: "block",
                    fontFamily: typography.lexendFont,
                    fontSize: 12,
                    lineHeight: 1.5,
                    fontWeight: 700,

                    [breakpoints.down(1600)]: {
                      fontSize: 10,
                      maxHeight: "30px",
                    },
                  })}
                  onClick={async () => {
                    await submit({
                      feedbackId: data.feedback.feedBackId,
                      comment,
                    });
                    await submitHub({
                      feedbackId: data.feedback.feedBackId,
                      teacher_email: auth.user.email,
                      response_content: comment,
                    });
                    await refetch?.();
                  }}
                >
                  {isLoading ? <CircularProgress color="inherit" /> : null} SAVE
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        ) : (
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            sx={({ breakpoints }) => ({
              background: "white",
              borderRadius: "16px",
              [breakpoints.down(1600)]: {
                borderRadius: "8px",
              },
            })}
          >
            <MDTypography
              sx={({ breakpoints, typography, functions: { pxToRem } }) => ({
                fontFamily: typography.lexendFont,
                fontSize: 16,
                fontWeight: 700,
                lineHeight: 1.625,
                color: "#344767",
                letterSpacing: "-0.8px",

                [breakpoints.down(1600)]: {
                  fontSize: 12,
                },
              })}
            >
              To complete your Report task, go to LMS
            </MDTypography>
            <MDButton
              sx={({ breakpoints, typography }) => ({
                backgroundImage: "linear-gradient(121.78deg, #11CDEF 9.06%, #1171EF 88.15%)",
                color: "#fff",
                fontFamily: typography.lexendFont,
                fontSize: 12,
                lineHeight: 1.5,
                fontWeight: 700,
                ml: 1,

                [breakpoints.down(1600)]: {
                  fontSize: 8,
                  padding: "4px 12px !important",
                  minHeight: "32px!important",
                },
              })}
              onClick={async () => {
                const res = await trigger();
                if (res?.data?.data?.url) {
                  window.open(res?.data?.data?.url, "_blank");
                }
              }}
            >
              ACCESS LMS
            </MDButton>
          </MDBox>
        )}
      </MDBox>
    </Grid>
  );
};

TodoDetail.propTypes = {
  data: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string,
  refetch: PropTypes.any,
};
