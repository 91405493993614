import MDBox from "components/MDBox";
import { Tooltip } from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";

import "./custom.css";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

import { boxListStyle, tableStyle, descBoxStyle, buttonStyle } from "./style";
import { useEffect, useState } from "react";
import { actions } from "./data";
import PropTypes from "prop-types";

function TableContent({
  data,
  dataClass,
  page,
  setPage,
  setTotalPage,
  handlePrev,
  handleNext,
  currentPage,
  setCurrentPage,
  isLoading,
  isEmpty,
}) {
  const listDataClass = dataClass?.data?.data || [];
  const listStudentAtRisk = data?.data?.data || [];
  const classCodePresent = listStudentAtRisk[0]?.classCode;
  const totalPage = data?.data?.totalPage ?? 0;
  const [dataTable, setDataTable] = useState({
    columns: [
      { Header: "Student", accessor: "student", width: "15%" },
      { Header: "Student code", accessor: "studentCode", width: "15%" },
      { Header: "Nickname", accessor: "nickname", width: "10%" },
      { Header: "Class", accessor: "class", width: "15%" },
      { Header: "Criteria", accessor: "criteria", width: "25%" },
      { Header: "Duration", accessor: "duration", width: "10%" },
      { Header: "Suggestion action", accessor: "suggest", width: "15%" },
    ],

    rows: [],
  });

  useEffect(() => {
    if (data) {
      const customDataRow = [...listStudentAtRisk].map((item) => {
        const tooltipContent = renderSuggestions(
          ["engagementStatus", "homeLearningStatus", "performanceStatus"].filter(
            (title) => item[title] === "OFFTRACK"
          ),
          parseInt(item?.continuousStatus?.[0] ?? "0")
        );

        const isShowTooltip = tooltipContent.filter((item) => item).length > 0;

        return {
          student: item?.studentName,
          nickname: item?.nickName ?? "",
          studentCode: item?.studentCode,
          class: item.className,
          criteria: (
            <MDTypography sx={(theme) => descBoxStyle(theme)}>
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={1.5}
                width="100%"
                height="100%"
              >
                {["engagementStatus", "homeLearningStatus", "performanceStatus"].map(
                  (title, index1) => {
                    if (item[title] === "OFFTRACK")
                      return (
                        <MDBox key={index1} sx={(theme) => buttonStyle(theme)}>
                          {title
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())
                            .replace("Status", "")}
                        </MDBox>
                      );
                  }
                )}
              </MDBox>
            </MDTypography>
          ),
          duration: (
            <MDTypography sx={(theme) => descBoxStyle(theme)}>
              {`${
                item.continuousStatus.includes(0) || item.continuousStatus.includes(1)
                  ? item?.continuousStatus
                  : `${item.continuousStatus}S`
              }`}
            </MDTypography>
          ),
          suggest: isShowTooltip ? (
            <Tooltip arrow={false} placement="left" title={tooltipContent}>
              <MDTypography sx={{ cursor: "pointer" }}>
                <RemoveRedEye />
              </MDTypography>
            </Tooltip>
          ) : (
            <MDTypography sx={{ cursor: "pointer" }}>
              <RemoveRedEye />
            </MDTypography>
          ),
        };
      });
      setDataTable((dataTable) => ({
        ...dataTable,
        rows: customDataRow || [],
      }));
    }
  }, [data]);

  const renderSuggestions = (items, timeRange) => {
    return items.map((item, key) => {
      const itemKey = `element-${key}`;
      let result;
      if (item === "performanceStatus") {
        result = actions
          .filter((action) => action["Offtrack category"] === "Performance Offtrack")
          .reduceRight(
            (greatest, obj) =>
              obj["Offtrack duration"] <= timeRange &&
              (greatest === null || obj["Offtrack duration"] >= greatest["Offtrack duration"])
                ? obj
                : greatest,
            null
          );
      } else if (item === "engagementStatus") {
        result = actions
          .filter((action) => action["Offtrack category"] === "Engagement")
          .reduceRight(
            (greatest, obj) =>
              obj["Offtrack duration"] <= timeRange &&
              (greatest === null || obj["Offtrack duration"] >= greatest["Offtrack duration"])
                ? obj
                : greatest,
            null
          );
      } else if (item === "homeLearningStatus") {
        result = actions
          .filter((action) => action["Offtrack category"] === "Home Learning")
          .reduceRight(
            (greatest, obj) =>
              obj["Offtrack duration"] <= timeRange &&
              (greatest === null || obj["Offtrack duration"] >= greatest["Offtrack duration"])
                ? obj
                : greatest,
            null
          );
      } else return null;

      if (!result?.Action) return null;

      return (
        <MDTypography
          key={itemKey}
          color="text"
          verticalAlign="middle"
          textAlign="left"
          sx={(theme) => {
            const {
              palette,
              typography,
              breakpoints,
              functions: { pxToRem },
            } = theme;

            return {
              fontFamily: typography.lexendFont,
              fontSize: 14,
              fontWeight: 600,
              lineHeight: 1.37,
              letterSpacing: pxToRem(-0.8),
              color: palette.white.main,
              marginBottom: "12px",

              [breakpoints.down(1600)]: {
                fontSize: 12,
              },
            };
          }}
        >
          {result?.Action}
        </MDTypography>
      );
    });
  };

  return (
    <MDBox sx={(theme) => tableStyle(theme)}>
      <MDBox sx={(theme) => boxListStyle(theme)}>
        <DataTable
          table={dataTable}
          entriesPerPage={false}
          isSorted={false}
          currentPage={currentPage}
          handlePrev={handlePrev}
          handleNext={handleNext}
          totalPage={totalPage}
          setCurrentPage={setCurrentPage}
          isLoading={isLoading}
          isEmpty={isEmpty}
        />
      </MDBox>
    </MDBox>
  );
}

TableContent.propTypes = {
  data: PropTypes.any,
  dataClass: PropTypes.any,
  page: PropTypes.any,
  setPage: PropTypes.any,
  setTotalPage: PropTypes.any,
  handlePrev: PropTypes.any,
  handleNext: PropTypes.any,
  currentPage: PropTypes.any,
  setCurrentPage: PropTypes.func,
  isLoading: PropTypes.bool,
  isEmpty: PropTypes.bool,
};

export default TableContent;
