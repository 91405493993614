import { BorderBottom } from "@mui/icons-material";
import { titleTypography } from "shared/styles/style";
import { descriptionTypography } from "shared/styles/style";

export const dateTimeStyle = ({ breakpoints, typography }) => ({
  ...descriptionTypography(breakpoints),
  textAlign: "center",
  lineHeight: 1.5,
});

export const closeIconStyle = (
  { typography: { size }, palette: { dark, white }, functions: { pxToRem } },
  { darkMode }
) => ({
  fontSize: `${size.md} !important`,
  color: darkMode ? white.main : dark.main,
  stroke: "currentColor",
  strokeWidth: "2px",
  cursor: "pointer",
  transform: "translateY(5px)",
  position: "absolute",
  top: pxToRem(24),
  right: pxToRem(24),
});

export const titleStyle = ({ breakpoints, palette, typography, functions: { pxToRem } }) => ({
  ...titleTypography(breakpoints),
  lineHeight: 1.37,
  letterSpacing: pxToRem(-0.8),
});

export const todayContainerStyle = ({ functions: { pxToRem } }) => ({
  maxHeight: pxToRem(254),
  overflowY: "auto",

  "&::-webkit-scrollbar": {
    width: pxToRem(6),
  },

  "&::-webkit-scrollbar-thumb": {
    background: "#8898aa",
    borderRadius: pxToRem(3),
  },
});

export const titleOderStyle = ({ breakpoints, palette, typography, functions: { pxToRem } }) => ({
  ...titleTypography(breakpoints),
  lineHeight: 1.37,
  letterSpacing: pxToRem(-0.8),
  position: "sticky",
  top: 0,
  backgroundColor: palette.white.main,
  zIndex: 1000,
  borderBottom: "1px solid #E9ECEF",
  paddingBottom: pxToRem(10),

  [breakpoints.down(1367)]: {
    fontSize: 14,
  },
});

export const notiItemStyle = ({ functions: { pxToRem } }, { unread = true }) => ({
  padding: pxToRem(8),
  display: "flex",
  alignItems: "center",
  gap: pxToRem(16),
  cursor: "pointer",

  padding: pxToRem(8),
  borderRadius: pxToRem(8),

  ...(unread ? { background: "#DDF1FF" } : { background: "#F6F9FC" }),

  "&:not(:last-child)": {
    marginBottom: pxToRem(16),
  },
});

export const statusStyle = ({ functions: { pxToRem } }, { unread = true }) => ({
  width: pxToRem(8),
  height: pxToRem(8),
  backgroundColor: unread ? "#1576bc" : "#E9ECEF",
  borderRadius: pxToRem(4),
});
