export default () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3341 3.86772H18.6674C18.7407 3.86772 18.8007 3.9278 18.8007 4.00106V28.0011C18.8007 28.0743 18.7407 28.1344 18.6674 28.1344H13.3341C13.2608 28.1344 13.2007 28.0743 13.2007 28.0011V4.00106C13.2007 3.9278 13.2608 3.86772 13.3341 3.86772Z"
        stroke="#1576BC"
        strokeWidth="2.4"
      />
      <path
        d="M1.33333 18.5363H6.66667C6.73992 18.5363 6.8 18.5964 6.8 18.6696V28.003C6.8 28.0762 6.73992 28.1363 6.66667 28.1363H1.33333C1.26008 28.1363 1.2 28.0762 1.2 28.003V18.6696C1.2 18.5964 1.26008 18.5363 1.33333 18.5363Z"
        stroke="#1576BC"
        strokeWidth="2.4"
      />
      <path
        d="M25.333 11.8676H30.6663C30.7396 11.8676 30.7996 11.9277 30.7996 12.0009V28.0009C30.7996 28.0742 30.7396 28.1343 30.6663 28.1343H25.333C25.2597 28.1343 25.1996 28.0742 25.1996 28.0009V12.0009C25.1996 11.9277 25.2597 11.8676 25.333 11.8676Z"
        stroke="#1576BC"
        strokeWidth="2.4"
      />
    </svg>
  );
};
