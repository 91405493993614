// eslint-disable-next-line
export default ({ fill }) => {
  return (
    <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.00004 0.833336L0.333374 3.5L4.33337 7.5L0.333374 11.5L3.00004 14.1667L9.66671 7.5L3.00004 0.833336Z"
        fill={fill}
      />
    </svg>
  );
};
