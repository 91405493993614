import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

import NotificationRoot from "./NotificationRoot";
import { useMaterialUIController, setOpenNotification } from "context";

import SeenImage from "assets/images/apollo-english/seen.jpg";
import moment from "moment";
import sunIcon from "assets/images/apollo-english/icon-sun-cloud.png";
import {
  closeIconStyle,
  dateTimeStyle,
  notiItemStyle,
  statusStyle,
  titleOderStyle,
  titleStyle,
  todayContainerStyle,
} from "./styles";
import { useNotification } from "examples/Header/hooks/useNotification";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { descriptionTypography } from "shared/styles/style";
import { noteTypography } from "shared/styles/style";
import { useEffect, useRef } from "react";

function Notification() {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, openNotification } = controller;

  const handleCloseNotification = () => setOpenNotification(dispatch, false);

  const {
    currentPage,
    fetchMore,
    fetchMoreToday,
    handleChangeStatus,
    hasMore,
    hasMoreToday,
    notifications,
    handleSetNotificationDetail,
    notificationsToday,
    currentPageToday,
    handlerReadAllNotification,
  } = useNotification();

  const navigate = useNavigate();

  const constants = {
    STUDENT_AT_RISK: "Student at risk",
    ANNOUNCEMENT: "Announcement",
    CHAT: "Chat",
    BIRTHDAY: "Birthday",
    PT_DEMO_SCHEDULE: "PT/Demo",
    WORKSHOP_TRAINING: "Workshop",
    TEST_EOCC: "Test/EOCC",
    TO_DO_LIST: "To Do",
    FEEDBACK: "Feedback",
    COURSE: "Welearn",
    OBSERVATION: "Observation",
    KPI: "KPI",
    APPROVED_LEAVE_SYSTEM: "Leave",
    HIGH_LIGHT: "News",
  };

  const renderColorBadge = (status) => {
    switch (status) {
      case "STUDENT_AT_RISK":
      case "BIRTHDAY":
      case "PT_DEMO_SCHEDULE":
      case "WORKSHOP_TRAINING":
        return "success";
      case "ANNOUNCEMENT":
        return "error";
      case "HIGH_LIGHT":
      case "APPROVED_LEAVE_SYSTEM":
      case "OBSERVATION":
        return "info";
      case "TO_DO_LIST":
      case "TEST_EOCC":
      case "FEEDBACK":
        return "warning";
      case "COURSE":
      case "KPI":
      case "CHAT":
        return "primary";
      default:
        return "error";
    }
  };

  const notiRef = useRef(null);

  const closeOpenMenus = (e) => {
    if (openNotification && !notiRef.current?.contains(e.target)) {
      handleCloseNotification();
    }
  };

  useEffect(() => {
    if (document) {
      document.addEventListener("mousedown", closeOpenMenus);
    }
    return () => document.removeEventListener("mousedown", closeOpenMenus);
  }, [openNotification]);

  return (
    <NotificationRoot ref={notiRef} variant="permanent" ownerState={{ openNotification }}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={3}
        borderBottom="1px solid #dee2e6"
        position="relative"
      >
        <MDButton
          size="small"
          variant="gradient"
          color="info"
          sx={({ breakpoints }) => ({
            ...descriptionTypography(breakpoints),
            color: "#fff",
            marginRight: "auto",
          })}
          onClick={() => {
            handlerReadAllNotification();
            handleCloseNotification();
          }}
        >
          Read all
        </MDButton>
        <MDBox
          sx={() => ({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "30px",
          })}
        >
          <MDTypography sx={(theme) => dateTimeStyle(theme)}>
            {moment(new Date()).format("MMMM Do, YYYY")}
          </MDTypography>
          <MDBox width="40px" component="img" src={sunIcon} />
        </MDBox>

        <Icon sx={(theme) => closeIconStyle(theme, { darkMode })} onClick={handleCloseNotification}>
          close
        </Icon>
      </MDBox>

      <MDBox p={3} borderBottom="1px solid #dee2e6">
        <MDTypography mb={2} sx={(theme) => titleStyle(theme)}>
          Today
        </MDTypography>
        <MDBox id="today" sx={(theme) => todayContainerStyle(theme)}>
          <InfiniteScroll
            dataLength={notificationsToday.length}
            next={() => fetchMoreToday(currentPageToday)}
            hasMore={hasMoreToday}
            scrollableTarget="today"
          >
            {notificationsToday.map((item, index) => (
              <MDBox
                key={index}
                sx={({ functions: { pxToRem } }) => ({
                  padding: pxToRem(8),
                  display: "flex",
                  alignItems: "center",
                  gap: pxToRem(16),
                  cursor: "pointer",

                  "&:not(:last-child)": {
                    marginBottom: pxToRem(16),
                  },
                })}
                onClick={() => {
                  handleCloseNotification();
                  handleChangeStatus(
                    item.notificationId,
                    item.notificationContentEntity.uniqueContentId,
                    true,
                    "today"
                  );
                  handleSetNotificationDetail(item);
                  if (item.notificationId) {
                    navigate(`/notification/${item.notificationId}`);
                  } else if (item.notificationContentEntity.uniqueContentId) {
                    navigate(
                      `/notification/${item.notificationContentEntity.uniqueContentId}?type=unique`
                    );
                  }
                }}
              >
                <MDBox
                  sx={({ functions: { pxToRem } }) => ({
                    width: pxToRem(8),
                    height: pxToRem(8),
                    backgroundColor: item.status === "UNREAD" ? "#1576bc" : "#E9ECEF",
                    borderRadius: pxToRem(4),
                  })}
                />

                <MDBox
                  sx={({ functions: { pxToRem } }) => ({
                    flexGrow: 1,
                    flexBasis: 0,
                    display: "flex",
                    flexDirection: "column",
                    gap: pxToRem(8),
                  })}
                >
                  <MDBadge
                    badgeContent={constants[item.notificationContentEntity.notificationType]}
                    variant="contained"
                    color={renderColorBadge(item.notificationContentEntity.notificationType)}
                    sx={({ functions: { pxToRem } }) => {
                      return {
                        "& .MuiBadge-badge": {
                          marginLeft: pxToRem(0),
                        },
                      };
                    }}
                  />
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={() => ({
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "350px",
                      display: "block",
                    })}
                  >
                    <MDTypography
                      variant="span"
                      sx={({ palette, typography }) => ({
                        color: palette.dark.main,
                        fontFamily: typography.lexendFont,
                        fontSize: 16,
                        fontWeight: 600,
                        lineHeight: 1.625,
                        letterSpacing: "-0.8px",
                      })}
                    >
                      {item.notificationContentEntity.titleEn}
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    sx={({ palette, typography, functions: { pxToRem } }) => ({
                      color: palette.textColors.primary,
                      fontFamily: typography.lexendFont,
                      fontSize: 14,
                      fontWeight: 400,
                      display: "block",
                      textOverflow: "ellipsis",
                      wordWrap: "break-word",
                      overflow: "hidden",
                      minHeight: pxToRem(24),
                      maxHeight: pxToRem(64),
                      whiteSpace: "break-spaces",
                      width: pxToRem(350),

                      "& p": {
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                      },
                    })}
                  >
                    <MDBox
                      sx={{ maxHeight: "64px" }}
                      dangerouslySetInnerHTML={{ __html: item.notificationContentEntity.contentEn }}
                    />
                  </MDBox>

                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {item.status === "UNREAD" && item.created ? (
                      <MDBox
                        sx={{
                          width: "300px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <MDTypography
                          variant="span"
                          sx={({ typography }) => ({
                            color: "#8898aa",
                            fontFamily: typography.lexendFont,
                            fontSize: 14,
                            fontWeight: 400,
                            lineHeight: "125%",
                            // textOverflow: "ellipsis",
                          })}
                        >
                          {moment(item.created).add(7, "hour").fromNow()}, sent by{" "}
                          {item.notificationContentEntity.senderName}
                        </MDTypography>
                      </MDBox>
                    ) : null}
                    {item.status === "READ" ? (
                      <MDBox
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          marginLeft: "auto",
                        }}
                      >
                        <MDTypography
                          sx={({ breakpoints }) => ({
                            ...noteTypography(breakpoints),
                            lineHeight: 1.25,
                          })}
                        >
                          Seen
                        </MDTypography>{" "}
                        <MDBox component="img" src={SeenImage} />
                      </MDBox>
                    ) : (
                      <></>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            ))}
          </InfiniteScroll>
          {notificationsToday.length === 0 ? (
            <MDTypography
              sx={({ breakpoints }) => ({
                ...noteTypography(breakpoints),
                textAlign: "center",
              })}
            >
              You don&apos;t have any notifications today
            </MDTypography>
          ) : null}
        </MDBox>
      </MDBox>

      <MDBox
        m={3}
        sx={() => ({
          flex: "0 1 auto",
          overflowY: "scroll",
          position: "relative",
          "&::-webkit-scrollbar": {
            width: "6px",
          },

          "&::-webkit-scrollbar-thumb": {
            background: "#8898aa",
            borderRadius: "3px",
          },
        })}
        id="older"
      >
        <MDTypography mb={2} sx={(theme) => titleOderStyle(theme)}>
          Older
        </MDTypography>

        <MDBox
          sx={({ functions: { pxToRem } }) => ({
            marginTop: pxToRem(8),
          })}
        >
          <InfiniteScroll
            dataLength={notifications.length}
            next={() => fetchMore(currentPage)}
            hasMore={hasMore}
            scrollableTarget="older"
          >
            {notifications.map((item, index) => (
              <MDBox
                key={index}
                sx={(theme) => notiItemStyle(theme, { unread: item.status === "UNREAD" })}
                onClick={() => {
                  handleCloseNotification();
                  handleChangeStatus(
                    item.notificationId,
                    item.notificationContentEntity.uniqueContentId,
                    true,
                    "older"
                  );
                  handleSetNotificationDetail(item);
                  navigate(`/notification/${item.notificationId}`);
                }}
              >
                <MDBox sx={(theme) => statusStyle(theme, { unread: item.status === "UNREAD" })} />

                <MDBox
                  sx={({ functions: { pxToRem } }) => ({
                    flexGrow: 1,
                    flexBasis: 0,
                    display: "flex",
                    flexDirection: "column",
                    gap: pxToRem(8),
                  })}
                >
                  <MDBox display="flex" justifyContent="space-between" alignItems="center">
                    <MDTypography
                      sx={({ palette, typography }) => ({
                        color: palette.dark.main,
                        fontFamily: typography.lexendFont,
                        fontSize: 16,
                        fontWeight: 600,
                        lineHeight: 1.625,
                        letterSpacing: "-0.8px",
                      })}
                    >
                      {item.notificationContentEntity.titleEn}
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    sx={({ typography, functions: { pxToRem } }) => ({
                      color: "#172b4d",
                      fontFamily: typography.lexendFont,
                      fontSize: 14,
                      fontStyle: "normal",
                      fontWeight: 400,

                      display: "block",
                      textOverflow: "ellipsis",
                      wordWrap: "break-word",
                      overflow: "hidden",
                      maxHeight: pxToRem(96),
                      whiteSpace: "break-spaces",
                      width: pxToRem(350),

                      // maxheight 3 line text ellipsis

                      "& p": {
                        display: "-webkit-box",
                        WebkitLineClamp: 4,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                      },
                    })}
                  >
                    <MDBox
                      dangerouslySetInnerHTML={{
                        __html: item.notificationContentEntity.contentEn,
                      }}
                    />
                  </MDBox>

                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {item.status === "UNREAD" && item.created ? (
                      <MDBox
                        sx={{
                          width: "300px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <MDTypography
                          sx={({ typography }) => ({
                            color: "#8898aa",
                            fontFamily: typography.lexendFont,
                            fontSize: 14,
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: 1,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          })}
                        >
                          {moment(item.created).add(7, "hour").fromNow()}, sent by{" "}
                          {item.notificationContentEntity.senderName}
                        </MDTypography>
                      </MDBox>
                    ) : null}
                    {item.status === "READ" ? (
                      <MDBox
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          marginLeft: "auto",
                        }}
                      >
                        <MDTypography
                          sx={({ breakpoints }) => ({
                            ...noteTypography(breakpoints),
                            lineHeight: 1.25,
                          })}
                        >
                          Seen
                        </MDTypography>
                        <MDBox component="img" src={SeenImage} />
                      </MDBox>
                    ) : (
                      <></>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            ))}
          </InfiniteScroll>
          {notifications.length === 0 ? (
            <MDTypography
              sx={({ breakpoints }) => ({
                ...noteTypography(breakpoints),
                textAlign: "center",
                mt: 6,
              })}
            >
              You don&apos;t have any notifications older
            </MDTypography>
          ) : null}
        </MDBox>
      </MDBox>
    </NotificationRoot>
  );
}

export default Notification;
