export const teacherDetailStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  borderRadius: pxToRem(16),
  background: "#fff",
  padding: `${pxToRem(24)}`,
  marginBottom: pxToRem(24),
  minHeight: pxToRem(500),

  [breakpoints.down("xxl")]: {
    rowGap: 2.5,
    flexDirection: "column",
  },

  [breakpoints.down("md")]: {
    rowGap: 2,
  },
});

export const titleStyle = ({ typography, functions: { pxToRem } }) => ({
  fontFamily: typography.lexendFont,
  fontSize: 20,
  lineHeight: 1.37,
  fontWeight: 700,
  letterSpacing: "-0.8px",
  marginBottom: pxToRem(21),
});
