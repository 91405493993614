import * as React from "react";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { useDeleteTemplateMutation } from "shared/redux/endpoints/admin";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  maxWidth: "min(90%, 80vh)",
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  outline: 0,
};

export default function DeleteTemplate({
  open,
  handleClose,
  setOpenSuccessSB,
  templateId,
  refetch,
}) {
  const [deletetemplate] = useDeleteTemplateMutation();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox component="form" sx={style}>
        <MDTypography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ p: 3, pb: 2, fontFamily: "Lexend, san-serif" }}
        >
          Do you want to delete template: {templateId}
        </MDTypography>
        <MDBox display="flex" sx={{ p: 3, pt: 2, width: "100%" }}>
          <Button
            variant="contained"
            sx={({ functions: { pxToRem } }) => ({
              mr: 2,
              color: "#FFFFFF",
              backgroundColor: "#5E72E4",
              fontFamily: "Lexend, san-serif",
              height: pxToRem(30),
              minHeight: pxToRem(30),
            })}
            role={undefined}
            tabIndex={-1}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            sx={({ palette, functions: { pxToRem } }) => ({
              color: palette.textColors.primary,
              borderColor: `${palette.textColors.primary}!important`,
              fontFamily: "Lexend, san-serif",
              height: pxToRem(30),
              minHeight: pxToRem(30),
            })}
            onClick={async () => {
              const response = await deletetemplate({
                templateId,
              });

              if (response?.data?.status) {
                handleClose();
                setOpenSuccessSB(true);
                refetch?.();
              }
            }}
          >
            Delete
          </Button>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

DeleteTemplate.propTypes = {
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
  openSuccessSB: PropTypes.bool,
  setOpenSuccessSB: PropTypes.func,
  templateId: PropTypes.string,
  refetch: PropTypes.func,
};
