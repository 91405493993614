import { ArrowUpward, Notifications } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { descriptionTypography } from "shared/styles/style";
import { noteTypography } from "shared/styles/style";
import { titleTypography } from "shared/styles/style";
import BellIcon from "assets/images/apollo-english/green-bell.svg";
import CardIcon from "assets/images/apollo-english/card.svg";

export const Event = () => {
  return (
    <MDBox
      sx={({ breakpoints, functions: { pxToRem } }) => ({
        background: "#ffffff",
        p: 3,
        boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.1)",
        borderRadius: pxToRem(15),
        height: "100%",

        [breakpoints.down(1600)]: {
          borderRadius: pxToRem(12),
          p: 2,
        },
      })}
    >
      <MDTypography
        sx={({ breakpoints }) => ({
          ...titleTypography(breakpoints),
          pb: 0.5,
        })}
      >
        TODAY&apos;S EVENT
      </MDTypography>
      <MDBox sx={{ display: "flex", alignItems: "center", gap: 0.5, mb: 3 }}>
        <ArrowUpward sx={{ width: 14, height: 14, color: "#2DCE89" }} />
        <MDTypography sx={({ breakpoints }) => ({ ...noteTypography(breakpoints) })}>
          You have a total of 9 events today
        </MDTypography>
      </MDBox>
      {[1, 2, 3, 4, 5].map((item, index) => (
        <MDBox key={index}>
          <MDBox sx={{ display: "flex", gap: 2, alignItems: "center", py: 0.5 }}>
            <MDBox
              sx={{
                flexBasis: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MDBox component="img" src={index === 4 ? CardIcon : BellIcon} />
            </MDBox>
            <MDBox sx={{ flexGrow: 1 }}>
              <MDTypography sx={({ breakpoints }) => ({ ...descriptionTypography(breakpoints) })}>
                Event Name 1
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <MDBox sx={{ flexBasis: 20, display: "flex", justifyContent: "center" }}>
              <MDBox sx={{ height: 35, width: "2px", background: "#E9ECEF" }}></MDBox>
            </MDBox>
            <MDBox sx={{ flexGrow: 1, alignSelf: "flex-start" }}>
              <MDTypography
                sx={({ breakpoints }) => ({ ...noteTypography(breakpoints), lineHeight: 1 })}
              >
                09:00 - 10:00
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      ))}
    </MDBox>
  );
};
