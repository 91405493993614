import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/account/components/FormField";
import { containerStyle, titleStyle } from "../styles/BasicInfo";
import typography from "assets/theme/base/typography";
import pxToRem from "assets/theme/functions/pxToRem";
import palette from "assets/theme/base/colors";
import { CustomDatePicker } from "shared/components/ui/CustomDatePicker";
import { useGetEmployeeInformationMutation } from "shared/redux/endpoints/employee";
import { useEffect, useState } from "react";
import ChevronDownIcon from "assets/images/apollo-english/chevron-down.svg";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { selectAuth } from "shared/redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { setEmpCode } from "shared/redux/slices/authSlice";
import { Loading } from "../../../shared/components/ui/Loading";
import NotFound from "assets/images/apollo-english/not-found.png";
import InfoIcon, { TooltipIconColor, TooltipType } from "../../../components/InfoIcon";
import { teacherInfoIconBlock } from "../../../components/InfoIcon/teacherInfoIcon";
import makeStyles from "../../../pages/style";

const customStyle = {
  "& .MuiInputBase-root, & .MuiInputBase-root .MuiInputBase-input": {
    lineHeight: "100%",
  },

  "& .MuiInputBase-root .MuiInputBase-input": {
    padding: `${pxToRem(9.5)} ${pxToRem(14)}`,
  },

  "& input, & input::placeholder": {
    fontSize: "16px !important",
    lineHeight: "1.5!important",
    fontWeight: 400,
    fontFamily: typography.lexendFont,
  },

  "& input": {
    paddingLeft: `${pxToRem(14)} !important`,
  },

  "& label": {
    fontSize: "18px !important",
    fontWeight: 700,
    fontFamily: typography.lexendFont,
    color: palette.textColors.primary,
    top: "-2px",
  },

  "& legend": {
    fontSize: "16px !important",
  },
};

export const BasicInfo = () => {
  const classes = makeStyles();
  const [getEmployeeInformation, { isLoading }] = useGetEmployeeInformationMutation();
  const [data, setData] = useState(null);

  const auth = useSelector(selectAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchEmployeeInformation = async () => {
      const response = await getEmployeeInformation({ email: auth.user.email });
      if (response?.data) {
        const result = response.data.data.Result[0];
        setData(result);
        if (result) {
          dispatch(
            setEmpCode({
              empCode: result.MaNhanVien,
            })
          );
        }
      } else {
        setData({});
      }
    };

    fetchEmployeeInformation();
  }, [auth]);

  return (
    <Card sx={(theme) => containerStyle(theme)}>
      <MDBox mb={5}>
        <InfoIcon
          type={TooltipType.TYPE_1}
          teacherInfoIconBlock={teacherInfoIconBlock.BASIC_INFO}
          color={TooltipIconColor.BLUE}
          className={classes.containerInfoIconWithHeading}
        >
          <MDTypography sx={(theme) => titleStyle(theme)}>Basic Info</MDTypography>
        </InfoIcon>
      </MDBox>
      <MDBox component="form">
        {isLoading ? (
          <MDBox
            sx={{
              height: 300,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading style={{ height: 200, width: 300 }} />
          </MDBox>
        ) : !data ? (
          <MDBox
            sx={{
              height: 400,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              fontSize: "24px",

              objectFit: "contain",
            }}
            component="img"
            src={NotFound}
          >
            {/* <MDTypography
              sx={({ breakpoints }) => ({
                ...titleTypography(breakpoints),
              })}
            >
              Data not found
            </MDTypography> */}
          </MDBox>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                label="First Name"
                customStyle={customStyle}
                variant="outlined"
                disabled
                value={data?.HoTenDemNhanVien ?? ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Last Name"
                customStyle={customStyle}
                variant="outlined"
                disabled
                value={data?.TenNhanVien ?? ""}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <Autocomplete
                    defaultValue={data?.GioiTinh ? "Male" : "Female"}
                    options={["Male", "Female"]}
                    sx={({ functions: { pxToRem } }) => ({
                      height: pxToRem(42),
                      backgroundImage: `url(${ChevronDownIcon})`,
                      backgroundSize: pxToRem(12),
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "95% 50%",
                      "& .MuiFormControl-root": {
                        height: "100%",
                      },
                      "& .MuiOutlinedInput-root": {
                        padding: `0 ${pxToRem(65)} 0 0`,
                        height: "100%",
                      },
                      "& .MuiChip-root": {
                        fontFamily: typography.lexendFont,
                      },
                      "& .MuiAutocomplete-endAdornment": {
                        opacity: 0,
                      },
                    })}
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        label="Gender"
                        customStyle={customStyle}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                      />
                    )}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <CustomDatePicker
                    label="Birth Date"
                    disabled
                    defaultValue={dayjs(data?.NgaySinh, "DD/MM/YYYY").toDate()}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    label="Employee code"
                    customStyle={customStyle}
                    variant="outlined"
                    disabled
                    value={data?.MaNhanVien ?? ""}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    label="Tax code"
                    customStyle={customStyle}
                    variant="outlined"
                    disabled
                    value={data?.MaSoThue ?? ""}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Email"
                customStyle={customStyle}
                variant="outlined"
                disabled
                value={data?.EmailCongTy ?? ""}
              />
            </Grid>
            <MDBox width="100%" />
            <Grid item xs={12} sm={6}>
              <FormField label="Degree" customStyle={customStyle} variant="outlined" disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Phone Number"
                customStyle={customStyle}
                variant="outlined"
                disabled
                value={data?.DienThoai ?? ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                label="Language"
                customStyle={customStyle}
                variant="outlined"
                disabled
                value={data?.TenQuocTichEN ?? ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                defaultValue={[]}
                options={[]}
                sx={({ typography, functions: { pxToRem } }) => ({
                  height: pxToRem(42),
                  backgroundImage: `url(${ChevronDownIcon})`,
                  backgroundSize: pxToRem(12),
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "97.5% 50%",
                  "& .MuiFormControl-root": {
                    height: "100%",
                  },
                  "& .MuiOutlinedInput-root": {
                    padding: `0 ${pxToRem(65)} 0 ${pxToRem(14)}`,
                    height: "100%",
                  },
                  "& .MuiAutocomplete-endAdornment": {
                    opacity: 0,
                  },
                  "& .MuiChip-root": {
                    fontFamily: typography.lexendFont,
                  },
                })}
                renderInput={(params) => (
                  <FormField
                    label="Certification"
                    {...params}
                    customStyle={customStyle}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                  />
                )}
                disabled
              />
            </Grid>
          </Grid>
        )}
      </MDBox>
    </Card>
  );
};
