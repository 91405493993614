import SearchImage from "assets/images/apollo-english/search.jpg";
import { titleTypography } from "shared/styles/style";

export const teacherManagementStyle = ({ palette, breakpoints, functions: { pxToRem } }) => ({
  borderRadius: pxToRem(16),
  background: "#fff",
  padding: `${pxToRem(24)}`,
  marginBottom: pxToRem(24),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  columnSpacing: 3,

  [breakpoints.down(1367)]: {
    padding: pxToRem(16),
  },

  [breakpoints.down("xxl")]: {
    rowGap: 2.5,
    flexDirection: "column",
  },

  [breakpoints.down("md")]: {
    rowGap: 2,
  },
});

export const subTitleStyle = ({ breakpoints, typography, functions: { pxToRem } }) => ({
  ...titleTypography(breakpoints),
  lineHeight: 1.37,
  letterSpacing: pxToRem(-0.8),
  marginBottom: pxToRem(21),
});

export const inputContainerStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  width: pxToRem(400),
  maxWidth: "100%",
  marinBottom: pxToRem(8),

  [breakpoints.down(1367)]: {
    width: pxToRem(240),
  },
});

export const inputStyle = ({ breakpoints, typography, functions: { pxToRem } }) => ({
  width: "100%",
  height: pxToRem(40),

  "& > div": {
    overflow: pxToRem(4),
  },

  "& input": {
    borderRadius: pxToRem(4),
    background: "#fff",
    width: "100%",
    height: pxToRem(48),
    padding: `0 ${pxToRem(16)} 0px ${pxToRem(14)}`,
    alignItems: "center",
    fontFamily: typography.lexendFont,
    outline: "none",
    background: `url(${SearchImage}) no-repeat scroll`,
    backgroundPosition: `right ${pxToRem(13)} center`,
  },

  "& input::-ms-input-placeholder, & input::placeholder": {
    fontFamily: typography.lexendFont,
    color: "#8a8a8a",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    letterSpacing: pxToRem(0.175),
  },

  [breakpoints.down(1367)]: {
    height: pxToRem(28),

    "& input": {
      height: pxToRem(33),
      fontSize: 12,
    },

    "& input::-ms-input-placeholder, & input::placeholder": {
      fontSize: 12,
    },
  },
});

export const tableContainerStyle = ({ breakpoints, palette, functions: { pxToRem } }) => ({
  overflowX: "scroll",
  maxHeight: pxToRem(530),
  width: "calc(100% + 14px)",
  marginTop: pxToRem(16),
  background: palette.white.main,
  "&::-webkit-scrollbar": {
    display: "none",
  },

  [breakpoints.down(1367)]: {
    maxHeight: pxToRem(400),
    marginTop: pxToRem(12),
  },
});

export const tableStyle = ({ typography, functions: { pxToRem } }) => ({
  color: "#333",
  height: "100%",
  width: pxToRem(800),
  minWidth: "100%",
  fontFamily: typography.lexendFont,
  fontSize: 14,
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: pxToRem(0.42),
});

export const tableHeadStyle = ({ breakpoints, palette, typography }) => ({
  ...descriptionTypography(breakpoints),
  lineHeight: 1.5,
  textTransform: "uppercase",
  fontWeight: 700,
});

export const checkboxStyle = ({ functions: { pxToRem } }) => ({
  "&.Mui-checked .MuiSvgIcon-root": {
    backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"),linear-gradient(195deg, #7B62E4, #646FE4) !important`,
    borderColor: "#646FE4",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 18,
    borderRadius: pxToRem(4),
  },
});

export const checkboxLabelStyle = ({ breakpoints, typography, functions: { pxToRem } }) => ({
  color: "#333",
  fontFamily: typography.lexendFont,
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: "0.42px",

  [breakpoints.down(1367)]: {
    fontSize: 11,
  },
});

export const avatarStyle = ({ breakpoints, typography, functions: { pxToRem } }) => ({
  width: pxToRem(48),
  height: pxToRem(48),
  borderRadius: pxToRem(8),
  marginLeft: pxToRem(4),
  marginRight: pxToRem(24),
});

export const tableTextStyle = ({ breakpoints, palette, typography }) => ({
  ...descriptionTypography(breakpoints),
  lineHeight: 1.5,
  display: "inline-block",
});

export const statusStyle = ({ breakpoints, typography, functions: { pxToRem } }, { status }) => ({
  ...noteTypography(breakpoints),
  display: "inline-block",
  color: status === "INACTIVE" ? "#F80031" : "#1AAE6F",
  background: status === "INACTIVE" ? "#FDD1DA" : "#B0EED3",
  padding: `${pxToRem(4)} ${pxToRem(8)}`,
  borderRadius: pxToRem(6),
  marginLeft: 1,
});

export const pagingTextStyle = ({ breakpoints, palette, typography }) => ({
  color: palette.textColors.secondary,
  fontFamily: typography.lexendFont,
  fontWeight: 400,
  fontSize: 14,

  [breakpoints.down(1367)]: {
    fontSize: 12,
  },
});

export const pagingItemStyle = (
  { breakpoints, palette, typography, functions: { pxToRem } },
  { active = false, isArrow = false, disable = false }
) => ({
  display: "flex",
  cursor: disable ? "not-allowed" : "pointer",
  opacity: disable ? 0.25 : 1,
  justifyContent: "center",
  alignItems: "center",
  border: `${pxToRem(1)} solid ${isArrow ? palette.textColors.secondary : "#E9ECEF"}`,
  width: pxToRem(34),
  height: pxToRem(34),
  borderRadius: pxToRem(9999),
  color: palette.textColors.secondary,
  fontSize: 14,
  fontFamily: typography.lexendFont,
  fontWeight: 400,
  ...(active
    ? {
        background: "linear-gradient(124deg, #5E72E4 -1.8%, #825EE4 105.23%)",
        boxShadow: "0px 4px 6px 0px #00000014",
        color: palette.white.main,
      }
    : {}),

  [breakpoints.down(1367)]: {
    width: pxToRem(28),
    height: pxToRem(28),
    fontSize: 12,
  },
});

export const tableStyleID = ({ breakpoints, functions: { pxToRem } }) => ({
  fontSize: pxToRem(14),
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  color: "#525F7F",
});

export const tableStyleDesc = ({ breakpoints, functions: { pxToRem } }) => ({
  fontSize: pxToRem(14),
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  color: "#525F7F",
});

export const tableStyleDate = ({ breakpoints, functions: { pxToRem } }) => ({
  fontSize: pxToRem(14),
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  color: "#8392AB",
});
