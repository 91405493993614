export const notificationDetailStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  borderRadius: pxToRem(16),
  background: "#fff",
  padding: `${pxToRem(24)}`,
  marginBottom: pxToRem(24),

  [breakpoints.down("xxl")]: {
    rowGap: 2.5,
    flexDirection: "column",
  },

  [breakpoints.down("md")]: {
    rowGap: 2,
  },
});

export const avatarStyle = ({ functions: { pxToRem } }) => ({
  width: pxToRem(48),
  height: pxToRem(48),
  borderRadius: pxToRem(12),
});

export const usernameStyle = ({ palette, typography }) => ({
  fontSize: 14,
  fontFamily: typography.lexendFont,
  fontWeiht: 400,
  color: palette.textColors.primary,
});

export const timeStyle = ({ palette, typography }) => ({
  fontSize: 14,
  fontFamily: typography.lexendFont,
  fontWeiht: 400,
  color: palette.textColors.secondary,
});

export const contentStyle = ({ palette, typography }) => ({
  fontSize: 16,
  fontFamily: typography.lexendFont,
  fontWeiht: 400,
  color: palette.textColors.secondary,
  maxWidth: "100%",
  width: "100%",
  wordWrap: "break-word",
  overflowWrap: "break-word",
});
