import React, { useEffect, useState } from "react";
import { Checkbox, Table, TableBody, TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import {
  avatarStyle,
  checkboxLabelStyle,
  checkboxStyle,
  tableTextStyle,
  inputContainerStyle,
  inputStyle,
  tableContainerStyle,
  tableStyle,
  teacherManagementStyle,
  titleStyle,
  statusStyle,
  tableHeadStyle,
  pagingItemStyle,
  pagingTextStyle,
} from "./style";
import {
  ChevronLeft,
  ChevronRight,
  Delete,
  ExpandLess,
  ExpandMore,
  RemoveRedEye,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useLazyGetTeachersQuery } from "shared/redux/endpoints/admin";

const EnhancedTableHead = ({ title, onAsc, onDesc, ascActive, descActive }) => {
  return (
    <MDBox
      sx={() => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginRight: "24px",
      })}
    >
      <MDTypography sx={(theme) => tableHeadStyle(theme)}>{title}</MDTypography>
      <MDBox sx={() => ({ display: "flex", alignItems: "center" })}>
        <ExpandLess
          onClick={onDesc}
          fontSize="small"
          sx={({ palette }) => ({
            color: descActive ? "#00FF7F" : palette.textColors.secondary,
            cursor: "pointer",
          })}
        />
        <ExpandMore
          onClick={onAsc}
          fontSize="small"
          sx={({ palette }) => ({
            color: ascActive ? "#00FF7F" : palette.textColors.secondary,
            cursor: "pointer",
          })}
        />
      </MDBox>
    </MDBox>
  );
};

EnhancedTableHead.propTypes = {
  title: PropTypes.string.isRequired,
  onAsc: PropTypes.func,
  onDesc: PropTypes.func,
  ascActive: PropTypes.boolean,
  descActive: PropTypes.boolean,
};

function TeacherManagement() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [data, setData] = useState([]);
  const [sort, changeSort] = useState("created%2Cdesc");

  const [triggerTeachers, { isLoading }] = useLazyGetTeachersQuery();

  useEffect(() => {
    const fetchTeachers = async () => {
      const response = await triggerTeachers({
        sort: sort ?? "",
        page,
        size: 10,
      });
      if (response?.isSuccess && response?.data?.status) {
        setTotalPage(response?.data?.data?.totalPage ?? 0);
        setData(response?.data?.data?.data);
      }
    };
    fetchTeachers();
  }, [page, sort]);

  const pageRange = [];
  for (let i = 1; i <= totalPage; i++) {
    pageRange.push(i);
  }

  const filteredPages = pageRange.slice(Math.max(page - 2, 0), Math.min(page + 3, totalPage + 1));

  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
      columnSpacing={3}
      sx={(theme) => teacherManagementStyle(theme)}
    >
      <MDBox
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        sx={({ breakpoints }) => ({
          rowGap: 2,
          [breakpoints.down("lg")]: {
            flexDirection: "column",
            alignItems: "flex-start",
          },
        })}
      >
        <MDTypography sx={(theme) => titleStyle(theme)}>All Teacher</MDTypography>
      </MDBox>

      <MDBox sx={(theme) => inputContainerStyle(theme)}>
        <MDInput placeholder="Search for a teacher by email" sx={(theme) => inputStyle(theme)} />
      </MDBox>

      <MDBox width="100%">
        <MDBox data-horizontal-scroll sx={(theme) => tableContainerStyle(theme)}>
          <Table sx={(theme) => tableStyle(theme)}>
            <MDBox component="thead" sx={{ borderBottom: "1px solid #DEE2E6" }}>
              <TableRow
                sx={({ typography }) => ({
                  height: "50px",
                  textAlign: "left",
                })}
              >
                <MDBox
                  component="th"
                  sx={() => ({
                    paddingLeft: 2,
                  })}
                >
                  <EnhancedTableHead
                    title="Teacher name"
                    onAsc={() => changeSort(sort === "fullName%2Casc" ? "" : "fullName%2Casc")}
                    onDesc={() => changeSort(sort === "fullName%2Cdesc" ? "" : "fullName%2Cdesc")}
                    ascActive={sort === "fullName%2Casc"}
                    descActive={sort === "fullName%2Cdesc"}
                  />
                </MDBox>
                <MDBox component="th">
                  <EnhancedTableHead
                    title="Email"
                    onAsc={() => changeSort(sort === "email%2Casc" ? "" : "email%2Casc")}
                    onDesc={() => changeSort(sort === "email%2Cdesc" ? "" : "email%2Cdesc")}
                    ascActive={sort === "email%2Casc"}
                    descActive={sort === "email%2Cdesc"}
                  />
                </MDBox>
                <MDBox component="th">
                  <EnhancedTableHead
                    title="Title"
                    onAsc={() => changeSort(sort === "title%2Casc" ? "" : "title%2Casc")}
                    onDesc={() => changeSort(sort === "title%2Cdesc" ? "" : "title%2Cdesc")}
                    ascActive={sort === "title%2Casc"}
                    descActive={sort === "title%2Cdesc"}
                  />
                </MDBox>
                <MDBox component="th">
                  <EnhancedTableHead
                    title="Role"
                    onAsc={() => changeSort(sort === "role%2Casc" ? "" : "role%2Casc")}
                    onDesc={() => changeSort(sort === "role%2Cdesc" ? "" : "role%2Cdesc")}
                    ascActive={sort === "role%2Casc"}
                    descActive={sort === "role%2Cdesc"}
                  />
                </MDBox>
                <MDBox component="th">
                  <EnhancedTableHead
                    title="User code"
                    onAsc={() => changeSort(sort === "userCode%2Casc" ? "" : "userCode%2Casc")}
                    onDesc={() => changeSort(sort === "userCode%2Cdesc" ? "" : "userCode%2Cdesc")}
                    ascActive={sort === "userCode%2Casc"}
                    descActive={sort === "userCode%2Cdesc"}
                  />
                </MDBox>
                <MDBox component="th">
                  <EnhancedTableHead
                    title="STATUS"
                    onAsc={() => changeSort(sort === "status%2Casc" ? "" : "status%2Casc")}
                    onDesc={() => changeSort(sort === "status%2Cdesc" ? "" : "status%2Cdesc")}
                    ascActive={sort === "status%2Casc"}
                    descActive={sort === "status%2Cdesc"}
                  />
                </MDBox>
                <MDBox component="th">
                  <EnhancedTableHead title="ACTION" />
                </MDBox>
              </TableRow>
            </MDBox>

            <TableBody
              sx={({ functions: { pxToRem } }) => ({
                maxHeight: pxToRem(454),
                overflowY: "scroll",
              })}
            >
              {data.map((item, index) => (
                <TableRow
                  onClick={() => {
                    navigate("/teacher-detail");
                  }}
                  key={index}
                  sx={({ typography }) => ({
                    height: "50px",
                    textAlign: "left",
                    fontFamily: typography.lexendFont,
                    fontWeight: 700,
                    fontSize: 12,
                    lineHeight: 1.5,
                    color: "#8392AB",
                    borderBottom: "1px solid #DEE2E6",
                    cursor: "pointer",
                  })}
                >
                  <MDBox component="td" sx={{ padding: 2, alignItems: "center", display: "flex" }}>
                    <Checkbox defaultChecked size="small" sx={(theme) => checkboxStyle(theme)} />
                    <MDBox
                      sx={(theme) => avatarStyle(theme)}
                      component="img"
                      src={item.avatar ?? "https://demo-lesson.sgp1.digitaloceanspaces.com/ava.png"}
                    ></MDBox>
                    <MDTypography variant="span" sx={(theme) => checkboxLabelStyle(theme)}>
                      {item.fullName}
                    </MDTypography>
                  </MDBox>

                  <MDBox component="td">
                    <MDTypography variant="span" sx={(theme) => tableTextStyle(theme)}>
                      {item.email}
                    </MDTypography>
                  </MDBox>

                  <MDBox component="td">
                    <MDTypography variant="span" sx={(theme) => tableTextStyle(theme)}>
                      {item.title}
                    </MDTypography>
                  </MDBox>
                  <MDBox component="td">
                    <MDTypography variant="span" sx={(theme) => tableTextStyle(theme)}>
                      {item.role}
                    </MDTypography>
                  </MDBox>
                  <MDBox component="td">
                    <MDTypography variant="span" sx={(theme) => tableTextStyle(theme)}>
                      {item.userCode}
                    </MDTypography>
                  </MDBox>
                  <MDBox component="td">
                    <MDBox sx={(theme) => statusStyle(theme, { status: item.status })}>
                      {item.status}
                    </MDBox>
                  </MDBox>
                  <MDBox component="td" sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <RemoveRedEye fontSize="small" sx={{ color: "#8392AB", margin: 2 }} />
                    <Delete fontSize="small" sx={{ color: "#8392AB", margin: 2 }} />
                  </MDBox>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </MDBox>
        <MDBox
          sx={({}) => ({
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "16px 0px",
            gap: 1,
          })}
        >
          <MDBox
            sx={(theme) =>
              pagingItemStyle(theme, {
                active: false,
                isArrow: true,
                disable: page === 0,
              })
            }
            onClick={() => {
              if (!(page === 0)) {
                setPage((page) => page - 1);
              }
            }}
          >
            <ChevronLeft sx={() => ({ color: "#8392AB" })} />
          </MDBox>

          {filteredPages.map((item, index) => (
            <MDBox
              key={index}
              sx={(theme) => pagingItemStyle(theme, { active: item === page + 1, isArrow: false })}
              onClick={() => setPage(item - 1)}
            >
              {item}
            </MDBox>
          ))}
          <MDBox
            sx={(theme) =>
              pagingItemStyle(theme, {
                active: false,
                isArrow: true,
                disable: page === totalPage - 1 || page === totalPage,
              })
            }
            onClick={() => {
              if (!(page === totalPage - 1 || page === totalPage)) {
                setPage((page) => page + 1);
              }
            }}
          >
            <ChevronRight sx={() => ({ color: "#8392AB" })} />
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export { TeacherManagement };
