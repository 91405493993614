import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Icon, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Notification from "./components/notification";
import "./custom.css";
import Slider from "react-slick";
import { useRef } from "react";
import HeaderNextArrow from "components/icons/HeaderNextArrowIcon";
import HeaderPrevArrow from "components/icons/HeaderPrevArrowIcon";
import helloIcon from "assets/images/apollo-english/hello.svg";
import { useMaterialUIController, setOpenNotification, setMiniSidenav } from "context";
import { ArrowStyles } from "./styles";
import backgroundImageValue from "assets/bg.png";
import { NotificationTab } from "./components/NotificationTab";
import { selectAuth } from "shared/redux/slices/authSlice";
import { useSelector } from "react-redux";
import { useBanners } from "pages/home/hooks/useBanners";
import { selectBanner } from "shared/redux/slices/bannerSlice";

function Header() {
  const sliderRef = useRef(null);

  const [controller, dispatch] = useMaterialUIController();

  const { openNotification, miniSidenav, darkMode, transparentNavbar } = controller;

  const handleNotificationOpen = (e) => {
    setOpenNotification(dispatch, !openNotification);
  };

  const banner = useSelector(selectBanner);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const theme = useTheme();
  const xlMatches = useMediaQuery(theme.breakpoints.up("xl"));

  const settings = {
    customPaging: function () {
      return <div className="custom-dot"></div>;
    },
    dots: xlMatches,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const auth = useSelector(selectAuth);

  return (
    <MDBox
      sx={({ functions: { pxToRem } }) => ({
        width: "100%",
        height: pxToRem(290),
        position: "relative",
        overflow: "hidden",
      })}
      id="header"
    >
      <Slider ref={sliderRef} {...settings}>
        {(banner.banners.length === 0
          ? [
              {
                imgUrl:
                  "https://static.spacecrafted.com/a7396ee5481b4f909a919d4d9b12438c/i/fb8d760141e741eab1385d7d1e49585f/1/4SoifmQp45JMgBnHp7ed2/1200-x-720%20Placeholder%20Image.jpg",
                linkUrl: "",
              },
            ]
          : banner.banners
        ).map((item, index) => {
          return (
            <MDBox
              key={index}
              sx={{
                height: "100%",
                width: "100%",
                display: "block",
                cursor: "pointer",
              }}
              onClick={() => {
                if (item.linkUrl) {
                  window.open(item.linkUrl, "_blank");
                }
              }}
            >
              <MDBox
                sx={{
                  height: "100%",
                  backgroundImage: `url(${item.imgUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></MDBox>
            </MDBox>
          );
        })}
      </Slider>

      <IconButton
        sx={({ breakpoints }) => ({
          display: "none !important",
          cursor: "pointer",
          position: "absolute",
          top: 20,
          left: 20,

          [breakpoints.up("xl")]: {
            display: "inline-block !important",
          },

          [breakpoints.down("xl")]: {
            display: "inline-block !important",
          },
        })}
        onClick={handleMiniSidenav}
        size="small"
        disableRipple
      >
        <Icon
          fontSize="medium"
          sx={({ palette: { dark, white, text }, functions: { rgba } }) => ({
            color: () => {
              let colorValue = darkMode ? white.main : dark.main;

              if (transparentNavbar) {
                colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
              }

              return colorValue;
            },
          })}
        >
          {miniSidenav ? "menu_open" : "menu"}
        </Icon>
      </IconButton>
      {/* <MDBox
        sx={(theme) => ArrowStyles(theme, { miniSidenav, type: "left" })}
        onClick={() => sliderRef?.current?.slickPrev()}
      >
        <Icon component={() => <HeaderPrevArrow />} />
      </MDBox>
      <MDBox
        sx={(theme) => ArrowStyles(theme, { miniSidenav, type: "right" })}
        onClick={() => sliderRef?.current?.slickNext()}
      >
        <Icon component={() => <HeaderNextArrow />} />
      </MDBox> */}

      <NotificationTab handleNotificationOpen={handleNotificationOpen} />

      <MDBox
        sx={({ breakpoints, functions: { pxToRem } }) => ({
          position: "absolute",
          top: pxToRem(190),
          left: pxToRem(80),

          [breakpoints.down("xl")]: {
            left: pxToRem(64),
          },

          [breakpoints.down("lg")]: {
            left: pxToRem(48),
          },

          [breakpoints.down("md")]: {
            left: pxToRem(24),
          },
        })}
      >
        <MDTypography
          variant="h1"
          sx={({ palette, typography }) => ({
            color: palette.white.main,
            fontFamily: typography.lexendFont,
            fontSize: 30,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "137%",
            letterSpacing: "-0.8px",
            textShadow: "-1px 1px 8px #000000",
          })}
        >
          Hi {auth.user.fullName}
          <MDBox
            component="img"
            src={helloIcon}
            width="2rem"
            sx={({ functions: { pxToRem } }) => ({
              width: pxToRem(32),
              height: pxToRem(32),
              display: "inline-block",
              marginLeft: "1rem",
            })}
          ></MDBox>
        </MDTypography>

        <MDTypography
          variant="p"
          sx={({ typography }) => ({
            color: "rgb(21, 118, 188)",
            fontFamily: typography.lexendFont,
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "162.5%",
            letterSpacing: "-0.8px",
          })}
        >
          Welcome to My Apollo
        </MDTypography>
      </MDBox>

      <Notification />
    </MDBox>
  );
}

export default Header;
