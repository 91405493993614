export default () => {
  return (
    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.95 6.29775C6.74448 6.43847 6.49884 6.50865 6.25 6.49775C6.00116 6.50865 5.75552 6.43847 5.55 6.29775L0.25 3.44775V8.49775C0.25 8.89558 0.408035 9.27711 0.68934 9.55841C0.970644 9.83972 1.35218 9.99775 1.75 9.99775H10.75C11.1478 9.99775 11.5294 9.83972 11.8107 9.55841C12.092 9.27711 12.25 8.89558 12.25 8.49775V3.44775L6.95 6.29775Z"
        fill="#172B4D"
      />
      <path
        d="M10.75 -0.00244141H1.75001C1.35218 -0.00244141 0.970652 0.155594 0.689347 0.436898C0.408043 0.718203 0.250007 1.09973 0.250007 1.49756V1.99756C0.24953 2.08772 0.27228 2.17649 0.316067 2.2553C0.359855 2.33412 0.423202 2.40033 0.500007 2.44756L6.00001 5.44756C6.07678 5.48798 6.1636 5.50534 6.25001 5.49756C6.33642 5.50534 6.42324 5.48798 6.50001 5.44756L12 2.44756C12.0768 2.40033 12.1402 2.33412 12.1839 2.2553C12.2277 2.17649 12.2505 2.08772 12.25 1.99756V1.49756C12.25 1.09973 12.092 0.718203 11.8107 0.436898C11.5294 0.155594 11.1478 -0.00244141 10.75 -0.00244141Z"
        fill="#172B4D"
      />
    </svg>
  );
};
