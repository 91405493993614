import teacherInfoDescriptionMap, {
  teacherInfoIconBlock,
} from "../../../components/InfoIcon/teacherInfoIcon";

const cards = [
  {
    title: "This month renewal rate",
    progress: 80,
    logoBg: "#E01E5A",
    logo: "mark",
    diff: 5,
    time: "than last month",
    isPercent: true,
    information: teacherInfoDescriptionMap.get(teacherInfoIconBlock.THIS_MONTH_RENEWAL_RATE),
  },
  {
    title: "Welearn course progress",
    progress: 50,
    logoBg: "#E01E5A",
    logo: "mark",
    diff: 5,
    time: "than last week",
    isPercent: true,
    information: teacherInfoDescriptionMap.get(teacherInfoIconBlock.WELEARN_COURSE_PROGRESS),
  },
  {
    title: "Teaching hour",
    progress: 85,
    logoBg: "#E01E5A",
    logo: "mark",
    diff: 5,
    time: "than last month",
    isPercent: false,
    information: teacherInfoDescriptionMap.get(teacherInfoIconBlock.TEACHING_HOUR),
  },
  {
    title: "Conversion rate ",
    progress: 65,
    logoBg: "#E01E5A",
    logo: "mark",
    diff: +2,
    time: "than last month",
    isPercent: true,
    information: teacherInfoDescriptionMap.get(teacherInfoIconBlock.CONVERSION_RATE),
  },
];

export { cards };
