import React from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import colors from "../../assets/theme-dark/base/colors";

// Modifier for tooltip positioning
const offsetModifier = [{ name: "offset", options: { offset: [0, -10] } }];

// Destructuring colors for easier reference
const { white, info } = colors;

// Default styles for the tooltip
const defaultTooltipStyles = {
  backgroundColor: white.main, // Tooltip background color
  color: "rgba(0, 0, 0, 0.87)", // Text color
  maxWidth: 220, // Max width of the tooltip
  fontSize: "0.75rem", // Font size
  border: "1.5px solid", // Tooltip border
  borderColor: info.main, // Border color
};

interface CustomTooltipProps extends TooltipProps {
  arrow?: boolean;
  placement?: "top" | "right" | "bottom" | "left";
  content?: React.ReactNode; // Adding content prop
}

const CustomTooltip = styled(({ className, style, content, ...props }: CustomTooltipProps) => {
  const { arrow = false, placement = "right" } = props;
  return (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      arrow={arrow}
      placement={placement}
      title={content || props.title} // Use content if available, otherwise fallback to title
      slotProps={{ popper: { modifiers: offsetModifier } }}
    />
  );
})(({ style }) => ({
  // Tooltip styling - merge default styles with any passed `style` prop
  [`& .${tooltipClasses.tooltip}`]: {
    ...defaultTooltipStyles,
    ...style, // Override with custom styles if provided
  },
}));

export default CustomTooltip;
