import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import PropTypes from "prop-types";
import {
  avatarStyle,
  bottomStyle,
  dateStyle,
  notificationIconStyle,
  notificationTabStyle,
} from "./style";
import { useSelector } from "react-redux";
import { selectAuth } from "shared/redux/slices/authSlice";
import { selectNotifcation } from "shared/redux/slices/notificationSlice";
import moment from "moment";
import Lottie from "react-lottie-player";
import bell from "./bell.json";
import logout from "components/icons/svgs/logout.svg";
import { useNavigate } from "react-router-dom";
import { logOut } from "shared/redux/slices/authSlice";
import { clearAllNotification } from "shared/redux/slices/notificationSlice";
import { useDispatch } from "react-redux";
import questionMark from "assets/question.svg";

export const NotificationTab = ({ handleNotificationOpen }) => {
  const auth = useSelector(selectAuth);
  const { notificationCount } = useSelector(selectNotifcation);

  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const handleLogout = () => {
    reduxDispatch(logOut());
    reduxDispatch(clearAllNotification());
    navigate("/sign-in");
  };

  return (
    <MDBox onClick={handleNotificationOpen} sx={(theme) => notificationTabStyle(theme)}>
      <MDTypography variant="p" sx={(theme) => dateStyle(theme)}>
        {moment(new Date()).format("MMMM Do, YYYY")}
      </MDTypography>
      <MDBox sx={(theme) => bottomStyle(theme)}>
        <MDBadge
          badgeContent={notificationCount?.unRead}
          variant="contained"
          circular
          sx={(theme) => notificationIconStyle(theme)}
        >
          <MDBox display="flex" height="100%" alignItems="center">
            <Lottie animationData={bell} goTo={9.5} />
          </MDBox>
        </MDBadge>

        <MDBox
          component="img"
          src={questionMark}
          alt="question"
          sx={(theme) => avatarStyle(theme)}
          onClick={(e) => {
            e.stopPropagation();
            window.open("https://welearn.apollo.vn/course/view.php?id=175", "_blank").focus();
          }}
        ></MDBox>
      </MDBox>
    </MDBox>
  );
};

NotificationTab.propTypes = {
  handleNotificationOpen: PropTypes.func,
};
