import { useEffect, useState } from "react";
import { useLazyGetClassSessionQuery } from "shared/redux/endpoints/calendar";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectAuth } from "shared/redux/slices/authSlice";

export const useWeeklyCalendar = () => {
  const auth = useSelector(selectAuth);
  const role = auth.role;
  const tabs = [
    { label: "All", value: "all" },
    { label: "Lesson", value: "session" },
    { label: "Demo/PT", value: "pt" },
    { label: "Tasks", value: "task" },
    ...(role !== "CSO"
      ? [
          { label: "EOCC & Test", value: "test" },
          { label: "Training", value: "training" },
        ]
      : []),
  ];
  const [tab, setTab] = useState(tabs[0]); // ["All", "Lesson", "Demo/PT", "Tasks"]
  const [events, setEvents] = useState([]);
  const [eventsByDay, setEventsByDay] = useState({});
  const [start, setStart] = useState(moment(new Date()).format("DD-MM-YYYY"));
  const [end, setEnd] = useState(moment(new Date()).format("DD-MM-YYYY"));
  const [timezone, setTimeZone] = useState((new Date().getTimezoneOffset() / 60) * -1);

  const [trigger, result] = useLazyGetClassSessionQuery();

  const handleChangeDate = (start, end) => {
    setStart(start);
    setEnd(end);
  };

  const mapTab = (item, key) => {
    if (key === "classSessionScheduleList") {
      const { startTime, endTime } = item;
      let color;
      if (moment().isAfter(moment(startTime)) && moment().isAfter(moment(endTime))) {
        color = "grey";
      } else if (
        moment().isBefore(moment(startTime), "day") &&
        moment().isBefore(moment(endTime), "day")
      ) {
        color = "#BCD9F2";
      } else if (moment().isSame(moment(startTime), "day")) {
        color = "#2576B9";
      } else {
        color = "#FFEBDA";
      }
      return { type: "Lesson", color };
    }
    if (key === "testEOCCList") {
      const { startTime, endTime } = item;
      let color;
      if (moment().isAfter(moment(endTime))) {
        color = "grey";
      } else if (moment().isSameOrBefore(moment(startTime), "day")) {
        color = "#F4CE14";
      } else {
        color = "#FFEBDA";
      }
      return { type: "EOCC & Test", color };
    }
    if (key === "ptDemoList") {
      const { startTime, endTime } = item;
      let color;
      if (moment().isAfter(moment(endTime))) {
        color = "grey";
      } else if (moment().isSameOrBefore(moment(startTime), "day")) {
        color = "#EABEFF";
      } else {
        color = "#FFEBDA";
      }
      return { type: "PT/Demo", color };
    }
    if (key === "feedback") {
      const { deadlineDate, feedBackTrackingId } = item;
      let color;
      if (moment().isAfter(moment(deadlineDate))) {
        color = "grey";
        if (feedBackTrackingId === 4 || feedBackTrackingId === 2) {
          color = "red";
        }
      } else if (moment().isSameOrBefore(moment(deadlineDate), "day")) {
        color = "#0DCAF0";
      } else {
        color = "#FFEBDA";
      }
      return { type: "Feedback", color };
    }
    if (key === "dailyReports") {
      const { deadline } = item;
      let color;
      if (moment().isAfter(moment(deadline))) {
        color = "grey";
        if (item.status === "OVERDUE") {
          color = "red";
        }
      } else if (moment().isSameOrBefore(moment(deadline), "day")) {
        color = "springgreen";
      } else {
        color = "#FFEBDA";
      }
      return { type: "Daily Report", color };
    }
    if (key === "progressReports") {
      const { deadline } = item;
      let color;
      if (moment().isAfter(moment(deadline))) {
        color = "grey";
        if (item.status === "OVERDUE") {
          color = "red";
        }
      } else if (moment().isSameOrBefore(moment(deadline), "day")) {
        color = "springgreen";
      } else {
        color = "#FFEBDA";
      }
      return { type: "Progress Report", color };
    }
    if (key === "gradeBookReports") {
      const { gradeBookDue } = item;
      let color;
      if (moment().isAfter(moment(gradeBookDue))) {
        color = "grey";
        if (item.status === "OVERDUE") {
          color = "red";
        }
      } else if (moment().isSameOrBefore(moment(gradeBookDue), "day")) {
        color = "springgreen";
      } else {
        color = "#FFEBDA";
      }
      return { type: "Gradebook Report", color };
    }
    if (key === "studentAtRiskTask") {
      return { type: "Students at risk", color: "orange" };
    }
    return { type: "", color: "white" };
  };

  const mapTitle = (item, key) => {
    if (key === "classSessionScheduleList") return item.className;
    if (key === "testEOCCList") return item.className;
    if (key === "ptDemoList") return item.eventTypeName;
    if (key === "feedback") return item.feedBackCode;
    if (key === "dailyReports") return item.className;
    if (key === "gradeBookReports") return item.className;
    if (key === "progressReports") return item.className;
    if (key === "studentAtRiskTask") return `${item.className}`;
    return "";
  };
  const convertTime = (time, diff = 0) =>
    moment(time)
      .add(-7, "hours")
      .add(diff ?? 0, "minutes")
      .add(timezone, "hours")
      .format("YYYY-MM-DD HH:mm:ss");

  const mapTime = (item, key) => {
    if (key === "feedback")
      return {
        start: convertTime(item.deadlineDate),
        end: convertTime(item.deadlineDate, 1),
      };
    if (key === "dailyReports")
      return {
        start: convertTime(item.deadline),
        end: convertTime(item.deadline, 30),
      };
    if (key === "gradeBookReports")
      return {
        start: convertTime(item.gradeBookDue),
        end: convertTime(item.gradeBookDue, 30),
      };
    if (key === "progressReports")
      return {
        start: convertTime(item.deadline),
        end: convertTime(item.deadline, 30),
      };

    // if (key === "studentAtRiskTask")
    //   return {
    //     start: convertTime(item.startTime),
    //     end: convertTime(item.startTime, 30),
    //   };
    return {
      start: convertTime(item.startTime),
      end: convertTime(item.endTime),
    };
  };

  const mapDeadline = (item, key) => {
    if (key === "feedback") return convertTime(item.deadlineDate);
    if (key === "dailyReports") return convertTime(item.deadline);
    if (key === "progressReports") return convertTime(item.deadline);
    if (key === "gradeBookReports") return convertTime(item.gradeBookDue);
  };

  useEffect(() => {
    if (start && end) {
      trigger({
        page: "",
        size: 100,
        sort: "",
        start,
        end,
        type: tab.value,
      });
    }
  }, [start, end, tab]);

  useEffect(() => {
    if (result?.data?.status) {
      let data = result?.data?.data;

      const newEvents = Object.entries(data ?? {})
        .reduce((acc, [key, values]) => {
          values?.forEach((item) => {
            if (key === "studentAtRiskTask") {
              const className = item.className;
              const studentAtRiskClassEventIndex = acc.findIndex(
                (event) => event.extendedProps.studentAtRiskClassName === className
              );

              const lessons = [...acc].filter((_acc) => _acc.type === "Lesson");

              lessons.forEach((lesson) => {
                const { start, end, title } = lesson;
                if (
                  moment(start).isAfter(moment(item.startTime)) &&
                  moment(end).isBefore(moment(item.endTime)) &&
                  title === className
                ) {
                  if (studentAtRiskClassEventIndex === -1) {
                    acc.push({
                      title: mapTitle(item, key),
                      ...{
                        start: convertTime(start),
                        end: convertTime(end),
                      },
                      backgroundColor: mapTab(item, key).color,
                      extendedProps: {
                        data: [item],
                        deadline: mapDeadline(item, key) ?? "",
                        studentAtRiskClassName: className,
                      },
                      type: mapTab(item, key).type,
                    });
                  } else {
                    acc[studentAtRiskClassEventIndex].extendedProps.data = [
                      ...acc[studentAtRiskClassEventIndex].extendedProps.data,
                      item,
                    ];
                  }
                }
              });
              // if (studentAtRiskClassEventIndex === -1) {
              //   acc.push({
              //     title: mapTitle(item, key),
              //     ...mapTime(item, key),
              //     backgroundColor: mapTab(item, key).color,
              //     extendedProps: {
              //       data: [item],
              //       deadline: mapDeadline(item, key) ?? "",
              //       studentAtRiskClassName: className,
              //     },
              //     type: mapTab(item, key).type,
              //   });
              // } else {
              //   acc[studentAtRiskClassEventIndex].extendedProps.data = [
              //     ...acc[studentAtRiskClassEventIndex].extendedProps.data,
              //     item,
              //   ];
              // }
            } else if (key === "gradeBookReports") {
              const className = item.className;
              const gradeBookReportEventIndex = acc.findIndex(
                (event) => event.extendedProps.gradeBookReportsClassName === className
              );
              if (gradeBookReportEventIndex === -1) {
                acc.push({
                  title: mapTitle(item, key),
                  ...mapTime(item, key),
                  backgroundColor: mapTab(item, key).color,
                  extendedProps: {
                    data: [item],
                    deadline: mapDeadline(item, key) ?? "",
                    gradeBookReportsClassName: className,
                  },
                  type: mapTab(item, key).type,
                });
              } else {
                acc[gradeBookReportEventIndex].extendedProps.data = [
                  ...acc[gradeBookReportEventIndex].extendedProps.data,
                  item,
                ];
              }
            } else if (key === "progressReports") {
              const className = item.className;
              const progressReportEventIndex = acc.findIndex(
                (event) => event.extendedProps.progressReportsClassName === className
              );
              if (progressReportEventIndex === -1) {
                acc.push({
                  title: mapTitle(item, key),
                  ...mapTime(item, key),
                  backgroundColor: mapTab(item, key).color,
                  extendedProps: {
                    data: [item],
                    deadline: mapDeadline(item, key) ?? "",
                    progressReportsClassName: className,
                  },
                  type: mapTab(item, key).type,
                });
              } else {
                acc[progressReportEventIndex].extendedProps.data = [
                  ...acc[progressReportEventIndex].extendedProps.data,
                  item,
                ];
              }
            } else {
              acc.push({
                title: mapTitle(item, key),
                ...mapTime(item, key),
                backgroundColor: mapTab(item, key).color,
                extendedProps: {
                  data: item,
                  deadline: mapDeadline(item, key) ?? "",
                },
                type: mapTab(item, key).type,
              });
            }
          });
          return acc;
        }, [])
        .filter((event, _index) => {
          return !(tab.value === "all" && event.type === "Daily Report");
        })
        .filter((event, _index) => {
          return !(tab.value === "task" && event.type === "Lesson");
        })
        .filter((event, _index, self) => {
          if (event.type === "Lesson") {
            const { start, end } = event;
            const eventTestEOCC = self.find(
              (_event) =>
                _event.start === start && _event.end === end && _event.type === "EOCC & Test"
            );
            if (eventTestEOCC) return false;
          }
          return true;
        });

      const newEventsByDay = (newEvents ?? []).reduce((acc, current) => {
        const date = current?.start?.split(" ")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(current);
        return acc;
      }, {});

      setEvents(newEvents);
      setEventsByDay(newEventsByDay);
    } else {
      setEvents([]);
      setEventsByDay({});
    }
  }, [result, tab, timezone]);

  return {
    events,
    eventsByDay,
    setEnd,
    setStart,
    handleChangeDate,
    tab,
    tabs,
    setTab,
    timezone,
    setTimeZone,
    loading: result.isFetching,
  };
};
