import * as Yup from "yup";

export const form = {
  formId: "reset-password-form",
  formField: {
    password: {
      name: "password",
      label: "Type password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password should be more than 6 characters.",
    },
    repeatPassword: {
      name: "repeatPassword",
      label: "Type password one more time ",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password doesn't match.",
    },
  },
};

const {
  formField: { password, repeatPassword },
} = form;

export const initialValues = {
  [password.name]: "",
  [repeatPassword.name]: "",
};

export const validations = Yup.object().shape({
  [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
  [repeatPassword.name]: Yup.string()
    .required(repeatPassword.errorMsg)
    .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
});
