import { useEffect } from "react";

export const useHorizontalSroll = () => {
  useEffect(() => {
    const events = document.querySelectorAll(`[data-horizontal-scroll]`);

    let isDown = false;
    let startX = 0;
    let scrollLeft = 0;
    events.forEach((event) => {
      event.addEventListener("mousedown", (e) => {
        e.stopPropagation();
        e.preventDefault();
        isDown = true;
        event.classList.add("active");
        startX = e.pageX - event.offsetLeft;
        scrollLeft = event.scrollLeft;
      });

      event.addEventListener("mouseleave", () => {
        isDown = false;
        event.classList.remove("active");
      });
      event.addEventListener("mouseup", () => {
        isDown = false;
        event.classList.remove("active");
      });
      event.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.stopPropagation();
        e.preventDefault();
        const x = e.pageX - event.offsetLeft;
        const walk = (x - startX) * 1;
        event.scrollLeft = scrollLeft - walk;
      });
    });
  }, []);
};
