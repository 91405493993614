import { useLocation } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import { form, initialValues, validations } from "./form";
import { useFormik } from "formik";
import theme from "assets/theme";
import { useResetPasswordMutation } from "shared/redux/endpoints/auth";
import { toast } from "react-toastify";
import pxToRem from "assets/theme/functions/pxToRem";
import typography from "assets/theme/base/typography";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import { useState } from "react";
import queryString from "query-string";

const inputStyle = {
  "& .MuiInputBase-root, & .MuiInputBase-root .MuiInputBase-input": {
    height: "100%",
    lineHeight: "100%",
  },

  "& .MuiInputBase-root .MuiInputBase-input": {
    paddingLeft: pxToRem(20),
  },

  "& input::placeholder": {
    fontFamily: typography.lexendFont,
  },

  "& label": {
    fontSize: 14,
    lineHeight: "16px",
    fontFamily: typography.lexendFont,
    color: "#A0AEC0",
    left: 6,
  },

  "& legend": {
    marginLeft: 6,
  },
};

function ResetPassword() {
  const {
    typography: { lexendFont, openSansFont },
  } = theme;
  const { formId, formField } = form;
  const { repeatPassword, password } = formField;
  const location = useLocation();

  const [resetPassword, isLoading] = useResetPasswordMutation();

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowRepeatPassword = () => setShowRepeatPassword((show) => !show);

  const formik = useFormik({
    initialValues,
    validationSchema: validations,
    onSubmit: async (values) => {
      const { token } = queryString.parse(location.search);
      const result = await resetPassword({ token, newPassword: values.password });

      if (result?.data?.status) {
        toast.success("Reset password successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: {
            fontFamily: "Lexend",
            fontSize: 14,
          },
        });
      } else {
        toast.error(
          "Your reset link has expired. Please note that it is only valid for 24 hours. Kindly return to My Apollo and request a new password reset.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            style: {
              fontFamily: "Lexend",
              fontSize: 14,
            },
          }
        );
      }
    },
  });

  const passwordError = formik.touched.password && Boolean(formik.errors.password);
  const repeatPasswordError =
    formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword);

  return (
    <IllustrationLayout title="Reset password" illustration={bgImage}>
      <MDBox component="form" role="form" id={formId} onSubmit={formik.handleSubmit}>
        <MDBox mb={2}>
          <MDInput
            type={showPassword ? "text" : password.type}
            label={password.label}
            name={password.name}
            variant="outlined"
            fullWidth
            placeholder="************"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={passwordError}
            sx={({ functions: { pxToRem } }) => ({
              height: pxToRem(50),
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                    size="small"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            isPassword
            customStyle={inputStyle}
          />
          {passwordError ? (
            <MDTypography
              sx={{
                color: "#f44335",
                fontFamily: lexendFont,
                fontWeight: 400,
                fontSize: 10,
                lineHeight: 1.5,
              }}
            >
              {formik.errors.password}
            </MDTypography>
          ) : (
            <></>
          )}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type={showRepeatPassword ? "text" : repeatPassword.type}
            label={repeatPassword.label}
            name={repeatPassword.name}
            variant="outlined"
            fullWidth
            placeholder="************"
            value={formik.values.repeatPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={repeatPasswordError}
            sx={({ functions: { pxToRem } }) => ({
              height: pxToRem(50),
            })}
            isPassword
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowRepeatPassword}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                    size="small"
                  >
                    {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            customStyle={inputStyle}
          />
          {repeatPasswordError ? (
            <MDTypography
              sx={{
                color: "#f44335",
                fontFamily: lexendFont,
                fontWeight: 400,
                fontSize: 10,
                lineHeight: 1.5,
              }}
            >
              {formik.errors.repeatPassword}
            </MDTypography>
          ) : (
            <></>
          )}
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton
            variant="gradient"
            color="info"
            size="large"
            fullWidth
            sx={({ typography, functions: { pxToRem } }) => ({
              height: pxToRem(40),
              fontSize: 14,
              lineHeight: 1.5,
              fontWeight: 700,
              textTransform: "unset",
              background: "#5E72E4!important",
              fontFamily: typography.lexendFont,
            })}
            type="submit"
          >
            Reset
          </MDButton>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default ResetPassword;
