import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "examples/Header";
import {
  containerStyle,
  descriptionStyle,
  firstRowStyle,
  iconStyle,
  itemStyle,
  itemTitleStyle,
  percentStyle,
  containerBoxStyle,
  boxItem,
  titleStyle,
  descStyle,
} from "./style";

import { titleTypography } from "shared/styles/style";
import { noteTypography } from "shared/styles/style";

import CompletionRateIcon from "assets/images/apollo-english/complete-rate-icon.png";
import { SVGIcon } from "components/icons";
import styled from "@emotion/styled";
import { Course } from "./components/Course";
import { selectAuth } from "shared/redux/slices/authSlice";

import {
  useLazyGetPhaseBoardQuery,
  useLazyGetListPhaseQuery,
} from "shared/redux/endpoints/my-development";
import { descriptionTypography } from "shared/styles/style";
import { useSelector } from "react-redux";
import { CoursePieChart } from "./components/PieChart";
import { WorkShop } from "./components/WorkShop";
import InfoIcon, { TooltipIconColor, TooltipType } from "../../components/InfoIcon";
import { teacherInfoIconBlock } from "../../components/InfoIcon/teacherInfoIcon";
import makeStyles from "../../pages/style";

const StyledLabel = styled("text")(({ theme }) => ({
  fill: "#F75C3A",
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 24,
  fontWeight: 700,
  lineHeight: 1.5,
  fontFamily: theme.typography.lexendFont,

  [theme.breakpoints.down(1600)]: {
    fontSize: 20,
  },
}));

// eslint-disable-next-line
function Label({ children }) {
  return (
    <StyledLabel x={55} y={55}>
      {children}
    </StyledLabel>
  );
}

const tabs = [
  {
    title: "Course",
    link: "my-tasks",
  },
  {
    title: "Workshop",
    link: "my-classes",
  },
  {
    title: "Observation",
    link: "my-students",
  },
];

const phaseMapping = {
  1: teacherInfoIconBlock.PHASE_1,
  2: teacherInfoIconBlock.PHASE_2,
  3: teacherInfoIconBlock.PHASE_3,
};
export const MyDevelopment = () => {
  const classes = makeStyles();
  const [getPhaseBoard, { data: dataBoard }] = useLazyGetPhaseBoardQuery();
  const [getListPhase, { data: dataList }] = useLazyGetListPhaseQuery();

  const [open, setOpen] = useState([true, false, false]);

  const auth = useSelector(selectAuth);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    getPhaseBoard();
    getListPhase();
  }, []);

  const progressPhase1 = dataBoard?.data?.phaseComplete?.phase_1;
  const progressPhase2 = dataBoard?.data?.phaseComplete?.phase_2;
  const progressPhase3 = dataBoard?.data?.phaseComplete?.phase_3;
  const completeRate = dataBoard?.data?.completeRate;
  const statusPhase1 = dataList?.data?.openPhase1;
  const statusPhase2 = dataList?.data?.openPhase2;
  const statusPhase3 = dataList?.data?.openPhase3;

  const phaseData = [
    {
      title: "WELEARN - PHASE 1",
      //desc: "0-6 months",
      // desc: "You are required to finish all the courses below",
      data: dataList?.data?.phase.phase_1,
      disable: !statusPhase1,
    },
    {
      title: "WELEARN - PHASE 2",
      //desc: "7-11.5 months",
      // desc: "Copy the code bellow to your registered provider.",
      data: dataList?.data?.phase.phase_2,
      disable: !statusPhase2,
    },
    {
      title: "WELEARN - PHASE 3",
      // desc: "2nd year +",
      // desc: "Copy the code bellow to your registered provider.",
      data: dataList?.data?.phase.phase_3,
      disable: !statusPhase3,
    },
  ];

  const dataPhase1 = [
    { id: 0, value: progressPhase1?.complete, label: "complete", color: "#5E72E4" },
    { id: 1, value: progressPhase1?.notComplete, label: "not complete", color: "#F5365C" },
  ];

  const dataPhase2 = [
    { id: 0, value: progressPhase2?.complete, label: "complete", color: "#5E72E4" },
    { id: 1, value: progressPhase2?.notComplete, label: "not complete", color: "#F5365C" },
  ];

  const dataPhase3 = [
    { id: 0, value: progressPhase3?.complete, label: "complete", color: "#5E72E4" },
    { id: 1, value: progressPhase3?.notComplete, label: "not complete", color: "#F5365C" },
  ];

  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const events = document.querySelectorAll(`[data-horizontal-scroll]`);

    let isDown = false;
    let startX = 0;
    let scrollLeft = 0;
    let scrollTimeout;
    events.forEach((event) => {
      event.addEventListener("mousedown", (e) => {
        isDown = true;
        event.classList.add("active");
        startX = e.pageX - event.offsetLeft;
        scrollLeft = event.scrollLeft;
      });

      event.addEventListener("mouseleave", () => {
        isDown = false;
        event.classList.remove("active");
      });
      event.addEventListener("mouseup", () => {
        isDown = false;
        event.classList.remove("active");
      });
      event.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - event.offsetLeft;

        const walk = (x - startX) * 1; // scroll-fast

        event.scrollLeft = scrollLeft - walk;
      });

      const handleScroll = () => {
        setIsScrolling(true);

        clearTimeout(scrollTimeout);

        scrollTimeout = setTimeout(() => {
          setIsScrolling(false);
        }, 500);
      };

      event?.addEventListener("scroll", handleScroll);

      return () => {
        event?.removeEventListener("scroll", handleScroll);
      };
    });
  }, []);

  return (
    <DashboardLayout>
      <Header />
      <MDBox sx={(theme) => containerStyle(theme)}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          columnSpacing={3}
          sx={({ breakpoints, functions: { pxToRem } }) => ({
            borderRadius: pxToRem(16),
            background: "#fff",
            padding: `${pxToRem(24)}`,
            marginBottom: pxToRem(24),

            [breakpoints.down(1600)]: {
              rowGap: 2.5,
              borderRadius: pxToRem(8),
            },

            [breakpoints.down(1366)]: {
              flexDirection: "column",
            },

            [breakpoints.down("md")]: {
              rowGap: 2,
            },
          })}
        >
          <MDBox display="flex" alignItems="center" gap={3}>
            <MDBox
              component="img"
              src={auth?.user?.avatar}
              sx={{ width: "74px", height: "74px" }}
            ></MDBox>
            <MDBox>
              <MDTypography
                sx={({ breakpoints }) => ({
                  ...titleTypography(breakpoints),
                  lineHeight: 1.37,
                  letterSpacing: "-0.8px",
                  marginBottom: "4px",
                })}
              >
                {auth?.user?.fullName}
              </MDTypography>
              <MDTypography
                sx={({ breakpoints }) => ({
                  ...descriptionTypography(breakpoints),
                  lineHeight: 1.5,
                })}
              >
                {auth?.role}
              </MDTypography>
            </MDBox>
          </MDBox>

          <Grid
            container
            alignItems="center"
            sx={({ functions: { pxToRem } }) => ({
              borderRadius: pxToRem(12),
              padding: pxToRem(4),
              background: "#F6F9FC",
              maxWidth: "420px",
            })}
          >
            {tabs.map((item, index) => {
              return (
                <Grid
                  item
                  xs={4}
                  key={index}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                  sx={({ functions: { pxToRem } }) => ({
                    height: pxToRem(35),
                    borderRadius: pxToRem(8),
                    background: tabIndex === index ? "white" : "inherite",
                    boxShadow: tabIndex === index ? "0px 2px 6px 0px #00000040" : "unset",
                    cursor: "pointer",
                  })}
                  onClick={() => {
                    setTabIndex(index);
                    //navigate(`/assistant/${item.link}`);
                  }}
                >
                  <MDTypography
                    sx={({ breakpoints, typography }) => ({
                      ...noteTypography(breakpoints),
                      lineHeight: 1.25,
                    })}
                  >
                    {item.title}
                  </MDTypography>
                </Grid>
              );
            })}
          </Grid>
        </MDBox>
        {tabIndex === 0 && (
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              columnSpacing={{ xs: 1, md: 2, xxxl: 3 }}
              sx={(theme) => firstRowStyle(theme)}
            >
              <Grid item xs={12} md={6} xxl={4}>
                <MDBox sx={(theme) => itemStyle(theme, { type: "completion-rate" })}>
                  <MDBox display="flex" justifyContent="space-between" alignItems="center">
                    <MDBox
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      gap={1}
                      width="100%"
                    >
                      <MDBox
                        component="img"
                        src={CompletionRateIcon}
                        sx={(theme) => iconStyle(theme)}
                      ></MDBox>
                      <InfoIcon
                        type={TooltipType.TYPE_1}
                        teacherInfoIconBlock={teacherInfoIconBlock.COMPLETION_RATE}
                        color={TooltipIconColor.BLUE}
                        className={classes.containerInfoIconWithHeading}
                      >
                        <MDTypography
                          sx={(theme) => ({
                            ...titleTypography(theme.breakpoints),
                            ...itemTitleStyle(theme),
                          })}
                        >
                          COMPLETION RATE
                        </MDTypography>
                      </InfoIcon>
                      <MDBox sx={{ marginLeft: "auto" }}>
                        <SVGIcon name="sunny" />
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" alignItems="flex-start" gap={1} mb={1}>
                    <MDTypography variant="span" sx={(theme) => percentStyle(theme)}>
                      {typeof completeRate === "number" ? completeRate + "%" : ""}
                    </MDTypography>
                    <MDBox display="flex" alignItems="center" gap={0.5}></MDBox>
                  </MDBox>
                  <MDBox>
                    <MDTypography
                      sx={(theme) => ({
                        ...descriptionTypography(theme.breakpoints),
                        ...descriptionStyle(theme),
                      })}
                    >
                      &#34;WeLearn&#34; is our in-house training platform designed to equip all our
                      teachers and staff with the essential courses for a smooth onboarding process,
                      as well as the necessary skills for the job.
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Grid>

              {[1, 2, 3].map((item, index) => {
                return (
                  <Grid item xs={12} md={6} xxl={2.66} key={item}>
                    <MDBox
                      sx={(theme) =>
                        itemStyle(theme, { type: "course-control", active: open[index] })
                      }
                      onClick={() => {
                        setOpen((_open) => {
                          let temp = [..._open];
                          temp = [false, false, false];
                          temp[index] = !_open[index];
                          return temp;
                        });
                      }}
                    >
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                      >
                        <MDBox
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                          gap={1}
                        >
                          <InfoIcon
                            type={TooltipType.TYPE_1}
                            teacherInfoIconBlock={phaseMapping[item]}
                            color={TooltipIconColor.BLUE}
                            className={classes.containerInfoIconWithHeading}
                          >
                            <MDTypography
                              sx={(theme) => ({
                                ...titleTypography(theme.breakpoints),
                                ...itemTitleStyle(theme),
                                lineHeight: "52px !important",
                              })}
                            >
                              Phase {item}
                            </MDTypography>
                          </InfoIcon>
                        </MDBox>
                      </MDBox>

                      <MDBox display="flex" justifyContent="space-between" alignItems="center">
                        <MDBox
                          display="flex"
                          justifyContent="flex-start"
                          mr={2}
                          sx={{ flexBasis: 80, flexGrow: 0 }}
                        >
                          <CoursePieChart
                            data={item === 1 ? dataPhase1 : item === 2 ? dataPhase2 : dataPhase3}
                          />
                        </MDBox>
                        <MDBox
                          sx={{
                            flexGrow: 1,
                          }}
                        >
                          <Grid container alignItems="center" p={{ xs: 0.25, xxl: 0.5 }}>
                            <Grid xs="auto" item>
                              <MDBox
                                sx={({ breakpoints, functions: { pxToRem } }) => ({
                                  width: pxToRem(24),
                                  height: pxToRem(24),
                                  borderRadius: pxToRem(8),
                                  background: "#5E72E4",

                                  [breakpoints.down(1600)]: {
                                    width: pxToRem(20),
                                    height: pxToRem(20),
                                    borderRadius: pxToRem(4),
                                  },
                                })}
                              ></MDBox>
                            </Grid>
                            <Grid
                              xs
                              item
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              ml={2}
                            >
                              <MDTypography
                                sx={({ breakpoints }) => ({
                                  ...titleTypography(breakpoints),
                                  textTransform: "unset",
                                  lineHeight: 1.625,
                                  letterSpacing: "-0.8px",
                                })}
                              >
                                {"Complete"}
                              </MDTypography>
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            sx={{ width: "100%" }}
                            alignItems="center"
                            p={{ xs: 0.25, xxl: 0.5 }}
                          >
                            <Grid xs="auto" item>
                              <MDBox
                                sx={({ breakpoints, functions: { pxToRem } }) => ({
                                  width: pxToRem(24),
                                  height: pxToRem(24),
                                  borderRadius: pxToRem(8),
                                  background: "#F5365C",

                                  [breakpoints.down(1600)]: {
                                    width: pxToRem(20),
                                    height: pxToRem(20),
                                    borderRadius: pxToRem(4),
                                  },
                                })}
                              ></MDBox>
                            </Grid>
                            <Grid
                              xs
                              item
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              ml={2}
                            >
                              <MDTypography
                                sx={({ breakpoints }) => ({
                                  ...titleTypography(breakpoints),
                                  textTransform: "unset",
                                  lineHeight: 1.625,
                                  letterSpacing: "-0.8px",
                                })}
                              >
                                {"Not complete"}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Grid>
                );
              })}
            </Grid>
            {phaseData.map((item, index) => {
              return (
                <MDBox
                  sx={{
                    borderRadius: "12px",
                    background: "white",
                    padding: "20px",
                    cursor: "pointer",
                    display: open[index] ? "block" : "none",
                  }}
                  mt={2}
                  key={item.title}
                >
                  <MDBox
                    sx={{
                      borderRadius: "12px",
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    <MDTypography sx={(theme) => titleStyle(theme)}>{item.title}</MDTypography>
                    <MDTypography sx={(theme) => descStyle(theme)}>{item.desc}</MDTypography>

                    <MDBox
                      sx={(theme) => containerBoxStyle(theme, { isScrolling })}
                      style={{
                        display: open[index] ? "flex" : "none",
                      }}
                    >
                      {item?.data
                        ?.filter((_item) => _item.status === "Not Complete")
                        ?.map((item1) => {
                          return (
                            <MDBox sx={(theme) => boxItem(theme)} key={item.id}>
                              <Course {...item1} disable={item.disable} />
                            </MDBox>
                          );
                        })}
                    </MDBox>
                  </MDBox>
                </MDBox>
              );
            })}
          </>
        )}
        {tabIndex === 1 && <WorkShop />}
      </MDBox>
    </DashboardLayout>
  );
};
