import { forwardRef } from "react";
import PropTypes from "prop-types";
import MDInputRoot from "components/MDInput/MDInputRoot";

const MDInput = forwardRef(
  ({ error, success, disabled, customStyle, isPassword, ...rest }, ref) => (
    <MDInputRoot
      {...rest}
      ref={ref}
      disabled={disabled}
      ownerState={{ error, success, disabled, customStyle, isPassword }}
    />
  )
);

// Setting default values for the props of MDInput
MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
  customStyle: {},
  isPassword: false,
};

// Typechecking props for the MDInput
MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  customStyle: PropTypes.any,
  isPassword: PropTypes.bool,
};

export default MDInput;
