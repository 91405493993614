/* Custom Icons */
import MenuHomeIcon from "components/icons/MenuHomeIcon";
import MenuHomeActiveIcon from "components/icons/MenuHomeActiveIcon";
import MenuAsistantIcon from "components/icons/MenuAsistantIcon";
import MenuAsistantActiveIcon from "components/icons/MenuAsistantActiveIcon";
import MenuDevelopmentIcon from "components/icons/MenuDevelopmentIcon";
import MenuDevelopmentActiveIcon from "components/icons/MenuDevelopmentActiveIcon";
import MenuPerformanceIcon from "components/icons/MenuPerformanceIcon";
import MenuPerformanceActiveIcon from "components/icons/MenuPerformanceActiveIcon";
import MenuCalendarIcon from "components/icons/MenuCalendarIcon";
import MenuCalendarActiveIcon from "components/icons/MenuCalendarActiveIcon";
import MenuSelf from "components/icons/MenuSelf";
import MenuSelfActive from "components/icons/MenuSelfActive";

/* Pages */
import ResetPasswordValidate from "pages/reset-password-validate";
import ResetPassword from "pages/reset-password";
import GoogleSuccess from "pages/google-success";
import GoogleFail from "pages/google-fail";
import Home from "pages/home";
import Calendar from "pages/calendar";
import SignIn from "pages/sign-in";
import ChangePassword from "pages/change-password";
import Assistant from "pages/assistant";
import StudentDetail from "pages/student-detail";
import MySelf from "pages/my-self";
import { MyDevelopment } from "pages/development";
import { Notification } from "pages/notifications";
import MyImpact from "pages/impact";

/* Admin Pages */
import { AdminTeacher } from "admin/teacher";
import { AdminAdmin } from "admin/admin";
import { TeacherDetail } from "admin/teacher-detail";
import { AdminNotification } from "admin/notification";
import { AdminTemplate } from "admin/templates";
import { AdminBanner } from "admin/banner";

const routes = [
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Home page",
    key: "",
    icon: <MenuHomeIcon />,
    iconActive: <MenuHomeActiveIcon />,
    noCollapse: true,
    component: <Home />,
    route: "/",
    isPrivate: true,
    redirectPath: "/sign-in",
  },
  {
    type: "collapse",
    name: "My Calendar",
    key: "calendar",
    icon: <MenuCalendarIcon />,
    iconActive: <MenuCalendarActiveIcon />,
    noCollapse: true,
    component: <Calendar />,
    route: "/calendar",
    isPrivate: true,
    redirectPath: "/sign-in?redirectTo=calendar",
  },
  {
    type: "collapse",
    name: "My Assistant",
    key: "assistant",
    icon: <MenuAsistantIcon />,
    iconActive: <MenuAsistantActiveIcon />,
    route: "/assistant/:tab",
    hiddenInSidenav: true,
    component: <Assistant />,
    noCollapse: true,
    isPrivate: true,
    redirectPath: "/sign-in",
  },
  {
    type: "collapse",
    name: "My Assistant",
    key: "assistant",
    icon: <MenuAsistantIcon />,
    iconActive: <MenuAsistantActiveIcon />,
    route: "/assistant",
    //href: "/assistant",
    component: <Assistant />,
    noCollapse: true,
    isPrivate: true,
    redirectPath: "/sign-in",
    // collapse: [
    //   {
    //     name: "My tasks",
    //     key: "my-tasks",
    //     route: "/assistant/my-tasks",
    //     component: <Assistant tab={0} />,
    //     isPrivate: true,
    //     redirectPath: "/sign-in",
    //   },
    //   {
    //     name: "My classes",
    //     key: "my-classes",
    //     route: "/assistant/my-classes",
    //     component: <Assistant tab={1} />,
    //     isPrivate: true,
    //     redirectPath: "/sign-in",
    //   },
    //   {
    //     name: "My students",
    //     key: "my-students",
    //     route: "/assistant/my-students",
    //     component: <Assistant tab={2} />,
    //     isPrivate: true,
    //     redirectPath: "/sign-in",
    //   },
    //   {
    //     name: "My EPP",
    //     key: "my-epp",
    //     route: "/assistant/my-epp",
    //     component: <Assistant tab={3} />,
    //     isPrivate: true,
    //     redirectPath: "/sign-in",
    //   },
    // ],
  },
  {
    type: "collapse",
    name: "Student Detail",
    key: "student-detail",
    noCollapse: true,
    component: <StudentDetail />,
    hiddenInSidenav: true,
    route: "/student-detail/:id",
  },
  {
    type: "collapse",
    name: "My Development",
    key: "development",
    icon: <MenuDevelopmentIcon />,
    iconActive: <MenuDevelopmentActiveIcon />,
    noCollapse: true,
    component: <MyDevelopment />,
    route: "/development",
  },
  {
    type: "collapse",
    name: "My Impact",
    key: "impact",
    icon: <MenuPerformanceIcon />,
    iconActive: <MenuPerformanceActiveIcon />,
    noCollapse: true,
    component: <MyImpact />,
    route: "/impact",
  },
  {
    type: "collapse",
    name: "Myself",
    key: "myself",
    icon: <MenuSelf />,
    iconActive: <MenuSelfActive />,
    noCollapse: true,
    component: <MySelf />,
    route: "/myself",
    isPrivate: true,
    redirectPath: "/sign-in",
  },
  {
    type: "collapse",
    name: "My Notification",
    key: "notification",
    icon: <MenuPerformanceIcon />,
    iconActive: <MenuPerformanceActiveIcon />,
    noCollapse: true,
    component: <Notification />,
    route: "/notification/:id",
    isPrivate: true,
    redirectPath: "/sign-in",
    hiddenInSidenav: true,
  },
];

export const adminRoutes = [
  {
    type: "collapse",
    name: "User Management",
    key: "user-management",
    icon: <MenuPerformanceIcon />,
    iconActive: <MenuPerformanceActiveIcon />,
    noCollapse: false,
    component: <AdminAdmin />,
    route: "/user-management/admin-management",
    isPrivate: true,
    redirectPath: "/sign-in",
    //hiddenInSidenav: true,
    collapse: [
      {
        type: "collapse",
        name: "Admin Managment",
        key: "admin-management",
        icon: <MenuPerformanceIcon />,
        iconActive: <MenuPerformanceActiveIcon />,
        noCollapse: true,
        component: <AdminAdmin tab={1} />,
        route: "/user-management/admin-management",
        isPrivate: true,
        redirectPath: "/sign-in",
        // hiddenInSidenav: true,
      },

      {
        type: "collapse",
        name: "Teacher Management",
        key: "teacher-management",
        icon: <MenuPerformanceIcon />,
        iconActive: <MenuPerformanceActiveIcon />,
        noCollapse: true,
        component: <AdminTeacher tab={2} />,
        route: "/user-management/teacher-management",
        isPrivate: true,
        redirectPath: "/sign-in",
        // hiddenInSidenav: true,
      },
    ],
  },

  {
    type: "collapse",
    name: "Noti Management",
    key: "notification-management",
    icon: <MenuPerformanceIcon />,
    iconActive: <MenuPerformanceActiveIcon />,
    noCollapse: false,
    component: <AdminNotification />,
    route: "notification-management",
    isPrivate: true,
    redirectPath: "/sign-in",
    // hiddenInSidenav: true,
    collapse: [
      {
        name: "Send Notification",
        key: "send-notification",
        route: "notification-management/send-notification",
        component: <AdminNotification tab={2} />,
        isPrivate: true,
        redirectPath: "/sign-in",
      },
      {
        type: "collapse",
        name: "Template Notification",
        key: "notifications-templates",
        icon: <MenuPerformanceIcon />,
        iconActive: <MenuPerformanceActiveIcon />,
        component: <AdminTemplate />,
        route: "notification-management/notifications-templates",
        isPrivate: true,
        redirectPath: "/sign-in",
        //hiddenInSidenav: true,
      },
    ],
  },
  {
    type: "collapse",
    name: "Teacher Detail",
    key: "teacher-detail",
    icon: <MenuPerformanceIcon />,
    iconActive: <MenuPerformanceActiveIcon />,
    noCollapse: true,
    component: <TeacherDetail />,
    route: "/teacher-detail",
    isPrivate: true,
    redirectPath: "/sign-in",
    // hiddenInSidenav: true,
  },
  {
    type: "collapse",
    name: "Banner Management",
    key: "banner-management",
    icon: <MenuPerformanceIcon />,
    iconActive: <MenuPerformanceActiveIcon />,
    noCollapse: true,
    component: <AdminBanner />,
    route: "/banner-management",
    isPrivate: true,
    redirectPath: "/sign-in",
    // hiddenInSidenav: true,
  },
];

export const commonRoutes = [
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    noCollapse: true,
    component: <SignIn />,
    route: "/sign-in",
    hiddenInSidenav: true,
  },
  {
    type: "collapse",
    name: "Change Password",
    key: "change-password",
    noCollapse: true,
    component: <ChangePassword />,
    route: "/change-password",
    hiddenInSidenav: true,
    isPrivate: true,
    redirectPath: "/sign-in",
  },
  {
    type: "collapse",
    name: "Reset Password Validate",
    key: "reset-password-validate",
    noCollapse: true,
    component: <ResetPasswordValidate />,
    route: "/reset-password-validate",
    hiddenInSidenav: true,
  },
  {
    type: "collapse",
    name: "Reset Password",
    key: "reset-password",
    noCollapse: true,
    component: <ResetPassword />,
    route: "/reset-password",
    hiddenInSidenav: true,
  },
  {
    type: "collapse",
    name: "Google Success",
    key: "google-success",
    noCollapse: true,
    component: <GoogleSuccess />,
    route: "/google-success",
    hiddenInSidenav: true,
  },
  {
    type: "collapse",
    name: "Google Fail",
    key: "google-fail",
    noCollapse: true,
    component: <GoogleFail />,
    route: "/google-fail",
    hiddenInSidenav: true,
  },
];

export default routes;
