import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import PageLayout from "shared/examples/LayoutContainers/PageLayout";
import Footer from "shared/layouts/authentication/components/Footer";
import { useLocation } from "react-router-dom";

function CoverLayout({ coverHeight, image, children }) {
  const location = useLocation();
  return (
    <PageLayout sx={{ position: "relative" }}>
      <MDBox
        width="calc(100% - 2rem)"
        minHeight={coverHeight}
        borderRadius="xl"
        mx={2}
        my={2}
        pt={6}
        pb={28}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.4),
              rgba(gradients.dark.state, 0.4)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MDBox
        mt={location.pathname === "/change-password" ? { xs: -30, lg: -25 } : { xs: -20, lg: -15 }}
        px={1}
        pb={{ xs: 18, md: 20 }}
        width="calc(100% - 2rem)"
        mx="auto"
      >
        <Grid container spacing={1} justifyContent="center">
          {location.pathname === "/change-password" ? (
            <Grid item xs={11} xxl={7}>
              {children}
            </Grid>
          ) : (
            <Grid item xs={9} sm={6} md={5} lg={3} xl={4} xxl={3.5} xxxl={3}>
              {children}
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />
    </PageLayout>
  );
}

// Setting default props for the CoverLayout
CoverLayout.defaultProps = {
  coverHeight: "35vh",
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  coverHeight: PropTypes.string,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
