import React, { useEffect, useState } from "react";
import { Button, Modal, Table, TableBody, TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import {
  tableTextStyle,
  inputContainerStyle,
  inputStyle,
  tableContainerStyle,
  tableStyle,
  teacherManagementStyle,
  titleStyle,
  statusStyle,
  tableHeadStyle,
  pagingItemStyle,
  subTitleStyle,
  tableTextEmptyStyle,
} from "./style";
import {
  ChevronLeft,
  ChevronRight,
  Delete,
  EditNote,
  ExpandLess,
  ExpandMore,
  RemoveRedEye,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import CreateTemplate from "../CreateTemplate";
import MDSnackbar from "components/MDSnackbar";
import { useLazyGetTemplatesQuery } from "shared/redux/endpoints/admin";
import moment from "moment";
import DeleteTemplate from "../DeleteTemplate";
import { useNavigate } from "react-router-dom";

const EnhancedTableHead = ({ title }) => {
  return (
    <MDBox
      sx={({ breakpoints, functions: { pxToRem } }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginRight: pxToRem(24),

        [breakpoints.down(1367)]: {
          marginRight: pxToRem(16),
        },
      })}
    >
      <MDTypography sx={(theme) => tableHeadStyle(theme)}>{title}</MDTypography>
      <MDBox sx={() => ({ display: "flex", alignItems: "center" })}>
        <ExpandLess sx={({ palette }) => ({ color: palette.textColors.secondary })} />
        <ExpandMore sx={({ palette }) => ({ color: palette.textColors.secondary })} />
      </MDBox>
    </MDBox>
  );
};

EnhancedTableHead.propTypes = {
  title: PropTypes.string.isRequired,
};

function TemplateManagement() {
  const [openSuccessSB, setOpenSuccessSB] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [data, setData] = useState([]);

  const [sort, changeSort] = useState("created%2Cdesc");
  const [key, setKey] = useState("");

  const [openSuccessSBDelete, setOpenSuccessSBDelete] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedTemplateId, setDeletedTemplateId] = useState("");

  const [triggerTemplates, { isLoading }] = useLazyGetTemplatesQuery();

  const [template, setTemplate] = useState(null);

  const fetchTemplates = async () => {
    const response = await triggerTemplates({
      sort: sort ?? "",
      page,
      size: 10,
      key,
    });
    if (response?.isSuccess && response?.data?.status) {
      setTotalPage(response?.data?.data?.totalPage ?? 0);
      setData(response?.data?.data?.data);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, [page, sort, key]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseSuccess = () => setOpenSuccessSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Notification"
      content="Save template successfully"
      open={openSuccessSB}
      onClose={handleCloseSuccess}
      close={handleCloseSuccess}
      bgWhite
    />
  );

  const renderSuccessSBDelete = (
    <MDSnackbar
      color="success"
      icon="check"
      title="template"
      content="Delete template successfully"
      open={openSuccessSBDelete}
      onClose={() => setOpenSuccessSBDelete(false)}
      close={() => setOpenSuccessSBDelete(false)}
      bgWhite
    />
  );

  const pageRange = [];
  for (let i = 1; i <= totalPage; i++) {
    pageRange.push(i);
  }

  const filteredPages = pageRange.slice(Math.max(page - 2, 0), Math.min(page + 3, totalPage + 1));

  const navigation = useNavigate();

  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
      columnSpacing={3}
      sx={(theme) => teacherManagementStyle(theme)}
    >
      <MDBox display="flex" width="100%" alignItems="center" justifyContent="space-between">
        <MDBox
          display="flex"
          sx={() => ({
            flexDirection: "column",
            alignItems: "flex-start",
          })}
        >
          <MDTypography sx={(theme) => titleStyle(theme)}>Template management</MDTypography>
          <MDTypography sx={(theme) => subTitleStyle(theme)}>
            Create notification and announcement templates
          </MDTypography>
        </MDBox>
        <MDBox>
          <Button
            variant="contained"
            sx={{
              mr: 2,
              color: "#FFFFFF",
              backgroundColor: "#5E72E4",
              fontFamily: "Lexend, san-serif",
              textTransform: "unset",
              minHeight: 30,
              height: 30,
            }}
            component="label"
            role={undefined}
            tabIndex={-1}
            onClick={() => {
              setTemplate(null);
              handleOpen();
            }}
          >
            New template
          </Button>
          <Button
            variant="outlined"
            sx={{
              ml: "auto",
              color: "#5E72E4",
              backgroundColor: "#FFFFFF",
              borderColor: "#5E72E4!important",
              fontFamily: "Lexend, san-serif",
              textTransform: "unset",
              minHeight: 30,
              height: 30,
            }}
          >
            Export
          </Button>
        </MDBox>
      </MDBox>

      <MDBox sx={(theme) => inputContainerStyle(theme)}>
        <MDInput placeholder="Search here" sx={(theme) => inputStyle(theme)} />
      </MDBox>

      <MDBox width="100%">
        <MDBox sx={(theme) => tableContainerStyle(theme)}>
          <Table sx={(theme) => tableStyle(theme)}>
            <MDBox component="thead" sx={{ borderBottom: "1px solid #DEE2E6" }}>
              <TableRow
                sx={() => ({
                  height: "50px",
                  textAlign: "left",
                })}
              >
                <MDBox component="th" pl={1.5}>
                  <EnhancedTableHead title="English template name" />
                </MDBox>
                <MDBox component="th">
                  <EnhancedTableHead title="Vietnamese template name" />
                </MDBox>
                <MDBox component="th">
                  <EnhancedTableHead title="Created by" />
                </MDBox>
                <MDBox component="th">
                  <EnhancedTableHead title="Created on" />
                </MDBox>
                <MDBox component="th">
                  <EnhancedTableHead title="Last modified" />
                </MDBox>
                <MDBox component="th">
                  <EnhancedTableHead title="Type" />
                </MDBox>
                <MDBox component="th">
                  <EnhancedTableHead title="ACTION" />
                </MDBox>
              </TableRow>
            </MDBox>

            <TableBody>
              {data.map((item, index) => (
                <TableRow
                  key={index}
                  sx={({ breakpoints, typography, functions: { pxToRem } }) => ({
                    height: pxToRem(50),
                    textAlign: "left",
                    fontFamily: typography.lexendFont,
                    fontWeight: 700,
                    fontSize: 12,
                    lineHeight: 1.5,
                    color: "#344767",
                    borderBottom: "1px solid #DEE2E6",
                    cursor: "pointer",

                    [breakpoints.down(1367)]: {
                      height: pxToRem(40),
                    },
                  })}
                  onClick={() => {
                    navigation(
                      `/notification-management/send-notification?id=${item.notificationContentId}`
                    );
                  }}
                >
                  <MDBox component="td">
                    <MDTypography
                      variant="span"
                      sx={(theme) => ({
                        ...tableTextStyle(theme),
                        fontWeight: 600,
                        color: "#344767",
                      })}
                    >
                      {item.titleEn}
                    </MDTypography>
                  </MDBox>

                  <MDBox component="td">
                    {item.titleVi ? (
                      <MDTypography
                        variant="span"
                        sx={(theme) => ({
                          ...tableTextStyle(theme),
                          fontWeight: 600,
                          color: "#344767",
                        })}
                      >
                        {item.titleVi}
                      </MDTypography>
                    ) : (
                      <MDTypography variant="span" sx={(theme) => tableTextEmptyStyle(theme)}>
                        Empty
                      </MDTypography>
                    )}
                  </MDBox>

                  <MDBox component="td">
                    <MDTypography variant="span" sx={(theme) => tableTextStyle(theme)}>
                      {item.createBy}
                    </MDTypography>
                  </MDBox>
                  <MDBox component="td">
                    <MDTypography variant="span" sx={(theme) => tableTextStyle(theme)}>
                      {moment(item.createdOn).format("DD-MM-YYYY HH:mm")}
                    </MDTypography>
                  </MDBox>
                  <MDBox component="td">
                    <MDTypography variant="span" sx={(theme) => tableTextStyle(theme)}>
                      {moment(item.updated).format("DD-MM-YYYY HH:mm")}
                    </MDTypography>
                  </MDBox>
                  <MDBox component="td">
                    {item.notificationType ? (
                      <MDBox sx={(theme) => statusStyle(theme)}>{item.notificationType}</MDBox>
                    ) : null}
                  </MDBox>
                  <MDBox
                    component="td"
                    sx={({ breakpoints }) => ({
                      display: "flex",
                      height: "100%",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontSize: 16,
                      [breakpoints.down(1367)]: { fontSize: 14 },
                    })}
                  >
                    <EditNote
                      fontSize="inherit"
                      sx={({ breakpoints }) => ({
                        color: "#8392AB",
                        margin: 2,
                        cursor: "pointer",
                        [breakpoints.down(1367)]: { margin: 1 },
                      })}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpen(true);
                        setTemplate(item);
                      }}
                    />
                    <RemoveRedEye
                      fontSize="inherit"
                      sx={({ breakpoints }) => ({
                        color: "#8392AB",
                        margin: 2,
                        [breakpoints.down(1600)]: { margin: 1 },
                      })}
                    />
                    <Delete
                      fontSize="inherit"
                      sx={({ breakpoints }) => ({
                        color: "#8392AB",
                        margin: 2,
                        fontSize: "10px",
                        cursor: "pointer",
                        [breakpoints.down(1600)]: { margin: 1 },
                      })}
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeletedTemplateId(item.notificationContentId);
                        setOpenDeleteModal(true);
                      }}
                    />
                  </MDBox>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </MDBox>
        <MDBox
          sx={({ breakpoints }) => ({
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "16px 0px",

            [breakpoints.down(1367)]: {
              padding: "12px 0px",
            },
          })}
        >
          <MDBox
            sx={({}) => ({
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "16px 0px",
              gap: 1,
            })}
          >
            <MDBox
              sx={(theme) =>
                pagingItemStyle(theme, {
                  active: false,
                  isArrow: true,
                  disable: page === 0,
                })
              }
              onClick={() => {
                if (!(page === 0)) {
                  setPage((page) => page - 1);
                }
              }}
            >
              <ChevronLeft sx={() => ({ color: "#8392AB" })} />
            </MDBox>

            {filteredPages.map((item, index) => (
              <MDBox
                key={index}
                sx={(theme) =>
                  pagingItemStyle(theme, { active: item === page + 1, isArrow: false })
                }
                onClick={() => setPage(item - 1)}
              >
                {item}
              </MDBox>
            ))}
            <MDBox
              sx={(theme) =>
                pagingItemStyle(theme, {
                  active: false,
                  isArrow: true,
                  disable: page === totalPage - 1 || page === totalPage,
                })
              }
              onClick={() => {
                if (!(page === totalPage - 1 || page === totalPage)) {
                  setPage((page) => page + 1);
                }
              }}
            >
              <ChevronRight sx={() => ({ color: "#8392AB" })} />
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>

      <CreateTemplate
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        setOpenSuccessSB={setOpenSuccessSB}
        template={template}
        refetch={fetchTemplates}
      />
      {renderSuccessSB}
      <DeleteTemplate
        open={openDeleteModal}
        handleOpen={() => setOpenDeleteModal(true)}
        handleClose={() => setOpenDeleteModal(false)}
        setOpenSuccessSB={setOpenSuccessSBDelete}
        templateId={deletedTemplateId}
        refetch={fetchTemplates}
      />
      {renderSuccessSBDelete}
    </MDBox>
  );
}

export { TemplateManagement };
