import PropTypes from "prop-types";

export const MarkIcon = ({ width = 23, height = 23, fill = "#FFFFFF" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_3048)">
        <path
          d="M7.35045 19.2573C7.35045 20.378 6.25725 20.6166 5.94047 20.6626C5.19977 20.6419 4.60373 20.021 4.60373 19.2573V1.87876H12.8439V0H3.68816C3.18276 0 2.77258 0.420842 2.77258 0.939379V19.2573C2.77258 21.0703 4.21003 22.5451 5.97709 22.5451H19.7107C21.4777 22.5451 22.9152 21.0703 22.9152 19.2573V17.8482H7.35045V19.2573Z"
          fill="white"
        />
        <path
          d="M18.3373 11.2726C16.9758 11.2726 15.7142 10.8311 14.675 10.0852V15.9695L18.3373 13.1513L21.9996 15.9695V10.0852C20.9604 10.8311 19.6987 11.2726 18.3373 11.2726Z"
          fill="white"
        />
        <path
          d="M18.3373 9.39379C20.8655 9.39379 22.9151 7.29092 22.9151 4.6969C22.9151 2.10287 20.8655 0 18.3373 0C15.809 0 13.7594 2.10287 13.7594 4.6969C13.7594 7.29092 15.809 9.39379 18.3373 9.39379Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_3048">
          <rect width="21.9737" height="22.5451" fill="white" transform="translate(0.941406)" />
        </clipPath>
      </defs>
    </svg>
  );
};

MarkIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};
