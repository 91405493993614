export default () => {
  return (
    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.7498 4.44775L10.1998 2.44775L5.9498 0.547754C5.7998 0.497754 5.6498 0.497754 5.5498 0.547754L1.2998 2.44775L5.7498 4.44775Z"
        fill="#172B4D"
      />
      <path
        d="M6.25 5.29785V11.2979L10.45 9.44785C10.65 9.34785 10.75 9.19785 10.75 8.99785V3.29785L6.25 5.29785Z"
        fill="#172B4D"
      />
      <path
        d="M5.25 5.29785L0.75 3.29785V8.99785C0.75 9.19785 0.85 9.39785 1.05 9.44785L5.25 11.2979V5.29785Z"
        fill="#172B4D"
      />
    </svg>
  );
};
