import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDPagination from "components/MDPagination";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { Loading } from "shared/components/ui/Loading";
import { WarningAmber } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import Stack from "@mui/material/Stack";
import { MenuItem, Select } from "@mui/material";
import { descriptionTypography } from "shared/styles/style";
import NotFound from "assets/images/apollo-english/not-found.png";

function DataTable({
  entriesPerPage,
  studentPageSize,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  setCurrentPage,
  totalPage,
  handlePrev,
  handleNext,
  currentPage,
  isLoading,
  isEmpty,
  setStudentPageSize,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 20;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const customStyle = (disable) => ({
    minWidth: "150px",
    ...(disable && { backgroundColor: "#FAFAFA!important" }),
    borderRadius: "0.375rem",
    "& *": {
      fontFamily: "'Lexend', san-serif !important",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "4px",
      paddingBottom: "4px",
    },
    "& input, & input::placeholder": {
      fontSize: "12px !important",
      lineHeight: "1.5!important",
      fontWeight: 400,
    },

    "& input": {
      paddingLeft: `14px !important`,
    },
  });

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(studentPageSize), [studentPageSize]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  const renderPagination = Array.from({ length: totalPage }, (_, i) => i + 1).map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => setCurrentPage(option - 1)}
      active={currentPage === option - 1}
    >
      {option}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const navigate = useNavigate();

  return (
    <>
      <TableContainer
        sx={{
          boxShadow: "none",
          "&::-webkit-scrollbar": {
            width: 6,
            height: 6,
          },

          "&::-webkit-scrollbar-thumb": {
            background: "#8898aa",
            borderRadius: 3,
          },
        }}
      >
        {isLoading ? (
          <MDBox
            sx={({ breakpoints }) => ({
              width: "100%",
              height: 715,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              [breakpoints.down(1600)]: {
                height: 550,
              },
            })}
          >
            <Loading style={{ width: 300, height: 200 }} />
          </MDBox>
        ) : isEmpty ? (
          <MDBox
            sx={({ breakpoints }) => ({
              width: "100%",
              height: 715,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 48,
              objectFit: "contain",

              [breakpoints.down(1600)]: {
                height: 550,
              },
            })}
            component="img"
            src={NotFound}
          >
            {/* <WarningAmber fontSize="inherit" style={{ color: "#9999" }} />
            <MDTypography sx={{ fontSize: 16, color: "#999999", mt: 2 }}>
              No data found
            </MDTypography> */}
          </MDBox>
        ) : (
          <Table {...getTableProps()}>
            <MDBox component="thead">
              {headerGroups.map((headerGroup, key) => (
                <TableRow
                  key={key}
                  {...headerGroup.getHeaderGroupProps()}
                  sx={{ "& .MuiBox-root": { color: "#000000!important", fontSize: 14 } }}
                >
                  {headerGroup.headers.map((column, idx) => (
                    <DataTableHeadCell
                      key={idx}
                      {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                      width={column.width ? column.width : "auto"}
                      align={column.align ? column.align : "left"}
                      sorted={setSortedValue(column)}
                    >
                      {column.render("Header")}
                    </DataTableHeadCell>
                  ))}
                </TableRow>
              ))}
            </MDBox>
            <TableBody
              {...getTableBodyProps()}
              sx={{ height: 500, "& *": { color: "#000000!important" } }}
            >
              {page.map((row, key) => {
                prepareRow(row);
                return (
                  <TableRow
                    key={key}
                    {...row.getRowProps()}
                    sx={{
                      height: 50,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(`/student-detail/${row.original.id}`);
                    }}
                  >
                    {row.cells.map((cell, idx) => (
                      <DataTableBodyCell
                        key={idx}
                        noBorder={noEndBorder && rows.length - 1 === key}
                        align={cell.column.align ? cell.column.align : "left"}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </DataTableBodyCell>
                    ))}
                  </TableRow>
                );
              })}
              <MDBox sx={{ height: 1, width: 1 }}></MDBox>
            </TableBody>
          </Table>
        )}

        {/* <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="flex-end"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        <MDPagination
          variant={pagination.variant ? pagination.variant : "gradient"}
          color={pagination.color ? pagination.color : "info"}
        >
          {currentPage !== 0 && (
            <MDPagination
              item
              onClick={() => {
                if (!(currentPage === 0)) {
                  handlePrev();
                }
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
            </MDPagination>
          )}
          {renderPagination}
          {currentPage !== totalPage - 1 && totalPage !== 0 && (
            <MDPagination
              item
              onClick={() => {
                if (currentPage !== totalPage - 1 && totalPage !== 0) {
                  handleNext();
                }
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
            </MDPagination>
          )}
        </MDPagination>
      </MDBox> */}
      </TableContainer>
      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        <MDBox display="flex" alignItems="center">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={studentPageSize}
            onChange={(e) => {
              setStudentPageSize(e.target.value);
            }}
            sx={{
              ...customStyle,
              width: "100px",
              mr: 2,
              "& input.MuiSelect-select": {
                padding: "10px !important",
              },
              "& .MuiInputBase-input": {
                paddingLeft: `14px !important`,
                padding: "10px !important",
                // minHeight: "40.25px !important",
              },
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
          <MDTypography
            sx={({ breakpoints }) => ({
              ...descriptionTypography(breakpoints),
            })}
          >
            Students per page
          </MDTypography>
        </MDBox>
        <Stack spacing={2}>
          <Pagination count={10} color="primary" sx={{ display: "none" }} />
          {/* cần hide, đừng xoá, lỗi của material */}
          <Pagination
            count={totalPage}
            onChange={(e, page) => {
              setCurrentPage(page - 1);
            }}
            page={currentPage + 1}
            renderItem={(item) => (
              <PaginationItem
                slots={{
                  previous: ArrowBackIcon,
                  next: ArrowForwardIcon,
                }}
                {...item}
              />
            )}
            sx={{
              padding: "0 !important",

              "& .Mui-selected": {
                background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                color: "white !important",

                "& .MuiTouchRipple-root": {
                  color: "white",
                },
              },

              "&:hover, &:focus, &:active": {
                transform: "none",
                opacity: "1 !important",
              },
            }}
          />
        </Stack>
      </MDBox>
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 20, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  studentPageSize: PropTypes.number,
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  currentPage: PropTypes.number,
  handlePrev: PropTypes.func,
  handleNext: PropTypes.func,
  totalPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  isLoading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  setStudentPageSize: PropTypes.func,
};

export default DataTable;
