import { noteTypography } from "shared/styles/style";
import { descriptionTypography } from "shared/styles/style";
import { titleTypography } from "shared/styles/style";

export const containerStyle = (theme) => {
  const {
    breakpoints,
    functions: { pxToRem },
  } = theme;

  return {
    minHeight: "100vh",
    position: "relative",
    top: pxToRem(-20),
    margin: "0 auto",

    [breakpoints.up("xxl")]: {
      width: `calc(100% - 150px)`,
    },

    [breakpoints.up("xl")]: {
      width: `calc(100% - 120px)`,
    },

    [breakpoints.up("lg")]: {
      width: `calc(100% - 100px)`,
    },

    [breakpoints.up("md")]: {
      width: `calc(100% - 80px)`,
    },

    [breakpoints.down("md")]: {
      width: `calc(100% - 40px)`,
    },
  };
};

export const firstRowStyle = (theme) => {
  const {
    breakpoints,
    functions: { pxToRem },
  } = theme;

  return {
    [breakpoints.down("xxxl")]: {
      rowGap: 3,
      justifyContent: "flex-start",
    },

    [breakpoints.down("xxl")]: {
      rowGap: 2.5,
    },

    [breakpoints.down("md")]: {
      rowGap: 2,
    },
  };
};

export const itemStyle = (theme, ownerState) => {
  const {
    palette,
    breakpoints,
    functions: { pxToRem },
  } = theme;

  const { type, active } = ownerState;

  const completionRate = {
    background: "white !important",
  };

  const courseBoardPhase = {
    background: "#FFFDD8!important",
  };

  return {
    borderRadius: pxToRem(20),
    padding: pxToRem(24),
    boxShadow: "0px 2px 6px 0px #00000040",
    background: active ? "#C8D0FF" : palette.white.main,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    ...(type === "completion-rate" ? completionRate : {}),
    ...(type === "course-board-phase" ? courseBoardPhase : {}),

    [breakpoints.down(1600)]: {
      padding: pxToRem(16),
    },

    [breakpoints.down(1367)]: {
      borderRadius: pxToRem(12),
    },
  };
};

export const iconStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  width: pxToRem(32),
  height: pxToRem(32),

  [breakpoints.down(1367)]: {
    width: pxToRem(24),
    height: pxToRem(24),
  },
});

export const itemTitleStyle = ({ functions: { pxToRem }, breakpoints, typography }) => ({
  ...titleTypography(breakpoints),
  lineHeight: 1.37,
});

export const percentStyle = ({ breakpoints, typography }) => ({
  fontSize: 30,
  fontFamily: typography.lexendFont,
  fontWeight: 700,
  background: "linear-gradient(339deg, #1576BC 31.89%, #007C95 62.81%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  borderBottom: "2px solid #F75C3A",

  [breakpoints.down(1600)]: {
    fontSize: 20,
  },
});

export const trendingStyle = ({ breakpoints, typography }) => ({
  fontSize: 14,
  fontFamily: typography.lexendFont,
  fontWeight: 400,
  color: "#2DCE89",

  [breakpoints.down(1600)]: {
    fontSize: 11,
  },
});

export const descriptionStyle = ({ breakpoints, typography }) => ({
  ...descriptionTypography(breakpoints),

  [breakpoints.down(1600)]: {
    fontSize: 12,
  },
});

export const buttonStyle = ({ palette, functions: { pxToRem } }, { active }) => ({
  height: pxToRem(40),
  boxShadow: "0px 2px 6px 0px #00000040",
  background: active ? "#67748E" : palette.white.main,
  borderRadius: pxToRem(8),
  padding: `${0} ${pxToRem(16)}`,
  cursor: "pointer",
});

export const buttonTextStyle = ({ breakpoints, palette, typography }, { active }) => ({
  fontSize: 16,
  fontFamily: typography.lexendFont,
  color: active ? palette.white.main : palette.textColors.primary,
  fontWeight: 400,

  [breakpoints.down(1600)]: {
    fontSize: 12,
  },
});

export const buttonBadgeStyle = ({ palette, functions: { pxToRem } }, { active }) => ({
  background: active ? "#F56565" : "#1576BC",
  border: `${pxToRem(2)} solid ${palette.white.main}`,
  width: pxToRem(18),
  height: pxToRem(18),
  borderRadius: pxToRem(9999),

  color: palette.white.main,
  fontSize: 12,
  fontWeight: 400,
});

export const containerBoxStyle = ({ palette, functions: { pxToRem } }, { isScrolling }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: pxToRem(16),
  paddingBottom: pxToRem(24),
  paddingTop: pxToRem(20),
  alignItems: "stretch",
  width: "100%",

  "&::-webkit-scrollbar": {
    height: isScrolling ? "6px" : "0px",
  },

  "&::-webkit-scrollbar-thumb": {
    background: "#999999",
    borderRadius: "3px",
  },
});

export const boxItem = ({ breakpoints }) => ({
  width: "calc(100% / 3 - 20px)",
  height: "100%",

  [breakpoints.down(1600)]: {
    width: "calc(100% / 2 - 20px)",
  },

  [breakpoints.down(700)]: {
    width: "100%",
  },
});

export const titleStyle = ({ breakpoints }) => ({
  ...titleTypography(breakpoints),
});

export const descStyle = ({ breakpoints }) => ({
  ...noteTypography(breakpoints),
});
