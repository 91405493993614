import { api } from "shared/redux/api";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    test: build.query({
      query: () => ({
        url: "https://dummyjson.com/products/1",
      }),
    }),
    login: build.mutation({
      query: (arg) => ({
        url: "/public/login/v1",
        method: "POST",
        body: arg,
      }),
    }),
    loginV2: build.mutation({
      query: (arg) => ({
        url: "/public/login/v2",
        method: "POST",
        headers: {
          Authorization: `Basic ${arg?.token}`,
        },
      }),
    }),
    changePassword: build.mutation({
      query: (arg) => ({
        url: "/private/user/v1/changePassword",
        method: "PUT",
        body: arg,
      }),
    }),
    changePasswordV2: build.mutation({
      query: (arg) => ({
        url: "/private/user/v2/changePassword",
        method: "PUT",
        body: arg,
      }),
    }),
    refreshToken: build.mutation({
      query: (arg) => ({
        url: "/public/login/v1/refreshAccessToken",
        method: "POST",
        body: arg,
      }),
    }),
    user: build.query({
      query: () => ({
        url: "/private/user/v1/profile",
      }),
    }),
    changeAvatar: build.mutation({
      query: (arg) => ({
        url: "/private/user/v1/avatar",
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data;",
        },
        body: arg,
        formData: true,
      }),
    }),
    sendMailResetPassword: build.mutation({
      query: (arg) => {
        return {
          url: `/public/login/v1/sendMailResetPassword`,
          method: "POST",
          body: arg,
        };
      },
    }),
    resetPassword: build.mutation({
      query: (arg) => {
        return {
          url: `/public/login/v1/resetPassword`,
          method: "PUT",
          body: arg,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useLoginV2Mutation,
  useChangePasswordMutation,
  useChangePasswordV2Mutation,
  useRefreshTokenMutation,
  useUserQuery,
  useLazyUserQuery,
  useTestQuery,
  useChangeAvatarMutation,
  useSendMailResetPasswordMutation,
  useResetPasswordMutation,
} = injectedRtkApi;
