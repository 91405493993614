import { descriptionTypography } from "shared/styles/style";
import { noteTypography } from "shared/styles/style";
import { titleTypography } from "shared/styles/style";

export const calendarContainer = ({ breakpoints, functions: { pxToRem } }) => ({
  width: "100%",
  height: "100%",
  boxShadow: ["0px 4px 4px 0px #00000040", "0px 2px 6px 0px #00000040"],
  borderRadius: pxToRem(20),
  padding: `${pxToRem(24)} ${pxToRem(16)} 0`,
  background: "#fff",
  position: "relative",
  minWidth: "100%",

  [breakpoints.down(1600)]: {
    maxWidth: pxToRem(800),
  },
});

export const calendarLoading = () => ({
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  background: "rgba(0, 0, 0, 0.15)",
  position: "absolute",
  zIndex: 99,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const calendarHeader = ({ breakpoints, functions: { pxToRem } }) => ({
  // marginLeft: pxToRem(52),
  // marginRight: pxToRem(17),
  //height: pxToRem(40),
  marginBottom: `${pxToRem(8)} !important`,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  [breakpoints.down(1600)]: {
    //height: pxToRem(32),
    marginLeft: 0,
  },

  [breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: pxToRem(16),
    margin: 0,
  },
});

export const calendarTitle = ({ breakpoints }) => ({
  ...titleTypography(breakpoints),
  lineHeight: 1.5,
  letterSpacing: 0,
  textAlign: "left",

  [breakpoints.down("md")]: {
    fontSize: 12,
  },
});

export const calendarTabMenuItem = ({ breakpoints, functions: { pxToRem } }, { active }) => ({
  flexGrow: 1,
  flexBasis: 0,
  borderRadius: pxToRem(8),
  height: pxToRem(35),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",

  ...(active ? { background: "#ffffff", boxShadow: "0px 2px 6px 0px #00000040" } : {}),

  [breakpoints.down(1600)]: {
    height: pxToRem(30),
  },
});

export const calendarTabMenuItemTitle = ({ breakpoints }) => ({
  ...noteTypography(breakpoints),
  lineHeight: 1.25,
  textAlign: "center",
});

export const calendarUTCSelect = ({ typography, breakpoints, functions: { pxToRem } }) => ({
  position: "relative",
  display: "flex",
  width: pxToRem(140),
  height: pxToRem(40),
  borderRadius: "8px",
  overflow: "hidden",
  border: "1px solid #1576bc",

  "& select": {
    appearance: "none",
    border: "0",
    boxShadow: "none",
    outline: "none",
    flex: 1,
    padding: "0 1em",
    color: "#525f7f",
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 16px center",
    fontFamily: typography.lexendFont,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: pxToRem(24),
    textAlign: "left",
    cursor: "pointer",
  },

  "& select::-ms-expand": {
    display: "none",
  },

  [breakpoints.down(1600)]: {
    width: pxToRem(120),
    height: pxToRem(34),

    "& select": {
      backgroundPosition: "right 8px center",
      fontSize: 12,
    },
  },
});

export const calendarTabMenuContainer = ({ breakpoints, functions: { pxToRem } }) => ({
  maxWidth: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: pxToRem(32),
  [breakpoints.down("lg")]: {
    flexDirection: "column",
    gap: pxToRem(12),
    alignItems: "flex-end",
  },
});

export const calendarTabMenu = ({ breakpoints, functions: { pxToRem } }) => ({
  maxWidth: "100%",
  borderRadius: "12px",
  padding: "4px",
  background: "#f6f9fc",
  display: "flex",
  width: pxToRem(400),
  flexGrow: 1,
  margin: `${pxToRem(0)} ${pxToRem(32)}`,
  // [breakpoints.down(1600)]: {
  //   width: pxToRem(300),
  // },

  [breakpoints.down(992)]: {
    width: "100%",
    margin: 0,
  },
});

export const calendarDayHeader = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
};

export const calendarDayTopHeader = ({ breakpoints }, { isWeekends, isToday }) => ({
  ...descriptionTypography(breakpoints),
  lineHeight: 1.5,
  letterSpacing: "0em",
  textAlign: "center",
  color: isWeekends ? "#fd7e14 !important" : isToday ? "#32325d !important" : "#adb5bd !important",
});

export const calendarDayBottomHeader = ({ breakpoints }, { isWeekends, isToday }) => ({
  ...descriptionTypography(breakpoints),
  lineHeight: 1.5,
  letterSpacing: "0em",
  textAlign: "center",
  fontWeight: isToday ? 700 : 400,
  color: isWeekends ? "#ffb647 !important" : isToday ? "#212529" : "#8898aa",
});

export const calendarSlotLabel = ({ breakpoints }) => ({
  ...descriptionTypography(breakpoints),
  lineHeight: 1.5,
  letterSpacing: "0em",
  textAlign: "center",
  color: "#212529",
});
