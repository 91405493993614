import { combineReducers } from "@reduxjs/toolkit";
import { api } from "./api";

import authReducer from "./slices/authSlice";
import themeReducer from "./slices/themeSlice";
import notificationReducer from "./slices/notificationSlice";
import uploadReducer from "./slices/uploadSlice";
import loadingSlice from "./slices/loadingSlice";
import bannerSlice from "./slices/bannerSlice";

export const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,

  auth: authReducer,
  theme: themeReducer,
  notification: notificationReducer,
  upload: uploadReducer,
  loading: loadingSlice,
  banner: bannerSlice,
});
