import React from "react";
import MDBox from "components/MDBox";
import { teacherDetailStyle, titleStyle } from "./style";
import MDTypography from "components/MDTypography";

function Detail() {
  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="flex-start"
      columnSpacing={3}
      sx={(theme) => teacherDetailStyle(theme)}
    >
      <MDBox
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        sx={({ breakpoints }) => ({
          rowGap: 2,
          [breakpoints.down("lg")]: {
            flexDirection: "column",
            alignItems: "flex-start",
          },
        })}
      >
        <MDTypography sx={(theme) => titleStyle(theme)}>Teacher detail page</MDTypography>
      </MDBox>
    </MDBox>
  );
}

export { Detail };
