import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import breakpoints from "assets/theme/base/breakpoints";
import { itemStyle } from "../styles/MenuTabs";
import { noteTypography } from "shared/styles/style";

export const tabs = [
  "Personal information",
  "Labour information",
  "Leave system",
  "Apollo support",
  "Disciplinary records",
];

// eslint-disable-next-line
export const MenuTabs = ({ tabIndex, setTabIndex }) => {
  return (
    <Grid
      container
      alignItems="center"
      sx={({ functions: { pxToRem } }) => ({
        borderRadius: pxToRem(12),
        padding: pxToRem(4),
        background: "#F6F9FC",
        width: "fit-content",
      })}
    >
      {tabs.map((item, index) => {
        return (
          <Grid
            item
            xs="auto"
            key={index}
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={({ breakpoints, functions: { pxToRem } }) => ({
              height: pxToRem(35),
              borderRadius: pxToRem(8),
              background: tabIndex === index ? "white" : "inherite",
              boxShadow: tabIndex === index ? "0px 2px 6px 0px #00000040" : "unset",
              cursor: "pointer",
              padding: `${pxToRem(0)} ${pxToRem(24)}`,

              [breakpoints.down(768)]: {
                width: "100%",
              },
            })}
            onClick={() => setTabIndex(index)}
          >
            <MDTypography
              sx={({ breakpoints }) => ({
                ...noteTypography(breakpoints),
                lineHeight: 1.25,
              })}
            >
              {item}
            </MDTypography>
          </Grid>
        );
      })}
    </Grid>
  );
};
