import { api } from "shared/redux/api";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPerformance: build.query({
      query: () => ({
        url: "/private/teacher/v2/performance",
      }),
    }),
    getRenewalRate: build.query({
      query: () => ({
        url: "/private/teacher/v1/revenueRate",
      }),
    }),
    getCourseProgress: build.query({
      query: () => ({
        url: "/private/teacher/v1/courseProgress",
      }),
    }),
    getStudentAtRisk: build.query({
      query: (arg) => {
        const {
          page,
          size,
          sort,
          start,
          end,
          classCode,
          durations,
          engagement,
          homeLearning,
          performance,
        } = arg;
        return {
          url: `/private/student/v2/studentAtRisk?page=${page}&size=${size}&sort=${sort}&start=${start}&end=${end}&classCode=${classCode}&durations=${durations}&engagement=${engagement}&homeLearning=${homeLearning}&performance=${performance}`,
        };
      },
    }),
    getStudentAtRiskClassSuggession: build.query({
      query: (arg) => {
        const { start, end } = arg;
        return {
          url: `/private/student/v1/studentAtRisk/block/suggestClass?end=${end}&start=${start}`,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPerformanceQuery,
  useLazyGetPerformanceQuery,
  useGetRenewalRateQuery,
  useGetCourseProgressQuery,
  useLazyGetStudentAtRiskQuery,
  useLazyGetStudentAtRiskClassSuggessionQuery,
} = injectedRtkApi;
