export const boxListStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  display: "flex",
  gap: pxToRem(16),
  backgroundColor: "#F7FAFC",
  borderRadius: pxToRem(15),
  flexDirection: "column",

  [breakpoints.down(768)]: {
    borderRadius: pxToRem(0),
  },
  // height: "100%",
});

export const tableStyle = ({ breakpoints, typography, functions: { pxToRem } }) => ({
  padding: pxToRem(24),
  display: "flex",
  flexDirection: "column",
  gap: pxToRem(40),
  // height: "500px",
  flexGrow: 1,
  p: 3,
  pt: 0,

  [breakpoints.down(768)]: {
    p: 0,
  },
});

export const boxListStyleItem = ({ breakpoints, functions: { pxToRem } }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  paddingLeft: pxToRem(10),
});

export const titleBoxStyle = ({ typography, functions: { pxToRem } }) => ({
  fontSize: pxToRem(12),
  fontWeight: 700,
  minWidth: pxToRem(100),
  color: "#8392AB",
  paddingBottom: pxToRem(15),
  borderBottom: "1px solid #E9E9E9",
  width: "100%",
});

export const descBoxStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  fontSize: pxToRem(12),
  fontWeight: 700,
  color: "#8392AB",
  minHeight: pxToRem(40),
  display: "flex",
  alignItems: "center",
  marginTop: pxToRem(5),

  [breakpoints.down(1600)]: {
    fontSize: pxToRem(10),
  },
});

export const dotCricle = ({ typography, functions: { pxToRem } }) => ({
  width: pxToRem(25),
  height: pxToRem(25),
  backgroundColor: "#F25586",
  borderRadius: "50%",
});

export const titleGroupStyle = (
  { breakpoints, typography, functions: { pxToRem } },
  { ecclips }
) => ({
  fontSize: pxToRem(14),
  fontWeight: 400,
  lineHeight: 1,
  minWidth: pxToRem(200),
  marginTop: pxToRem(10),
  overflow: "hidden",
  whiteSpace: "nowrap",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
  textOverflow: "ellipsis",
  width: "85%",

  [breakpoints.down(1600)]: {
    fontSize: pxToRem(12),
  },
});

export const showLessStyle = ({ typography, functions: { pxToRem } }) => ({
  fontSize: pxToRem(12),
  fontWeight: 400,
  lineHeight: 1,
  color: "#8392AB",
  marginTop: pxToRem(10),
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  gap: pxToRem(5),
});

export const groupAllList = ({ breakpoints, functions: { pxToRem } }, { open, active }) => ({
  display: "flex",
  overflow: "hidden",
  flexDirection: "column",
  gap: pxToRem(16),
  backgroundColor: "#F7FAFC",
  borderRadius: pxToRem(8),
  height: open ? pxToRem(250) : active ? pxToRem(170) : pxToRem(0),
  overflow: "scroll",
  maxWidth: pxToRem(750),

  "&::-webkit-scrollbar": {
    display: "none",
  },

  transition: "height 0.2s ease",

  [breakpoints.down("md")]: {
    maxWidth: "300px",
  },
});

export const buttonStyle = ({ breakpoints, typography, functions: { pxToRem } }) => ({
  background: "linear-gradient(122deg, #11CDEF 9.06%, #1171EF 88.15%)",
  color: "#fff!important",
  borderRadius: pxToRem(5),
  fontSize: pxToRem(11),
  lineHeight: 1,
  fontWeight: 700,
  paddingLeft: pxToRem(8),
  paddingRight: pxToRem(8),
  paddingTop: pxToRem(4),
  paddingBottom: pxToRem(4),
  minHeight: pxToRem(20),
  height: pxToRem(20),
  textTransform: "uppercase",
});
