import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useRef } from "react";
import "./custom.css";
import Slider from "react-slick";
import dot from "./dot.json";

import {
  backgroundStyle,
  containerStyle,
  redDotStyle,
  streamNameStyle,
  streamStyle,
} from "./style";
import makeStyles from "pages/style";
import Lottie from "react-lottie-player";
import { useSelector } from "react-redux";
import { selectBanner } from "shared/redux/slices/bannerSlice";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import InfoIcon, { TooltipIconColor, TooltipType } from "components/InfoIcon";
import { teacherInfoIconBlock } from "components/InfoIcon/teacherInfoIcon";

function Advertise() {
  const sliderRef = useRef(null);

  const banner = useSelector(selectBanner);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const classes = makeStyles();

  return (
    <MDBox width="100%" height="100%" sx={(theme) => containerStyle(theme)}>
      <Slider ref={sliderRef} {...settings}>
        {(banner.announcements.length === 0
          ? [
              {
                imgUrl:
                  "https://static.spacecrafted.com/a7396ee5481b4f909a919d4d9b12438c/i/fb8d760141e741eab1385d7d1e49585f/1/4SoifmQp45JMgBnHp7ed2/1200-x-720%20Placeholder%20Image.jpg",
                linkUrl: "",
              },
            ]
          : banner.announcements
        ).map((item, index) => {
          return (
            <MDBox
              key={index}
              width="100%"
              height="100%"
              sx={{ cursor: "pointer " }}
              onClick={() => {
                if (item.linkUrl) {
                  window.open(item.linkUrl, "_blank");
                }
              }}
            >
              <MDBox sx={(theme) => backgroundStyle(theme, { bgImage: item.imgUrl })}>
                <MDBox sx={(theme) => streamStyle(theme)}>
                  <MDBox className={classes.containerDisplayFlex}>
                    <MDTypography sx={(theme) => streamNameStyle(theme)}>
                      APOLLO ON AIR
                    </MDTypography>
                    <InfoIcon
                      teacherInfoIconBlock={teacherInfoIconBlock.APOLLO_ON_AIR}
                      type={TooltipType.TYPE_2}
                      color={TooltipIconColor.BLUE}
                      className={classes.containerInfoIconWith2}
                    />
                  </MDBox>
                  <MDBox sx={(theme) => redDotStyle(theme)}>
                    <Lottie animationData={dot} play />
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          );
        })}
      </Slider>

      {/* <MDBox height="100%">
        <iframe
          height="125%"
          width={"100%"}
          src="https://demo-lesson.sgp1.digitaloceanspaces.com/2023-11-1-ApolloShootingDay2-Script9-final2.mp4"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </MDBox> */}

      <MDBox
        sx={({ functions: { pxToRem } }) => ({
          position: "absolute",
          top: pxToRem(48),
          right: pxToRem(48),
          zIndex: 99,
          display: "flex",
          gap: 2,
        })}
      >
        <MDBox
          sx={{
            cursor: "pointer",
            background: "#fff",
            width: 40,
            height: 40,
            borderRadius: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => sliderRef?.current?.slickPrev()}
        >
          <ChevronLeft sx={{ color: "#000", height: 28, width: 28 }} />
        </MDBox>
        <MDBox
          sx={{
            cursor: "pointer",
            background: "#fff",
            width: 40,
            height: 40,
            borderRadius: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => sliderRef?.current?.slickNext()}
        >
          <ChevronRight sx={{ color: "#000", height: 28, width: 28 }} />
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default Advertise;
