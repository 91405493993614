import PropTypes from "prop-types";

export const ArrowUpIcon = ({ width = 34, height = 23, fill = "#868686" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={17} height={15} viewBox="0 0 17 15" fill="none">
      <path
        d="M12.5664 9.60938L8.0786 5.39063L3.5908 9.60937"
        stroke="#A0AEC0"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ArrowUpIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};
