export const containerStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  top: pxToRem(90),

  [breakpoints.down("xxl")]: {
    top: pxToRem(60),
  },

  [breakpoints.down("xl")]: {
    top: pxToRem(40),
  },
});

export const headingStyle = ({ typography, palette, breakpoints, functions: { pxToRem } }) => ({
  fontFamily: typography.lexendFont,
  color: palette.white.main,
  fontWeight: 700,
  fontSize: 48,
  lineHeight: 1.25,
  textSpacing: pxToRem(-0.8),

  [breakpoints.down(1367)]: {
    fontSize: 36,
  },

  [breakpoints.down("lg")]: {
    fontSize: 24,
  },
});

export const subHeadingStyle = ({ typography, palette, breakpoints, functions: { pxToRem } }) => ({
  fontFamily: typography.lexendFont,
  fontWeight: 400,
  fontSize: 16,
  lineHeight: 1.5,
  textSpacing: pxToRem(-0.8),

  [breakpoints.down(1367)]: {
    fontSize: 12,
  },

  [breakpoints.down("lg")]: {
    fontSize: 10,
  },
});

export const signInTextStyle = ({ palette, typography, breakpoints, functions: { pxToRem } }) => ({
  color: palette.textColors.primary,
  fontFamily: typography.lexendFont,
  fontWeight: 600,
  fontSize: 20,
  lineHeight: 1.37,
  textSpacing: pxToRem(-0.8),
  marginBottom: pxToRem(24),

  [breakpoints.down(1367)]: {
    fontSize: 16,
    marginBottom: pxToRem(16),
  },
});

export const googleButtonStyle = ({ functions: { pxToRem } }) => ({
  borderRadius: pxToRem(8),
  gap: pxToRem(4),
  height: pxToRem(30),
  boxShadow: "0px 2px 6px 0px #00000040",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
});

export const googleTextStyle = ({ typography, breakpoints, functions: { pxToRem } }) => ({
  color: "#505050",
  fontFamily: typography.lexendFont,
  fontSize: 14,
  fontWeight: 700,
  lineHeight: 1.4,
  letterSpacing: pxToRem(0.175),

  [breakpoints.down(1367)]: {
    fontSize: 11,
  },
});

export const orTextStyle = ({ palette, typography, breakpoints, functions: { pxToRem } }) => ({
  color: palette.textColors.secondary,
  fontFamily: typography.lexendFont,
  fontWeight: 400,
  fontSize: 16,
  lineHeight: 1.5,
  marginBottom: pxToRem(24),

  [breakpoints.down(1367)]: {
    fontSize: 11,
    marginBottom: pxToRem(12),
  },
});

export const signInButtonStyle = ({ breakpoints, typography, functions: { pxToRem } }) => ({
  height: pxToRem(40),
  fontSize: 14,
  lineHeight: 1.5,
  fontWeight: 700,
  textTransform: "unset",
  background: "#5E72E4!important",
  fontFamily: typography.lexendFont,

  [breakpoints.down(1367)]: {
    height: pxToRem(36),
    minHeight: pxToRem(36),
    fontSize: 12,
  },
});

export const forgotPasswordButtonStyle = ({ palette, typography, breakpoints }) => ({
  fontFamily: typography.lexendFont,
  fontSize: 14,
  lineHeight: 1.42,
  fontWeight: 400,
  color: palette.textColors.secondary,

  [breakpoints.down(1367)]: {
    fontSize: 12,
  },

  [breakpoints.down("xl")]: {
    fontSize: 10,
  },
});

export const forgotPasswordLinkStyle = ({ typography, breakpoints }) => ({
  fontFamily: typography.lexendFont,
  lineHeight: 1.5,
  fontWeight: 700,
  color: "#5E72E4",

  [breakpoints.down(1367)]: {
    fontSize: 12,
  },

  [breakpoints.down("xl")]: {
    fontSize: 10,
  },
});

export const errorMessageStyle = ({ typography }) => ({
  color: "#f44335",
  fontFamily: typography.lexendFont,
  fontWeight: 400,
  fontSize: 10,
  lineHeight: 1.5,
});
