import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Grid, AppBar, Tabs, Tab } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PropTypes from "prop-types";

import {
  tableContainerStyle,
  teacherManagementStyle,
  tableHeadStyle,
  tableStyleID,
  tableStyleDesc,
  tableStyleDate,
} from "./style";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormNotification from "components/FormNotification";
import TitleBoxComponent from "components/TitleBoxComponent";
import breakpoints from "assets/theme/base/breakpoints";
import StatusCell from "components/StatusCell";

import {
  useLazyGetScheduleNotificationsTodayQuery,
  useLazyGetTemplateDetailNotificationQuery,
} from "shared/redux/endpoints/notifications";
import DataTable from "components/DataTable";
import moment from "moment";

const EnhancedTableHead = ({ title }) => {
  return (
    <MDBox
      sx={({ breakpoints, functions: { pxToRem } }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginRight: pxToRem(24),

        [breakpoints.down(1367)]: {
          marginRight: pxToRem(16),
        },
      })}
    >
      <MDTypography sx={(theme) => tableHeadStyle(theme)}>{title}</MDTypography>
      <MDBox sx={() => ({ display: "flex", alignItems: "center" })}>
        <ExpandLess sx={({ palette }) => ({ color: palette.textColors.secondary })} />
        <ExpandMore sx={({ palette }) => ({ color: palette.textColors.secondary })} />
      </MDBox>
    </MDBox>
  );
};

EnhancedTableHead.propTypes = {
  title: PropTypes.string.isRequired,
};

function NotificationManagement() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [dataTable, setDataTable] = useState({
    columns: [
      { Header: "ID", accessor: "id" },
      { Header: "title", accessor: "title" },
      {
        Header: "time push",
        accessor: "timePush",
        Cell: ({ value }) => {
          const status = value.status;
          const date = value.date;
          let result;

          if (status === "SUCCESS") {
            result = <StatusCell icon="done" color="success" status={date} />;
          } else if (status === "FAIL") {
            result = <StatusCell icon="close" color="error" status={date} />;
          } else if (status === "IN_PROGRESS") {
            result = <StatusCell icon="replay" color="dark" status={date} />;
          } else {
            result = <StatusCell icon="replay" color="dark" status={date} />;
          }

          return result;
        },
      },
      { Header: "number of recipient", accessor: "numberRecipient" },
      { Header: "Push by", accessor: "pushBy" },
      { Header: "action", accessor: "action" },
    ],
    rows: [],
  });
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [getScheduleNotificationsToday] = useLazyGetScheduleNotificationsTodayQuery();
  const [getTemplateDetailNotifcation, { data: dataTemplate }] =
    useLazyGetTemplateDetailNotificationQuery();

  const valueTabs = [
    {
      value: 0,
      label: "Schedule noti",
    },
    {
      value: 1,
      label: "Noti to NTW",
    },
    {
      value: 2,
      label: "Noti to center list",
    },
    {
      value: 3,
      label: "Noti to list teacher",
    },
  ];

  const handleTabsOrientation = () => {
    return window.innerWidth < breakpoints.values.sm
      ? setTabsOrientation("vertical")
      : setTabsOrientation("horizontal");
  };

  useEffect(() => {
    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleGetDataTable = (tabValue) => {
    const type =
      tabValue === 0 || tabValue === 3
        ? "TEACHERS"
        : tabValue === 1
        ? "ALL"
        : "CENTERS" || TEACHERS;
    getScheduleNotificationsToday({
      sort: "created,desc",
      page: 0,
      size: 10,
      target: type,
    }).then((data) => {
      if (data.data.status) {
        const customRows = data.data.data.map((item) => ({
          id: <MDTypography sx={tableStyleID}># {item.id}</MDTypography>,
          numberRecipient: <MDTypography sx={tableStyleID}>{item.sendTo.length}</MDTypography>,
          timePush: {
            date: (
              <MDTypography sx={tableStyleDate}>
                {moment(item.scheduleTime).add(7, "hour").format("YYYY-MM-DD HH:mm:ss")}
              </MDTypography>
            ),
            status: item.status,
          },
          title: <MDTypography sx={tableStyleID}>{item.titleEn}</MDTypography>,
          pushBy: <MDTypography sx={tableStyleDesc}>Admin</MDTypography>,
          action: <MDTypography sx={tableStyleDesc}>View</MDTypography>,
        }));
        setDataTable({
          ...dataTable,
          rows: customRows,
        });
      }
    });
  };

  useEffect(() => {
    handleGetDataTable(tabValue);
    id &&
      getTemplateDetailNotifcation({
        id,
      });
  }, []);

  const handleSetTabValue = (event, newValue) => {
    handleGetDataTable(newValue);
    setTabValue(newValue);
  };

  const propertiesFormNotification = {
    dataTemplate,
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={10} xl={8}>
          <MDBox mb={3}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ padding: 0.5, background: "#fff", gap: "20px", display: "flex" }}
                TabIndicatorProps={{
                  style: {
                    fontWeight: "bold",
                    color: "#fff",
                  },
                }}
              >
                {valueTabs.map((item, index) => {
                  const isActive = tabValue === index;

                  return (
                    <Tab
                      sx={{
                        fontWeight: isActive ? 700 : 400,
                        minWidth: "150px",
                      }}
                      label={item.label}
                      key={item.value}
                      TabIndicatorProps={{
                        style: {
                          minWidth: "350px",
                        },
                      }}
                    />
                  );
                })}
              </Tabs>
            </AppBar>
          </MDBox>
        </Grid>
      </Grid>

      <MDBox sx={(theme) => teacherManagementStyle(theme)}>
        <TitleBoxComponent title="Today's queue" />

        <MDBox width="100%">
          <MDBox data-horizontal-scroll sx={(theme) => tableContainerStyle(theme)}>
            <DataTable
              table={dataTable}
              entriesPerPage={false}
              showTotalEntries={false}
              isSorted={true}
            />
          </MDBox>
        </MDBox>
      </MDBox>

      <MDBox sx={(theme) => teacherManagementStyle(theme)}>
        <TitleBoxComponent title="Add a notification " />
        <FormNotification {...propertiesFormNotification} />
      </MDBox>
    </>
  );
}

export { NotificationManagement };
