import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { containerStyle, headerStyle } from "../styles/ContractItem";
import typography from "assets/theme/base/typography";
import palette from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";
import FormField from "layouts/pages/account/components/FormField";
import { titleTypography } from "shared/styles/style";
import { DateSelect } from "pages/assistant/components/DateSelect/temp3";
import { noteTypography } from "shared/styles/style";
import { ArrowForward } from "@mui/icons-material";
import { descriptionTypography } from "shared/styles/style";
import moment from "moment";
import { useGetLeaveInformationMutation } from "../../../shared/redux/endpoints/employee";
import { useEffect, useState } from "react";
import { selectAuth } from "../../../shared/redux/slices/authSlice";
import { useSelector } from "react-redux";
import { Loading } from "../../../shared/components/ui/Loading";
import NotFound from "assets/images/apollo-english/not-found.png";
import InfoIcon, { TooltipIconColor, TooltipType } from "../../../components/InfoIcon";
import { teacherInfoIconBlock } from "../../../components/InfoIcon/teacherInfoIcon";
import makeStyles from "../../../pages/style";

const customStyle = {
  "& .MuiInputBase-root, & .MuiInputBase-root .MuiInputBase-input": {
    lineHeight: "100%",
  },

  "& .MuiInputBase-root .MuiInputBase-input": {
    padding: `${pxToRem(9.5)} ${pxToRem(14)}`,
  },

  "& input, & input::placeholder": {
    fontSize: "16px !important",
    lineHeight: "1.5!important",
    fontWeight: 400,
    fontFamily: typography.lexendFont,
  },

  "& input": {
    paddingLeft: `${pxToRem(14)} !important`,
  },

  "& label": {
    fontSize: "18px !important",
    fontWeight: 700,
    fontFamily: typography.lexendFont,
    color: palette.textColors.primary,
    top: "-2px",
  },

  "& legend": {
    fontSize: "16px !important",
  },
};

// eslint-disable-next-line
export const LeaveRecords = ({ last }) => {
  const classes = makeStyles();
  const [getLeaveInformation, { isLoading }] = useGetLeaveInformationMutation();
  const startOfYear = moment().startOf("year").format("MM-DD-YYYY");
  const currentDate = moment().endOf().format("MM-DD-YYYY");
  const [start, setStart] = useState(startOfYear);
  const [end, setEnd] = useState(currentDate);
  const [data, setData] = useState([]);

  const auth = useSelector(selectAuth);

  useEffect(() => {
    if (auth.user.empCode) {
      const fetchLabourInformation = async () => {
        const response = await getLeaveInformation({
          empCode: auth.user.empCode,
          start: moment(start).format("DD/MM/YYYY"),
          end: moment(end).format("DD/MM/YYYY"),
        });
        if (response?.data) {
          const result = response.data.data.Result;

          setData(result);
        } else {
          setData([]);
        }
      };

      fetchLabourInformation();
    } else {
      setData([]);
    }
  }, [auth, start, end]);

  const content = (
    <MDBox sx={(theme) => headerStyle(theme)}>
      <MDBox sx={(theme) => containerStyle(theme)}>
        <MDBox
          sx={() => ({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: "100%",
          })}
        >
          <MDBox>
            <InfoIcon
              type={TooltipType.TYPE_1}
              teacherInfoIconBlock={teacherInfoIconBlock.LEAVE_TRACKING}
              color={TooltipIconColor.BLUE}
              className={classes.containerInfoIconWithHeading}
            >
              <MDTypography sx={({ breakpoints }) => ({ ...titleTypography(breakpoints) })}>
                Leave tracking
              </MDTypography>
            </InfoIcon>
            <MDTypography sx={({ breakpoints }) => ({ ...noteTypography(breakpoints) })}>
              You can choose time range with the filter on the right, or it’s from 01/01 of the
              current year, by default
            </MDTypography>
          </MDBox>
          <MDBox>
            <DateSelect
              onChangeStartDate={(e) => {
                setStart(e);
              }}
              onChangeEndDate={(e) => {
                setEnd(e);
              }}
              defaultStartDate={start}
              defaultEndDate={end}
            />
          </MDBox>
        </MDBox>
        {isLoading ? (
          <MDBox
            sx={{
              height: 300,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading style={{ height: 200, width: 300 }} />
          </MDBox>
        ) : data.length === 0 ? (
          <MDBox
            sx={{
              height: 400,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              fontSize: "24px",

              objectFit: "contain",
            }}
            component="img"
            src={NotFound}
          >
            {/* <MDTypography
              sx={({ breakpoints }) => ({
                ...titleTypography(breakpoints),
              })}
            >
              Data not found
            </MDTypography> */}
          </MDBox>
        ) : (
          <MDBox
            width="100%"
            sx={{
              maxHeight: 700,
              overflowY: "scroll",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            {(data ?? []).map((item, index) => (
              <MDBox
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  borderBottom: "1px solid",
                  borderImageSource:
                    "linear-gradient(90deg, rgba(224, 225, 226, 0.3) 0%, #E0E1E2 49.52%, rgba(224, 225, 226, 0.3) 99.04%)",
                  borderImageSlice: 1,
                  height: 60,
                  mb: item !== 4 ? 3 : 0,
                }}
              >
                <MDBox>
                  <MDTypography
                    sx={({ breakpoints }) => ({ ...descriptionTypography(breakpoints) })}
                  >
                    {item?.NgayNghiPhep}
                  </MDTypography>
                  <MDTypography sx={({ breakpoints }) => ({ ...noteTypography(breakpoints) })}>
                    {item?.EmpCode}
                  </MDTypography>
                </MDBox>
                <MDBox sx={{ display: "flex", alignItems: "center" }} gap={4}>
                  {item?.TinhTrang === "Đã phê duyệt" ? (
                    <MDBox sx={{ background: "#B0EED3", borderRadius: "6px", padding: "4px 8px" }}>
                      <MDTypography
                        sx={({ breakpoints }) => ({
                          ...noteTypography(breakpoints),
                          color: "#1AAE6F",
                          fontWeight: 700,
                          textTransform: "uppercase",
                          lineHeight: 1,
                        })}
                      >
                        APPROVED
                      </MDTypography>
                    </MDBox>
                  ) : (
                    <MDBox sx={{ background: "#FDD1DA", borderRadius: "6px", padding: "4px 8px" }}>
                      <MDTypography
                        sx={({ breakpoints }) => ({
                          ...noteTypography(breakpoints),
                          color: "#F80031",
                          fontWeight: 700,
                          textTransform: "uppercase",
                          lineHeight: 1,
                        })}
                      >
                        UNAPPROVED
                      </MDTypography>
                    </MDBox>
                  )}

                  <MDTypography
                    sx={({ breakpoints }) => ({
                      ...descriptionTypography(breakpoints),
                      color: "#5E72E4",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#04188A",
                      },
                    })}
                  >
                    Have queries? <ArrowForward />
                  </MDTypography>
                </MDBox>
              </MDBox>
            ))}
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
  return last ? <MDBox sx={{ minHeight: "calc(100vh - 24px)" }}>{content}</MDBox> : content;
};
