/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// draft-js
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

// Custom styles for the MDEditor
import MDEditorRoot from "components/MDEditor/MDEditorRoot";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

import "./custom.css";
import { useUploadImageMutation } from "shared/redux/endpoints/upload";
import { toast } from "react-toastify";

function MDEditor(props) {
  const { value, defaultValue, setValue, disabled } = props;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [convertedContent, setConvertedContent] = React.useState(null);
  const [editorState, setEditorState] = React.useState(
    !defaultValue
      ? () => EditorState.createEmpty()
      : () =>
          EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(defaultValue))
          )
  );

  const [uploadImage] = useUploadImageMutation();

  const handleUploadImage = async (file) => {
    const formData = new FormData();
    formData.set("file", file);

    const response = await uploadImage(formData);
    if (!response.data.status) {
      return false;
    }
    return response.data.data;
  };

  React.useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
    setValue && setValue(html);
  }, [editorState]);

  React.useEffect(() => {
    if (value === "" && !defaultValue) {
      setEditorState(() => EditorState.createEmpty());
    }
  }, [value, defaultValue]);

  return (
    <MDEditorRoot ownerState={{ darkMode }}>
      {value && typeof value === "function" && value(convertedContent)}
      <Editor
        wrapperClassName="editor-wrapper"
        onContentStateChange={(data) =>
          setValue && typeof setValue === "function" && setValue(draftToHtml(data))
        }
        editorState={editorState}
        onEditorStateChange={setEditorState}
        toolbar={{
          options: ["blockType", "inline", "list", "link", "image"],
          inline: {
            options: ["bold", "italic", "underline"],
            className: "custom-option",
          },
          blockType: {
            inDropdown: true,
            options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
            className: "custom-option",
          },
          list: { options: ["unordered", "ordered"], className: "custom-option" },
          link: { options: ["link"], className: "custom-option" },
          image: {
            className: "custom-option",
            component: undefined,
            popupClassName: undefined,

            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: (file) => {
              return new Promise((resolve, reject) => {
                handleUploadImage(file)
                  .then((response) => {
                    if (response) {
                      resolve({ data: { link: response } });
                    } else {
                      toast.error("Picture too large! Our system's on a diet, only 2MB allowed!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        style: {
                          fontFamily: "Lexend",
                          fontSize: 14,
                        },
                      });
                      reject("Upload failed");
                    }
                  })
                  .catch((error) => {
                    toast.error("Picture too large! Our system's on a diet, only 2MB allowed!", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      style: {
                        fontFamily: "Lexend",
                        fontSize: 14,
                      },
                    });
                    reject(error);
                  });
              });
            },
            previewImage: true,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            alt: { present: true, mandatory: false },
            defaultSize: {
              height: "auto",
              width: "auto",
            },
          },
        }}
        readOnly={disabled}
      />
    </MDEditorRoot>
  );
}

// Setting default values for the props of MDEditor
MDEditor.defaultProps = {
  value: () => {},
  setValue: () => {},
};

// Typechecking props for the MDEditor
MDEditor.propTypes = {
  value: PropTypes.func,
  setValue: PropTypes.func,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
};

export default MDEditor;
