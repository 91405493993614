import { titleTypography } from "shared/styles/style";

export const containerStyle = (theme) => {
  const {
    functions: { pxToRem },
  } = theme;
  return {
    marginBottom: pxToRem(24),
    height: "fit-content",
    padding: 3,
  };
};

export const titleStyle = (theme) => {
  const {
    breakpoints,
    functions: { pxToRem },
  } = theme;

  return {
    ...titleTypography(breakpoints),
    lineHeight: 1.37,
    letterSpacing: pxToRem(-0.8),
    marginBottom: pxToRem(4),
  };
};

export const changePasswordTitleStyle = (theme) => {
  const {
    functions: { pxToRem },
    breakpoints,
  } = theme;

  return {
    ...titleTypography(breakpoints),
    lineHeight: 1.37,
    letterSpacing: pxToRem(-0.8),
  };
};

export const changePasswordSubTitleStyle = (theme) => {
  const {
    typography,
    functions: { pxToRem },
  } = theme;

  return {
    fontFamily: typography.lexendFont,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.37,
    letterSpacing: pxToRem(-0.8),
    color: "#A0AEC0",
    marginBottom: pxToRem(4),
  };
};

export const changePasswordItemStyle = (theme) => {
  const {
    typography,
    functions: { pxToRem },
  } = theme;

  return {
    fontFamily: typography.lexendFont,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.37,
    letterSpacing: pxToRem(-0.8),
    color: "#718096",
  };
};

export const updatePasswordButtonStyle = (theme) => {
  const {
    palette,
    typography,
    functions: { pxToRem },
  } = theme;

  return {
    fontFamily: typography.lexendFont,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 1.5,
    color: palette.white.main,
    marginBottom: pxToRem(4),
    textTransform: "unset",
  };
};

export const errorMessage = (theme) => {
  const { typography } = theme;

  return {
    color: "#f44335",
    fontFamily: typography.lexendFont,
    fontWeight: 400,
    fontSize: 10,
    lineHeight: 1.5,
  };
};
