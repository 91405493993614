import { Autocomplete, Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { DateSelect } from "../../DateSelect/single";
import { useSearchParams } from "react-router-dom";
import FormField from "layouts/pages/account/components/FormField";
import typography from "assets/theme/base/typography";
import pxToRem from "assets/theme/functions/pxToRem";
import ChevronDownIcon from "assets/images/apollo-english/chevron-down.svg";
import DataTable from "./table";
import { useState, useEffect, useMemo } from "react";
import { titleTypography } from "shared/styles/style";
import { descriptionTypography } from "shared/styles/style";
import moment from "moment";
import { useLazyGetOoolabStudentDetailQuery } from "shared/redux/endpoints/sso";
import {
  useLazyGetStudentQuery,
  useLazyGetSuggestionsQuery,
  useLazyGetClassSuggessionForStudentQuery,
  useLazyGetStudentByClassCodeQuery,
  useLazyGetStudentByCodeQuery,
  useLazyGetStudentSuggessionForStudentQuery,
} from "shared/redux/endpoints/assistant";
import InfoIcon, { TooltipIconColor, TooltipType } from "components/InfoIcon";
import { teacherInfoIconBlock } from "components/InfoIcon/teacherInfoIcon";
import makeStyles from "pages/style";

const customStyle = (disable) => ({
  minWidth: "150px",
  ...(disable && { backgroundColor: "#FAFAFA!important" }),
  borderRadius: "0.375rem",
  "& *": {
    fontFamily: "'Lexend', san-serif !important",
  },
  "& .MuiOutlinedInput-root": {
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  "& input, & input::placeholder": {
    fontSize: "12px !important",
    lineHeight: "1.5!important",
    fontWeight: 400,
    fontFamily: typography.lexendFont,
  },

  "& input": {
    paddingLeft: `${pxToRem(14)} !important`,
  },
});

function Students() {
  const classes = makeStyles();
  const [trigger] = useLazyGetOoolabStudentDetailQuery();
  const [searchParams, setSearchParams] = useSearchParams();

  const groupKOC = searchParams.get("group-koc");
  const koc = searchParams.get("koc");
  const centre = searchParams.get("center");
  const className = searchParams.get("class");
  const valueClassName = (className && className?.split(",")) || [];
  const studentName = searchParams.get("student-name");
  const valueStudentName = (studentName && studentName?.split(",")) || [];
  const page = searchParams.get("page") || 0;
  const size = searchParams.get("size") || 10;
  const date =
    searchParams.get("date") || moment(new Date()).startOf("isoweek").format("MM-DD-YYYY");

  const [totalPage, setTotalPage] = useState(1);

  const handlePrev = () => {
    searchParams.set("page", (page ?? 0) - 1);
    setSearchParams(searchParams);
  };

  const handleNext = () => {
    searchParams.set("page", (page ?? 0) + 1);
    setSearchParams(searchParams);
  };

  const setCurrentPage = (page) => {
    searchParams.set("page", page);
    setSearchParams(searchParams);
  };

  const setSize = (size) => {
    searchParams.set("size", size);
    setSearchParams(searchParams);
  };

  const [dataTable, setDataTable] = useState({
    columns: [
      { Header: "No", accessor: "no", width: "5%" },
      { Header: "Student Code", accessor: "studentCode", width: "15%" },
      { Header: "Class Name", accessor: "className", width: "15%" },
      { Header: "Student Name", accessor: "studentName", width: "15%" },
      { Header: "Nickname", accessor: "nickname", width: "5%" },
      { Header: "Engagement", accessor: "engagement", width: "15%" },
      { Header: "Performance", accessor: "performance", width: "15%" },
      { Header: "Home Learning", accessor: "homeLearning", width: "15%" },
      { Header: "LMS", accessor: "LMS", width: "10%" },
    ],

    rows: [],
  });

  const [studentTable, setStudentTable] = useState([]);
  const [suggestions, setSuggestions] = useState({
    centerName: [],
    groupKOC: [],
    koc: [],
  });
  const [classSuggestions, setClassSuggestions] = useState([]);
  const [studentSuggestions, setStudentSuggestions] = useState([]);

  const [step, setStep] = useState(
    studentName ? 5 : className ? 4 : koc ? 3 : groupKOC ? 2 : centre ? 1 : 0
  );

  const [triggerGetStudent, { isFetching: isFetchingStudent }] = useLazyGetStudentQuery();
  const [triggerSuggestions, { isFetching: isFetchingSuggestions }] = useLazyGetSuggestionsQuery();
  const [triggerGetClassSuggession, { isFetching: isFetchingClassSuggestions }] =
    useLazyGetClassSuggessionForStudentQuery();
  const [triggerGetStudentSuggession, { isFetching: isFetchingStudentSuggestions }] =
    useLazyGetStudentSuggessionForStudentQuery();
  const [triggerGetStudentByClassCode, { isFetching: isFetchingStudentByClassCode }] =
    useLazyGetStudentByClassCodeQuery();
  const [triggerGetStudentByStudentCode, { isFetching: isFetchingStudentByStudentCode }] =
    useLazyGetStudentByCodeQuery();

  const handleFetchSuggestions = async (data) => {
    const response = await triggerSuggestions(data);
    if (response?.data?.status) {
      setSuggestions(response?.data?.data);
    }
  };

  const handleFetchClassSuggestions = async (data) => {
    const response = await triggerGetClassSuggession(data);
    if (response?.data?.status) {
      setClassSuggestions(response?.data?.data);
    }
  };

  const handleFetchStudentSuggestions = async (data) => {
    const response = await triggerGetStudentSuggession(data);
    if (response?.data?.status) {
      setStudentSuggestions(response?.data?.data);
    }
  };

  const queryParams = useMemo(
    () => ({
      page: page ?? 0,
      size: size ?? 0,
      sort: "",
      startDate: date ? moment(date).format("DD-MM-YYYY") : "",
      endDate: date ? moment(date).format("DD-MM-YYYY") : "",
      groupKOC: groupKOC ?? "",
      centerName: centre ?? "",
      KOC: koc ?? "",
      classCode: className,
      studentCode: studentName,
    }),
    [page, size, date, groupKOC, centre, koc, className, studentName]
  );

  useEffect(() => {
    const fetchStudents = async () => {
      if (queryParams.studentCode) {
        const response = await triggerGetStudentByStudentCode({
          code: queryParams.studentCode,
          startDate: queryParams.startDate,
          endDate: queryParams.endDate,
        });
        if (response?.data?.status) {
          setStudentTable(response?.data?.data ?? []);
          setTotalPage(1);
        }
      } else if (queryParams.classCode) {
        const response = await triggerGetStudentByClassCode({
          code: queryParams.classCode,
          startDate: queryParams.startDate,
          endDate: queryParams.endDate,
          page: queryParams.page,
          size: queryParams.size,
        });
        if (response?.data?.status) {
          setStudentTable(response?.data?.data?.data ?? []);
          setTotalPage(response?.data?.data?.totalPage ?? 1);
        }
      } else {
        const response = await triggerGetStudent({
          ...queryParams,
        });

        if (response?.data?.status) {
          setStudentTable(response?.data?.data?.data ?? []);
          setTotalPage(response?.data?.data?.totalPage ?? 1);
        }
      }
    };
    void fetchStudents();
  }, [queryParams]);

  useEffect(() => {
    handleFetchSuggestions({
      centerName: centre || "",
      groupKOC: groupKOC || "",
    });
  }, []);

  useEffect(() => {
    if (centre && groupKOC && koc && date) {
      handleFetchClassSuggestions({
        startDate: date ? moment(date).format("DD-MM-YYYY") : "",
        endDate: date ? moment(date).format("DD-MM-YYYY") : "",
        groupKOC: groupKOC || "",
        KOC: koc || "",
        centerName: centre,
      });
    }
  }, [centre, groupKOC, koc, date]);

  useEffect(() => {
    if (className) {
      handleFetchStudentSuggestions({
        classCode: className,
        startDate: date ? moment(date).format("DD-MM-YYYY") : "",
        endDate: date ? moment(date).format("DD-MM-YYYY") : "",
      });
    }
  }, [className, date]);

  // useEffect(() => {
  //   if (!date) {
  //     searchParams.set("date", moment(new Date()).startOf("isoweek").format("MM-DD-YYYY"));
  //     setSearchParams(searchParams);
  //   }
  //   if (!size) {
  //     searchParams.set("size", 10);
  //     setSearchParams(searchParams);
  //   }
  //   if (!page) {
  //     searchParams.set("page", 0);
  //     setSearchParams(searchParams);
  //   }
  // }, [date, size, page]);

  const isFetching = useMemo(
    () =>
      isFetchingStudent ||
      isFetchingSuggestions ||
      isFetchingClassSuggestions ||
      isFetchingStudentSuggestions ||
      isFetchingStudentByClassCode ||
      isFetchingStudentByStudentCode,
    [
      isFetchingStudent,
      isFetchingSuggestions,
      isFetchingClassSuggestions,
      isFetchingStudentSuggestions,
      isFetchingStudentByClassCode,
      isFetchingStudentByStudentCode,
    ]
  );

  useEffect(() => {
    if (studentTable) {
      const customDataRow = studentTable.map((item, index) => {
        return {
          no: parseInt(size) * parseInt(page) + index + 1,
          id: item.studentId,
          studentCode: item?.studentCode,
          nickname: item?.nickName ?? "",
          className: item?.className,
          studentName: item?.studentName,
          engagement: (
            <MDTypography
              variant="span"
              sx={({ breakpoints }) => ({
                ...descriptionTypography(breakpoints),
                lineHeight: 1.5,
                color: item.engagementStatus === "OFFTRACK" ? "#F5365C" : "#172B4D",

                paddingRight: 2,
                display: "block",

                [breakpoints.down(1600)]: {
                  fontSize: 12,
                },
              })}
            >
              {item.engagementStatus}
            </MDTypography>
          ),
          performance: (
            <MDTypography
              variant="span"
              sx={({ breakpoints }) => ({
                ...descriptionTypography(breakpoints),
                lineHeight: 1.5,
                color: item.homeLearningStatus === "OFFTRACK" ? "#F5365C" : "#172B4D",

                paddingRight: 2,
                display: "block",

                [breakpoints.down(1600)]: {
                  fontSize: 12,
                },
              })}
            >
              {item.performanceStatus}
            </MDTypography>
          ),
          homeLearning: (
            <MDTypography
              variant="span"
              sx={({ breakpoints }) => ({
                ...descriptionTypography(breakpoints),
                lineHeight: 1.5,
                color: item.performanceStatus === "OFFTRACK" ? "#F5365C" : "#172B4D",

                paddingRight: 2,
                display: "block",

                [breakpoints.down(1600)]: {
                  fontSize: 12,
                },
              })}
            >
              {item.homeLearningStatus}
            </MDTypography>
          ),
          LMS: (
            <MDBox
              sx={{
                backgroundColor: "#2576B9",
                color: "#FFFFFF !important",
                padding: "5px 10px",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={async (e) => {
                e.stopPropagation();
                if (item?.studentCode) {
                  const res = await trigger({
                    studentCode: item.studentCode,
                  });
                  if (res) {
                    window.open(res.data.data.url, "_blank");
                  }
                }
              }}
            >
              Go to LMS
            </MDBox>
          ),
        };
      });
      setDataTable({
        ...dataTable,
        rows: customDataRow || [],
      });
    }
  }, [studentTable]);

  return (
    <MDBox mb={3}>
      <Card>
        <MDBox p={3} lineHeight={1}>
          <InfoIcon
            type={TooltipType.TYPE_1}
            teacherInfoIconBlock={teacherInfoIconBlock.STUDENT_LIST}
            color={TooltipIconColor.BLUE}
            className={classes.containerInfoIconWithHeading}
          >
            <MDTypography sx={({ breakpoints }) => ({ ...titleTypography(breakpoints) })}>
              Student list
            </MDTypography>
          </InfoIcon>
        </MDBox>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          gap={2}
          width="100%"
          height="100%"
          px={3}
          pb={3}
          sx={({ breakpoints }) => ({
            [breakpoints.down("lg")]: {
              justifyContent: "flex-start",
            },
          })}
        >
          <Grid item sx={({ breakpoints }) => ({ [breakpoints.down("md")]: [{ width: "100%" }] })}>
            <DateSelect
              onChangeDate={(e) => {
                searchParams.set("date", moment(e).format("MM-DD-YYYY"));
                setSearchParams(searchParams);
              }}
              defaultDate={
                date
                  ? moment(date).format("MM-DD-YYYY")
                  : moment(new Date()).startOf("isoweek").format("MM-DD-YYYY")
              }
              disable={isFetching}
            />
          </Grid>
          <Grid item sx={({ breakpoints }) => ({ [breakpoints.down("md")]: [{ width: "100%" }] })}>
            <Autocomplete
              value={centre || ""}
              options={suggestions.centerName || []}
              onChange={(e, v) => {
                searchParams.set("center", v ?? "");
                searchParams.delete("group-koc");
                searchParams.delete("koc");
                searchParams.delete("class");
                searchParams.delete("student-name");
                setSearchParams(searchParams);
                handleFetchSuggestions({
                  centerName: v || "",
                  groupKOC: "",
                });
                if (v) {
                  setStep(1);
                } else {
                  setStep(0);
                }
              }}
              popupIcon={
                <div style={{ height: 24, display: "flex", alignItems: "center" }}>
                  <img src={ChevronDownIcon} />
                </div>
              }
              disabled={isFetching}
              renderInput={(params) => (
                <FormField
                  {...params}
                  variant="outlined"
                  label="Centre"
                  customStyle={customStyle(isFetching)}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item sx={({ breakpoints }) => ({ [breakpoints.down("md")]: [{ width: "100%" }] })}>
            <Autocomplete
              value={groupKOC || ""}
              options={suggestions?.groupKOC || []}
              onChange={(e, v) => {
                searchParams.set("group-koc", v ?? "");
                searchParams.delete("koc");
                searchParams.delete("class");
                searchParams.delete("student-name");
                setSearchParams(searchParams);
                handleFetchSuggestions({
                  centerName: centre || "",
                  groupKOC: v || "",
                });
                if (v) {
                  setStep(2);
                } else {
                  setStep(1);
                }
              }}
              popupIcon={
                <div style={{ height: 24, display: "flex", alignItems: "center" }}>
                  <img src={ChevronDownIcon} />
                </div>
              }
              disabled={suggestions?.groupKOC?.length === 0 || isFetching || step < 1}
              renderInput={(params) => (
                <FormField
                  {...params}
                  variant="outlined"
                  label="Group KOC"
                  customStyle={customStyle(
                    suggestions?.groupKOC?.length === 0 || isFetching || step < 1
                  )}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item sx={({ breakpoints }) => ({ [breakpoints.down("md")]: [{ width: "100%" }] })}>
            <Autocomplete
              options={suggestions?.koc || []}
              onChange={(e, v) => {
                searchParams.set("koc", v ?? "");
                searchParams.delete("class");
                searchParams.delete("student-name");
                setSearchParams(searchParams);
                if (v) {
                  setStep(3);
                } else {
                  setStep(2);
                }
              }}
              popupIcon={
                <div style={{ height: 24, display: "flex", alignItems: "center" }}>
                  <img src={ChevronDownIcon} />
                </div>
              }
              value={koc || ""}
              disabled={suggestions?.koc?.length === 0 || isFetching || step < 2}
              renderInput={(params) => (
                <FormField
                  {...params}
                  variant="outlined"
                  label="KOC"
                  customStyle={customStyle(
                    suggestions?.koc?.length === 0 || isFetching || step < 2
                  )}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item sx={({ breakpoints }) => ({ [breakpoints.down("md")]: [{ width: "100%" }] })}>
            <Autocomplete
              multiple
              value={valueClassName}
              options={classSuggestions.map((item) => item.value)}
              popupIcon={
                <div style={{ height: 24, display: "flex", alignItems: "center" }}>
                  <img src={ChevronDownIcon} alt="dropdown-icon" />
                </div>
              }
              disabled={classSuggestions?.length === 0 || isFetching || step < 3}
              onChange={(_, v) => {
                searchParams.set("class", v ?? "");
                searchParams.delete("student-name");
                setSearchParams(searchParams);
                if (v.length > 0) {
                  setStep(4);
                } else {
                  setStep(3);
                }
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label="Class"
                  customStyle={customStyle(
                    classSuggestions?.length === 0 || isFetching || step < 3
                  )}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item sx={({ breakpoints }) => ({ [breakpoints.down("md")]: [{ width: "100%" }] })}>
            <Autocomplete
              multiple
              popupIcon={
                <div style={{ height: 24, display: "flex", alignItems: "center" }}>
                  <img src={ChevronDownIcon} />
                </div>
              }
              value={valueStudentName}
              options={studentSuggestions.map((item) => item.value)}
              disabled={studentSuggestions?.length === 0 || isFetching || step < 4}
              onChange={(_, v) => {
                searchParams.set("student-name", v ?? "");
                setSearchParams(searchParams);
                if (v.length > 0) {
                  setStep(5);
                } else {
                  setStep(4);
                }
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  label="Student"
                  customStyle={customStyle(
                    studentSuggestions?.length === 0 || isFetching || step < 4
                  )}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
        </Grid>

        <DataTable
          table={dataTable}
          isSorted={false}
          currentPage={parseInt(queryParams.page ?? 0)}
          handlePrev={handlePrev}
          handleNext={handleNext}
          totalPage={totalPage}
          setCurrentPage={setCurrentPage}
          isLoading={isFetching}
          isEmpty={studentTable?.length === 0}
          studentPageSize={parseInt(queryParams.size ?? 10)}
          setStudentPageSize={setSize}
        />
      </Card>
    </MDBox>
  );
}

export { Students };
