import { Card, CardContent, CardMedia, Divider, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { access, descriptionStyle, headerStyle, nameStyle } from "./style";
import MDButton from "components/MDButton";
import { noteTypography } from "shared/styles/style";
import booking3 from "assets/images/products/product-3-min.jpg";
import { titleTypography } from "shared/styles/style";
import { descriptionTypography } from "shared/styles/style";
import MDBadge from "components/MDBadge";
import { SVGIcon } from "components/icons";
import { useSelector } from "react-redux";

// eslint-disable-next-line react/prop-types
export const Course = ({ courseName, cover_url, course_url, disable, description, action }) => {
  const user = useSelector((state) => state.auth.user);
  const email = user.email;
  return (
    <Card
      sx={{
        maxWidth: "100%",
        width: "100%",
        background: "#EDF2F7",
        boxShadow: "none",
      }}
    >
      <MDBox
        position="relative"
        borderRadius="lg"
        className="card-header"
        py={2}
        px={2.5}
        sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
        width="100%"
      >
        <MDBox display="flex" justifyContent="space-between" mb={2} width="100%">
          <MDBox
            sx={{
              borderRadius: "8px",
              background: "linear-gradient(122deg, #11CDEF 9.06%, #1171EF 88.15%)",
            }}
          >
            <MDTypography
              sx={({ breakpoints }) => ({
                ...titleTypography(breakpoints),
                [breakpoints.down(1600)]: {
                  fontSize: "11px !important",
                },

                [breakpoints.down(1400)]: {
                  fontSize: "10px !important",
                },

                [breakpoints.down(600)]: {
                  fontSize: "8px !important",
                },
                color: "#fff",
                padding: "5px 10px",
              })}
            >
              {courseName}
            </MDTypography>
          </MDBox>

          <MDBox
            display="flex"
            alignItems="center"
            gap={1}
            onClick={() => {
              const linkToWeCourses = `https://welearn.apollo.vn/s-s-o?magic=${encodeURIComponent(
                email
              )}&type=${2}`;
              window.open(linkToWeCourses, "_blank");
            }}
          >
            <MDTypography
              sx={({ breakpoints }) => ({
                ...titleTypography(breakpoints),
                color: "#2DCE89",
                textTransform: "capitalize",
                [breakpoints.down(768)]: {
                  fontSize: 10,
                },
                [breakpoints.down(500)]: {
                  fontSize: "8px !important",
                },
              })}
            >
              {disable ? "Pending" : "In Progress"}
            </MDTypography>
            <SVGIcon name="chevon-right" />
          </MDBox>
        </MDBox>

        <MDBox
          component="img"
          src={
            cover_url ??
            "https://demo-lesson.sgp1.digitaloceanspaces.com/Screenshot%202024-08-27%20at%2010.20.46%E2%80%AFAM.png"
          }
          alt={courseName}
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="200px"
          zIndex={10}
          onDragStart={(e) => e.preventDefault()}
        />
      </MDBox>
    </Card>
  );
};
