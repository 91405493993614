import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import {
  myTasksContainerStyle,
  accessLMS,
  completionTitleStyle,
  overdueTextStyle,
  toCompleteTextStyle,
  alertStyle,
  taskCountStyle,
  finishedStyle,
} from "./style";
import { StatusList } from "pages/assistant/components/Table/Tasks/statusList";
import MDButton from "components/MDButton";
import { WarningAmberOutlined } from "@mui/icons-material";
import BellIcon from "assets/images/apollo-english/bell.svg";
import { descriptionTypography } from "shared/styles/style";

export const Task = () => {
  const data = [
    {
      color: "#DF25FD",
      id: "completed_on_time",
      label: "Completed on time",
      percent: 100,
      value: 24,
    },
    {
      color: "#2DCE89",
      id: "In progress overdue",
      label: "In progress overdue",
      percent: 100,
      value: 4,
    },
  ];
  return (
    <MDBox sx={myTasksContainerStyle}>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start">
        <MDBox mb={5}>
          <MDTypography sx={completionTitleStyle}>Today&apos;s task</MDTypography>
        </MDBox>
      </MDBox>
      {data.length > 0 ? (
        <>
          <MDBox
            display="flex"
            gap={5}
            mb={2}
            sx={({ breakpoints }) => ({
              height: "100%",
              flexDirection: "column",
              [breakpoints.down("md")]: {
                flexDirection: "column",
              },
            })}
          >
            {data.map((item, index) => (
              <MDBox key={index} sx={{ display: "flex", alignItems: "flex-start" }}>
                <MDBox
                  sx={{ height: 10, width: 10, borderRadius: 5, backgroundColor: "red", mr: 3 }}
                ></MDBox>
                <MDBox sx={{ mr: 5 }}>
                  <MDTypography
                    sx={({ breakpoints }) => ({
                      ...descriptionTypography(breakpoints),
                      fontWeight: 700,
                      color: "#fff",
                      lineHeight: 1.25,
                    })}
                  >
                    Complete Progress Report
                  </MDTypography>
                  <MDTypography
                    sx={({ breakpoints }) => ({
                      ...descriptionTypography(breakpoints),

                      color: "#fff",
                    })}
                  >
                    Due: 09/10/2023
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography
                    sx={({ breakpoints }) => ({
                      ...descriptionTypography(breakpoints),
                      fontWeight: 700,
                      lineHeight: 1.25,
                      color: "#fff",
                    })}
                  >
                    | JCEC
                  </MDTypography>
                  <MDTypography
                    sx={({ breakpoints }) => ({
                      ...descriptionTypography(breakpoints),

                      color: "#fff",
                    })}
                  >
                    Overdue
                  </MDTypography>
                </MDBox>
              </MDBox>
            ))}
          </MDBox>
        </>
      ) : (
        <MDBox
          sx={({ breakpoints }) => ({
            fontSize: 36,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "200px",
            flexGrow: 1,

            objectFit: "contain",

            [breakpoints.down(1600)]: {
              height: "160px",
            },
          })}
          component="img"
          src={NotFound}
        >
          {/* <WarningAmberOutlined fontSize="inherit" sx={{ color: "#999999" }} />
          <MDTypography
            sx={{
              fontSize: 14,
              color: "#999999",
            }}
          >
            Data is empty
          </MDTypography> */}
        </MDBox>
      )}
    </MDBox>
  );
};
