import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "shared/layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-sign-in-cover.jpeg";
import { useChangePasswordMutation } from "shared/redux/endpoints/auth";
import { selectAuth } from "shared/redux/slices/authSlice";
import { useSelector } from "react-redux";
import theme from "assets/theme";
import { useFormik } from "formik";
import { form, initialValues, validations } from "./form";
import { useNavigate } from "react-router-dom";
import Apo from "./apo";
import "./apo.css";
import Lottie from "react-lottie-player";
import logo from "./logo.json";
import InputAdornment from "@mui/material/InputAdornment";
import { IconButton } from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useState } from "react";
import pxToRem from "../../assets/theme/functions/pxToRem";
import typography from "../../assets/theme/base/typography";

const customStyle = {
  "& .MuiInputBase-root, & .MuiInputBase-root .MuiInputBase-input": {
    height: "100%",
    lineHeight: "100%",
  },

  "& .MuiInputBase-root .MuiInputBase-input": {
    paddingLeft: pxToRem(20),
  },

  "& input::placeholder": {
    fontFamily: typography.lexendFont,
  },

  "& label": {
    fontSize: 12,
    lineHeight: "16px",
    fontFamily: typography.lexendFont,
    color: "#A0AEC0",
    left: 6,
  },

  "& legend": {
    marginLeft: 6,
  },
};

function ChangePassword() {
  const {
    typography: { lexendFont },
  } = theme;

  const { formId, formField } = form;
  const { password } = formField;

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const navigate = useNavigate();

  const auth = useSelector(selectAuth);

  const formik = useFormik({
    initialValues,
    validationSchema: validations,
    onSubmit: async (values) => {
      await changePassword({ password: values.password });
      navigate("/");
    },
  });

  const passwordError = formik.touched.password && Boolean(formik.errors.password);

  return (
    <CoverLayout image={bgImage}>
      <Apo />
      <Card id="form">
        <MDBox
          borderRadius="lg"
          coloredShadow="success"
          mx="auto"
          mt={-9.5}
          textAlign="center"
          sx={({ functions: { pxToRem } }) => ({
            width: pxToRem(130),
            height: pxToRem(105),
            background: "#FFF",
          })}
        >
          <Lottie animationData={logo} play />
        </MDBox>
        <MDBox
          p={{ xs: 2, lg: 3, xl: 4, xxl: 6 }}
          sx={({ functions: { pxToRem } }) => ({
            paddingTop: `${pxToRem(14)}!important`,
            display: "flex",
            flexDirection: "column",
          })}
        >
          <MDTypography
            textAlign="center"
            sx={({ breakpoints, functions: { pxToRem } }) => ({
              color: "#172B4D",
              fontFamily: lexendFont,
              fontWeight: 800,
              fontSize: 24,
              lineHeight: 1.37,
              textSpacing: "-0.8px",
              marginBottom: pxToRem(16),

              [breakpoints.down(1600)]: {
                fontSize: 20,
                marginBottom: pxToRem(12),
              },

              [breakpoints.down(1367)]: {
                fontSize: 16,
                marginBottom: pxToRem(10),
              },
            })}
          >
            Hi, {auth.user.fullName}! It&apos;s good to see you!
          </MDTypography>

          <MDTypography
            textAlign="left"
            sx={({ breakpoints, functions: { pxToRem } }) => ({
              width: "fit-content",
              alignSelf: "center",
              color: "#8392AB",
              fontFamily: lexendFont,
              fontWeight: 400,
              fontSize: 16,
              lineHeight: 1.5,
              marginBottom: pxToRem(24),

              [breakpoints.down(1600)]: {
                fontSize: 13,
                marginBottom: pxToRem(16),
              },

              [breakpoints.down(1367)]: {
                fontSize: 11,
                marginBottom: pxToRem(12),
              },
            })}
          >
            Welcome to My Apollo! We&apos;re thrilled to have you on board.
            <br />
            <MDBox component="ul" sx={{ listStyleType: "disc!important", color: "#8392AB", pl: 4 }}>
              <li>Here&apos;s a quick rundown of what you can do with Apollo:</li>
              <li>Stay on schedule 📅: Keep track of your classes and never miss a beat.</li>
              <li>Manage to-do tasks 📝: Organize your tasks and get things done efficiently. </li>
              <li>
                Monitor students 🚨: Identify and support students who need a little extra help.
              </li>
              <li>View class performance 📊: Check out detailed performance reports. </li>
            </MDBox>
            <br />
            To get started, please set a new password. <br />
            <br />
            Let&apos;s make teaching even more awesome together! <br />
            <br />
            🌟 Enjoy your journey with Apollo!
          </MDTypography>

          <MDBox component="form" role="form" id={formId} onSubmit={formik.handleSubmit}>
            <MDBox
              mb={{ xs: 2, lg: 3 }}
              sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}
            >
              <MDInput
                type={showPassword ? "text" : password.type}
                label={password.label}
                name={password.name}
                variant="outlined"
                fullWidth
                placeholder="************"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={passwordError}
                isPassword
                sx={({ functions: { pxToRem } }) => ({
                  height: pxToRem(50),
                  width: "100%",
                  maxWidth: pxToRem(350),
                })}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="small"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                customStyle={customStyle}
              />
              {passwordError ? (
                <MDTypography
                  sx={{
                    color: "#f44335",
                    fontFamily: lexendFont,
                    fontWeight: 400,
                    fontSize: 10,
                    lineHeight: 1.5,
                  }}
                >
                  {formik.errors.password}
                </MDTypography>
              ) : (
                <></>
              )}
            </MDBox>
            <MDBox mt={{ xs: 2, lg: 3 }} mb={1} display="flex" justifyContent="center">
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                sx={({ typography, functions: { pxToRem } }) => ({
                  width: pxToRem(160),
                  height: pxToRem(40),
                  fontSize: 14,
                  lineHeight: 1.5,
                  fontWeight: 700,
                  textTransform: "unset",
                  background: "#161616!important",
                  fontFamily: typography.lexendFont,
                })}
              >
                Log me in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default ChangePassword;
