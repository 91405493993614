import React from "react";
import CustomTooltip from "../CustomTooltip";
import MDBox from "../MDBox";
import { SVGIcon } from "../icons";
import teacherInfoDescriptionMap, { teacherInfoIconBlock } from "./teacherInfoIcon";

export const TooltipIconColor = {
  BLUE: "blue",
  WHITE: "white",
};

export const TooltipType = {
  CARD: "card",
  TYPE_1: "type1",
  TYPE_2: "type2",
};

type TeacherInfoIconBlock = typeof teacherInfoIconBlock;

interface TooltipProps {
  teacherInfoIconBlock?: TeacherInfoIconBlock;
  type: TooltipType;
  content?: string;
  color?: TooltipIconColor;
  children?: React.ReactNode;
}

const InfoIcon: React.FC<TooltipProps> = ({
  teacherInfoIconBlock,
  type,
  content,
  color = TooltipIconColor.WHITE,
  children,
  ...rest
}) => {
  const colorApply = color === TooltipIconColor.BLUE ? "#2376BC" : TooltipIconColor.WHITE;
  if (teacherInfoIconBlock) {
    content = teacherInfoDescriptionMap.get(teacherInfoIconBlock);
  }
  if (type === TooltipType.CARD) {
    return (
      <CustomTooltip content={content}>
        <MDBox {...rest}>
          <SVGIcon name={"information"} fill={colorApply} />
        </MDBox>
      </CustomTooltip>
    );
  }
  if (type === TooltipType.TYPE_1) {
    return (
      <MDBox display="flex">
        {children}
        <CustomTooltip content={content}>
          <MDBox {...rest}>
            <SVGIcon name={"information"} fill={colorApply} />
          </MDBox>
        </CustomTooltip>
      </MDBox>
    );
  }
  if (type === TooltipType.TYPE_2) {
    return (
      <>
        <MDBox> {children} </MDBox>
        <CustomTooltip content={content}>
          <MDBox {...rest}>
            <SVGIcon name={"information"} fill={colorApply} />
          </MDBox>
        </CustomTooltip>
      </>
    );
  }
  return null;
};

export default InfoIcon;
