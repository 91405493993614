import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "examples/Header";
import WeeklyCalendar from "./components/WeeklCalendar";
import MonthCalendar from "./components/MonthCalendar";
import calendarStyles from "./style";
import { useState } from "react";
import moment from "moment";
import InfoIcon, { TooltipIconColor, TooltipType } from "../../components/InfoIcon";
import { teacherInfoIconBlock } from "../../components/InfoIcon/teacherInfoIcon";

function Calendar() {
  const [start, setStart] = useState(moment().startOf("isoWeek").weekday(1).format("YYYY-MM-DD"));
  const [end, setEnd] = useState(moment().startOf("isoWeek").weekday(7).format("YYYY-MM-DD"));

  return (
    <DashboardLayout>
      <Header />
      <MDBox sx={calendarStyles}>
        <MDBox
          sx={() => ({
            width: "100%",
          })}
        >
          <InfoIcon
            type={TooltipType.TYPE_2}
            teacherInfoIconBlock={teacherInfoIconBlock.WEEK_MONTH}
            color={TooltipIconColor.BLUE}
          >
            <Grid container columnSpacing={2} rowGap={2}>
              <Grid item xs={12} xl>
                <WeeklyCalendar outerDate={start} />
              </Grid>
              <Grid item xs={12} xl="auto" mb={{ xs: 3, xl: 0 }}>
                <MonthCalendar start={start} setStart={setStart} end={end} setEnd={setEnd} />
              </Grid>
            </Grid>
          </InfoIcon>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Calendar;
