import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  file: null,
};

const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    setFile: (state, { payload }) => {
      state.file = payload.file;
    },
  },
});

export default uploadSlice.reducer;

export const { setFile } = uploadSlice.actions;

export const selectFile = (state) => state.file;
