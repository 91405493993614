import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => {
  const { breakpoints } = theme;

  return {
    containerDisplayFlex: {
      display: "flex",
    },
    containerInfoIconCard: {
      position: "absolute",
      top: "5px",
      right: "5px",
    },
    containerInfoIconWith1: {
      marginTop: "10px",
    },
    containerInfoIconWith2: {
      marginTop: "-10px",
      marginLeft: "5px",
    },
    containerInfoIconWith3: {
      marginTop: "-30px",
      marginLeft: "5px",
    },
    containerInfoIconWith4: {
      position: "absolute",
      marginTop: "-5px",
      marginRight: "-25px",
      top: 0,
      right: 0,
    },
    containerInfoIconWith5: {
      position: "absolute",
      marginTop: "-5px",
      marginRight: "-5px",
      top: 0,
      right: 0,
    },
    containerInfoIconWith6: {
      marginTop: "-5px",
      marginLeft: "5px",
    },
    containerInfoIconWithHeading: {
      marginTop: "-5px",
      marginLeft: "5px",
    },
    containerCalendarFilterTabs: {
      position: "relative",
    },
    containerCalendarBreakdownUnderSmallCalendar: {
      position: "relative",
    },
  };
});
