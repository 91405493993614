import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLazyUserQuery } from "shared/redux/endpoints/auth";
import { setTokens, setUser, selectAuth, setRole } from "shared/redux/slices/authSlice";

export default function GoogleSuccess() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);

  const [trigger] = useLazyUserQuery();

  useEffect(() => {
    const handleSSOLogin = async () => {
      const accessToken = searchParams.get("accessToken");
      const refreshToken = searchParams.get("refreshToken");

      const role = JSON.parse(atob(accessToken.split(".")[1])).roles[0];

      if (!!accessToken && !!refreshToken) {
        await dispatch(
          setTokens({
            accessToken,
            refreshToken,
          })
        );
        const result = await trigger();

        console.log(32, result);

        dispatch(
          setUser({
            user: {
              ...result.data,
              avatar:
                result.data.avatar ?? "https://demo-lesson.sgp1.digitaloceanspaces.com/ava.png",
            },
          })
        );
        dispatch(
          setRole({
            role: role === "ROLE_CSO" ? "CSO" : role === "ROLE_ADMIN" ? "Admin" : "Teacher",
          })
        );
      }
    };

    handleSSOLogin();
  }, []);

  useEffect(() => {
    if (auth.accessToken && auth.refreshToken) {
      navigate("/");
    }
  }, [auth.refreshToken, auth.accessToken]);

  return <></>;
}
