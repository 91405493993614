import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { SVGIcon } from "components/icons";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Header from "examples/Header";
import { MenuTabs } from "./components/MenuTabs";

import { headerStyles, mySelfStyles } from "./styles/myself";
import { useState } from "react";
import { PersonalInfomation } from "./components/PersonalInformation";
import { LabourInfomation } from "./components/LabourInformation";
import { LeaveSystem } from "./components/LeaveSystem";

function MySelf() {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <DashboardLayout>
      <Header />
      <MDBox sx={(theme) => mySelfStyles(theme)}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          columnSpacing={3}
          sx={(theme) => headerStyles(theme)}
        >
          <MenuTabs tabIndex={tabIndex} setTabIndex={setTabIndex} />
        </Grid>
        {tabIndex === 0 ? <PersonalInfomation /> : null}
        {tabIndex === 1 ? <LabourInfomation /> : null}
        {tabIndex === 2 ? <LeaveSystem /> : null}
      </MDBox>
    </DashboardLayout>
  );
}

export default MySelf;
