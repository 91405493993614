import { titleTypography } from "shared/styles/style";

export const containerStyle = (theme) => {
  const {
    functions: { pxToRem },
    palette,
  } = theme;
  return {
    borderRadius: pxToRem(16),
    background: palette.white.main,
    padding: `${pxToRem(22)}`,

    overflow: "visible",
  };
};

export const titleStyle = (theme) => {
  const {
    functions: { pxToRem },
    breakpoints,
  } = theme;

  return {
    ...titleTypography(breakpoints),
    lineHeight: 1.37,
    letterSpacing: pxToRem(-0.8),
    marginBottom: pxToRem(4),
  };
};
