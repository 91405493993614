import { useEffect, useState } from "react";

import { MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import ChevronDownIcon from "assets/images/apollo-english/chevron-down.svg";

import "./custom.css";

export const CustomSelect = ({ options, customStyle, iconSize, setOuterValue, defaultValue }) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setOuterValue?.(value);
  }, [value]);

  return (
    <Select
      value={value}
      onChange={(e) => setValue(e.target.value)}
      IconComponent={() => (
        <MDBox
          component="img"
          width={`${iconSize}px`}
          src={ChevronDownIcon}
          sx={{ position: "absolute", right: 16 }}
        />
      )}
      sx={({ functions: { pxToRem } }) => ({
        height: pxToRem(40),
        paddingRight: pxToRem(36),

        ...customStyle,
      })}
    >
      {(options ?? []).map((item, index) => {
        const { value, label } = item;
        return (
          <MenuItem key={index} value={value}>
            {label}
          </MenuItem>
        );
      })}
    </Select>
  );
};

CustomSelect.defaultProps = {
  options: [
    {
      value: "",
      label: "All",
    },
  ],

  customStyle: {},
  iconSize: 16,
};

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,

      label: PropTypes.string,
    })
  ),
  customStyle: PropTypes.any,
  iconSize: PropTypes.number,
  setOuterValue: PropTypes.func,
  defaultValue: PropTypes.string,
};
