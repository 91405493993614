// MD Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

// Mui Components

import { Icon, IconButton } from "@mui/material";

// context
import { useMaterialUIController, setOpenNotification, setMiniSidenav } from "context";

// styles
import { ArrowStyles } from "./styles";

export const AdminHeader = () => {
  const [controller, dispatch] = useMaterialUIController();

  const { miniSidenav, darkMode, transparentNavbar } = controller;

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  return (
    <MDBox
      sx={({ functions: { pxToRem } }) => ({
        width: "100%",
        height: pxToRem(300),
        position: "fixed",
        overflow: "hidden",
        background: "#1576BC",
      })}
    ></MDBox>
  );
};
