import PropTypes from "prop-types";

export const IconInformation = ({ width = 18, height = 16, fill = "#2376BC" }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
          stroke={fill}
          strokeWidth="1.2"
        />
        <path
          d="M8.00156 5.8C8.00156 5.58783 8.14906 5.38434 8.41161 5.23431C8.67416 5.08429 9.03026 5 9.40156 5C9.77287 5 10.129 5.08429 10.3915 5.23431C10.6541 5.38434 10.8016 5.58783 10.8016 5.8C10.8016 6.01217 10.6541 6.21566 10.3915 6.36569C10.129 6.51571 9.77287 6.6 9.40156 6.6C9.03026 6.6 8.67416 6.51571 8.41161 6.36569C8.14906 6.21566 8.00156 6.01217 8.00156 5.8ZM6.60156 8.2C6.60156 7.905 7.01865 7.66667 7.5349 7.66667H9.40156C9.91781 7.66667 10.3349 7.905 10.3349 8.2V11.9333H11.2682C11.7845 11.9333 12.2016 12.1717 12.2016 12.4667C12.2016 12.7617 11.7845 13 11.2682 13H7.5349C7.01865 13 6.60156 12.7617 6.60156 12.4667C6.60156 12.1717 7.01865 11.9333 7.5349 11.9333H8.46823V8.73333H7.5349C7.01865 8.73333 6.60156 8.495 6.60156 8.2Z"
          fill={fill}
        />
      </svg>
    </>
  );
};

IconInformation.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};
