import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

import specialCardBg from "./assets/special-card-bg.jpg";
import InfoIcon, { TooltipType } from "components/InfoIcon";
import { teacherInfoIconBlock } from "components/InfoIcon/teacherInfoIcon";
import makeStyles from "pages/style";

function SpecialCard({ progress, diff }) {
  const classes = makeStyles();
  return (
    <MDBox
      width="100%"
      position="relative"
      overflow="hidden"
      cursor="pointer"
      sx={({ functions: { pxToRem }, breakpoints }) => ({
        height: pxToRem(120),
        borderRadius: pxToRem(15),
        backgroundImage: `url(${specialCardBg})`,
        backgroundSize: "cover",

        [breakpoints.down(1920)]: {
          height: pxToRem(120),
        },

        [breakpoints.down(1600)]: {
          height: pxToRem(100),
        },
      })}
    >
      <MDBox
        width="100%"
        height="100%"
        top={0}
        left={0}
        position="absolute"
        sx={{
          opacity: 0.75,
          background: "linear-gradient(122deg, #11cdef 9.06%, #1171ef 88.15%)",
          position: "absolute",
          zIndex: 0,
        }}
      ></MDBox>
      <MDBox
        sx={({ functions: { pxToRem }, breakpoints }) => ({
          zIndex: 1,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        })}
      >
        <InfoIcon
          className={classes.containerInfoIconCard}
          teacherInfoIconBlock={teacherInfoIconBlock.KPI_Score_YTD}
          type={TooltipType.CARD}
        />
        <MDTypography
          variant="h3"
          sx={({ typography, palette, breakpoints }) => ({
            color: palette.white.main,
            textAlign: "center",
            fontFamily: typography.lexendFont,
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "162.5%",
            letterSpacing: "-0.8px",

            [breakpoints.down(1600)]: {
              fontSize: 12,
            },
          })}
        >
          KPI SCORE - YTD
        </MDTypography>
        <MDTypography
          variant="h2"
          sx={({ typography, palette, breakpoints }) => ({
            color: palette.white.main,
            textAlign: "center",
            fontFamily: typography.lexendFont,
            fontSize: 30,
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "130%",
            letterSpacing: "-0.8px",

            [breakpoints.down(1600)]: {
              fontSize: 20,
            },
          })}
        >
          {progress}%
        </MDTypography>
        <MDBox
          sx={({ functions: { pxToRem }, breakpoints }) => ({
            display: "flex",
            height: pxToRem(28),
            padding: pxToRem(8),
            justifyContent: "center",
            alignItems: "center",
            gap: pxToRem(8),
            flexShrink: 0,
            background: "#009a58",
            width: "100%",

            [breakpoints.down(1600)]: {
              height: pxToRem(20),
              padding: pxToRem(4),
            },
          })}
        >
          <MDTypography
            sx={({ typography, breakpoints }) => ({
              color: "#f6f9fc",
              textAlign: "center",
              fontFamily: typography.lexendFont,
              fontSize: 14,
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "150%",

              [breakpoints.down(1600)]: {
                fontSize: 9,
              },
            })}
          >
            {diff}% since last month
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

SpecialCard.propTypes = {
  progress: PropTypes.number,
  diff: PropTypes.number,
};

export default SpecialCard;
