import * as React from "react";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import MDEditor from "components/MDEditor";
import { East, West } from "@mui/icons-material";
import MDInput from "components/MDInput";
import { titleInputStyle, titleStyle } from "./styles";
import { useCreateTemplateMutation } from "shared/redux/endpoints/admin";
import { useUpdateTemplateMutation } from "shared/redux/endpoints/admin";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "min(90%, 80vh)",
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  outline: 0,
};

export default function CreateTemplate({ open, handleClose, setOpenSuccessSB, template, refetch }) {
  const [errors, setErrors] = React.useState({});
  const [create, {}] = useCreateTemplateMutation();
  const [update, {}] = useUpdateTemplateMutation();
  const [titleEn, setTitleEn] = React.useState("");
  const [contentEn, setContentEn] = React.useState("");
  const [titleVn, setTitleVn] = React.useState("");
  const [contentVn, setContentVn] = React.useState("");

  React.useEffect(() => {
    if (template) {
      setTitleEn(template.titleEn);
      setTitleVn(template.titleVi);
      setContentEn(template.contentEn);
      setContentVn(template.contentVi);
    }
  }, [template]);

  const validateFields = (data) => {
    const errors = {};

    // Validate tiêu đề và nội dung cho tiếng Anh
    if (!data.titleEn || data.titleEn.trim() === "") {
      errors.titleEn = "Field is required";
    }

    if (!contentEn || contentEn.trim() === "") {
      errors.contentEn = "Field is required";
    }

    // Validate tiêu đề và nội dung cho tiếng Việt
    if (!data.titleVn || data.titleVn.trim() === "") {
      errors.titleVn = "Field is required";
    }

    if (!contentVn || contentVn.trim() === "") {
      errors.titleVn = "Field is required";
    }

    return errors;
  };

  React.useEffect(() => {
    const errors = {};
    if (titleEn) errors.titleEn = "";
    if (titleVn) errors.titleVn = "";
    if (contentEn) errors.contentEn = "";
    if (contentVn) errors.contentVn = "";
    setErrors(errors);
  }, [titleEn, titleVn, contentEn, contentVn]);

  const handleOpenSuccess = () => setOpenSuccessSB(true);

  const handleReset = () => {
    setContentEn("");
    setContentVn("");
    setTitleEn("");
    setTitleVn("");
  };

  const handleSubmit = async () => {
    const validationErrors = validateFields({ titleEn, titleVn });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      const formData = {
        target: "TEACHERS",
        titleEn,
        titleVi: titleVn,
        contentEn,
        contentVi: contentVn,
        notificationType: "ANNOUNCEMENT",
      };
      if (template) {
        const response = await update({
          ...formData,
          notificationContentId: template.notificationContentId,
        });

        if (response?.data?.status) {
          handleClose();
          handleOpenSuccess();
          setErrors({});
          handleReset();
          refetch();
        }
      } else {
        const response = await create(formData);

        if (response?.data?.status) {
          handleClose();
          handleOpenSuccess();
          setErrors({});
          handleReset();
          refetch();
        }
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox component="form" sx={style}>
        <MDTypography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ p: 3, pb: 2, fontFamily: "Lexend, san-serif" }}
        >
          Notification
        </MDTypography>
        <MDBox
          sx={({ breakpoints }) => ({
            display: "flex",
            px: 3,
            justifyContent: "space-between",
            alignItems: "center",

            [breakpoints.down("md")]: {
              flexDirection: "column",
            },
          })}
        >
          <MDBox sx={{ flexGrow: 1 }}>
            <MDTypography sx={(theme) => titleStyle(theme)}>English title</MDTypography>
            <MDInput
              error={!!errors.titleEn}
              success={!!titleEn}
              value={titleEn}
              onChange={(e) => setTitleEn(e.target.value)}
              sx={(theme) => titleInputStyle(theme)}
              placeholder="Set a title here"
            ></MDInput>
            {errors.titleEn && (
              <MDTypography fontSize="12px" color="error">
                {errors.titleEn}
              </MDTypography>
            )}
            <MDTypography sx={(theme) => titleStyle(theme)}>Body</MDTypography>

            <MDEditor
              value={contentEn}
              defaultValue={template?.contentEn ?? ""}
              setValue={setContentEn}
            />
            {errors.contentEn && (
              <MDTypography fontSize="12px" color="error">
                {errors.contentEn}
              </MDTypography>
            )}
          </MDBox>
          <MDBox
            sx={{ display: "flex", flexDirection: "column", flexGrow: 0, padding: "0px 24px" }}
          >
            <West sx={{ mr: 1 }} />
            <East sx={{ ml: 1 }} />
          </MDBox>
          <MDBox sx={{ flexGrow: 1 }}>
            <MDTypography sx={(theme) => titleStyle(theme)}>Vietnamese title</MDTypography>
            <MDInput
              error={!!errors.titleVn}
              success={!!titleVn}
              value={titleVn}
              onChange={(e) => setTitleVn(e.target.value)}
              sx={(theme) => titleInputStyle(theme)}
              placeholder="Set a title here"
            ></MDInput>
            {errors.titleVn && (
              <MDTypography fontSize="12px" color="error">
                {errors.titleVn}
              </MDTypography>
            )}
            <MDTypography sx={(theme) => titleStyle(theme)}>Body</MDTypography>
            <MDEditor
              value={contentVn}
              defaultValue={template?.contentVi ?? ""}
              setValue={setContentVn}
            />
            {errors.contentVn && (
              <MDTypography fontSize="12px" color="error">
                {errors.contentVn}
              </MDTypography>
            )}
          </MDBox>
        </MDBox>
        <MDBox display="flex" sx={{ p: 3, pt: 2, width: "100%" }}>
          <Button
            variant="contained"
            sx={({ functions: { pxToRem } }) => ({
              mr: 2,
              color: "#FFFFFF",
              backgroundColor: "#5E72E4",
              fontFamily: "Lexend, san-serif",
              height: pxToRem(30),
              minHeight: pxToRem(30),
              textTransform: "unset",
            })}
            component="label"
            role={undefined}
            tabIndex={-1}
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            sx={({ palette, functions: { pxToRem } }) => ({
              color: palette.textColors.primary,
              borderColor: `${palette.textColors.primary}!important`,
              fontFamily: "Lexend, san-serif",
              height: pxToRem(30),
              minHeight: pxToRem(30),
              textTransform: "unset",
            })}
            onClick={handleReset}
          >
            Remove
          </Button>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

CreateTemplate.propTypes = {
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
  openSuccessSB: PropTypes.bool,
  setOpenSuccessSB: PropTypes.func,
  template: PropTypes.any,
  refetch: PropTypes.any,
};
