import { noteTypography } from "shared/styles/style";
import { descriptionTypography } from "shared/styles/style";
import { titleTypography } from "shared/styles/style";

export const containerStyle = ({ palette, breakpoints, functions: { pxToRem } }, { index }) => ({
  borderRadius: pxToRem(15),
  border: "1px solid #e2e2e2",
  background: palette.white.main,
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.1)",
  padding: `${pxToRem(16)} 0`,
  height: "100%",

  [breakpoints.down(1600)]: {
    borderRadius: pxToRem(12),
    padding: `${pxToRem(12)} 0`,
  },
});

export const headerStyle = ({ breakpoints, functions: { pxToRem } }, { index }) => ({
  ...titleTypography(),
  lineHeight: 1.35,
  letterSpacing: pxToRem(-0.8),
  marginBottom: pxToRem(16),
  padding: `0 0 0 ${pxToRem(24)}`,
  //...(index === 1 && { color: "#FFFFFF" }),

  [breakpoints.down(1600)]: {
    fontSize: `${14}px !important`,
    marginBottom: pxToRem(10),
    padding: `0 0 0 ${pxToRem(16)}`,
  },
});

export const itemStyle = ({ breakpoints, functions: { pxToRem } }, { index }) => ({
  padding: `${pxToRem(8)} ${pxToRem(24)}`,
  display: "flex",
  alignItems: "center",
  gap: pxToRem(16),
  cursor: "pointer",

  "&:hover": {
    background: "#C4C4C4",
  },

  [breakpoints.down(1600)]: {
    padding: `${pxToRem(4)} ${pxToRem(16)}`,
    gap: pxToRem(12),
  },
});

export const logoStyle = ({ palette, breakpoints, functions: { pxToRem } }, { index }) => ({
  width: pxToRem(36),
  height: pxToRem(36),
  background: "#1576BC",
  borderRadius: pxToRem(12),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  [breakpoints.down(1600)]: {
    width: pxToRem(28),
    height: pxToRem(28),
  },
});

export const titleStyle = (
  { breakpoints, palette, typography, functions: { pxToRem } },
  { index }
) => ({
  ...descriptionTypography(breakpoints),
  lineHeight: 1.625,
  letterSpacing: pxToRem(-0.8),
  marginBottom: 0,
  //...(index === 1 && { color: "#FFFFFF" }),
});

export const subTitleStyle = ({ breakpoints, palette, typography }, { index }) => ({
  ...noteTypography(breakpoints),
  lineHeight: 1.25,
  marginBottom: 0,
  // ...(index === 1 && { color: "#FFFFFF", opacity: 0.5 }),
});

export const logoImageStyle = ({ breakpoints, functions: { pxToRem } }) => ({
  display: "block",
  width: pxToRem(36),
  height: pxToRem(36),

  [breakpoints.down(1600)]: {
    width: pxToRem(28),
    height: pxToRem(28),
  },
});
