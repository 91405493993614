import PropTypes from "prop-types";

export const UploadIcon = ({ width = 17, height = 15, fill = "#717171" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M9.1712 3.20239V10.3127C9.1712 10.8313 8.71582 11.2502 8.15217 11.2502C7.58853 11.2502 7.13315 10.8313 7.13315 10.3127V3.20239L4.79577 5.35278C4.39772 5.71899 3.75127 5.71899 3.35322 5.35278C2.95516 4.98657 2.95516 4.39185 3.35322 4.02563L7.42931 0.275635C7.82736 -0.0905762 8.4738 -0.0905762 8.87186 0.275635L12.9479 4.02563C13.346 4.39185 13.346 4.98657 12.9479 5.35278C12.5499 5.71899 11.9034 5.71899 11.5054 5.35278L9.1712 3.20239ZM2.03804 10.3127H6.11413C6.11413 11.3469 7.02807 12.1877 8.15217 12.1877C9.27628 12.1877 10.1902 11.3469 10.1902 10.3127H14.2663C15.3904 10.3127 16.3043 11.1536 16.3043 12.1877V13.1252C16.3043 14.1594 15.3904 15.0002 14.2663 15.0002H2.03804C0.913935 15.0002 0 14.1594 0 13.1252V12.1877C0 11.1536 0.913935 10.3127 2.03804 10.3127ZM13.7568 13.3596C13.9595 13.3596 14.1539 13.2855 14.2972 13.1537C14.4405 13.0218 14.5211 12.843 14.5211 12.6565C14.5211 12.47 14.4405 12.2912 14.2972 12.1593C14.1539 12.0274 13.9595 11.9534 13.7568 11.9534C13.5541 11.9534 13.3597 12.0274 13.2164 12.1593C13.073 12.2912 12.9925 12.47 12.9925 12.6565C12.9925 12.843 13.073 13.0218 13.2164 13.1537C13.3597 13.2855 13.5541 13.3596 13.7568 13.3596Z"
        fill={fill}
      />
    </svg>
  );
};

UploadIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};
