import { api } from "shared/redux/api";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    uploadImage: build.mutation({
      query: (arg) => ({
        url: "/private/notification/v1/upload/image",
        method: "POST",
        body: arg,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useUploadImageMutation } = injectedRtkApi;
