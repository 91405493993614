import { Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import HubImage from "assets/images/apollo-english/HUB.png";
import PropTypes from "prop-types";
import { titleTypography } from "shared/styles/style";
import { descriptionTypography } from "shared/styles/style";

function camelCaseToNormal(text) {
  // Insert a space before each uppercase letter and capitalize the first letter
  return text
    .replace(/([A-Z])/g, " $1") // Add space before uppercase letters
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
}

const sample = [
  {
    name: "Engagement",
    detail: ["attendanceValue", "talktimeValue", "h5pCompletionValue"],
    label: ["Attendance", "Talktime", "H5P Completion"],
  },
  {
    name: "Home Learning",
    detail: ["aarCompletionValue", "workbookCompletionValue", "wsCompletionValue"],
    label: ["Active App Review completion", "Workbook completion", "Workspace completion"],
  },
  {
    name: "Performance",
    detail: ["h5pScoreValue", "aarScoreValue", "lastGradebookScoreValue"],
    label: ["H5P score", "Active App Review score", "Last Gradebook Score"],
  },
];

function Performance({ data }) {
  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      columnSpacing={3}
      sx={({ breakpoints, functions: { pxToRem } }) => ({
        borderRadius: pxToRem(16),
        background: "#fff",
        padding: `${pxToRem(36)} 0 0`,

        [breakpoints.down("xxl")]: {
          rowGap: 2.5,
          flexDirection: "column",
        },

        [breakpoints.down("md")]: {
          rowGap: 2,
        },
      })}
    >
      <MDBox
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        sx={({ breakpoints }) => ({
          rowGap: 2,
          [breakpoints.down("lg")]: {
            flexDirection: "column",
            alignItems: "flex-start",
          },
        })}
      >
        <MDTypography
          sx={() => ({
            ...titleTypography,
            lineHeight: 1.625,
            letterSpacing: "-0.8px",
            paddingLeft: "12px",
            color: "#2D3748",
          })}
        >
          Details of student’s performance
        </MDTypography>
      </MDBox>

      <MDBox width="100%">
        <MDBox
          data-horizontal-scroll
          sx={({ breakpoints, functions: { pxToRem } }) => ({
            overflowX: "scroll",
            maxHeight: pxToRem(530),
            width: "100%",
            background: "#fff",

            "&::-webkit-scrollbar": {
              width: pxToRem(6),
              height: 0,
            },

            "&::-webkit-scrollbar-thumb": {
              background: "#8898aa",
              borderRadius: pxToRem(3),
            },

            [breakpoints.down("md")]: {
              "&::-webkit-scrollbar": {
                display: "block",
              },
            },
          })}
        >
          <Table
            sx={({ breakpoints, typography, functions: { pxToRem } }) => ({
              color: "#333",
              height: "100%",
              width: "800px",
              minWidth: "100%",
              fontFamily: typography.lexendFont,
              fontSize: 14,
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: pxToRem(0.42),
              "& *": {
                ...descriptionTypography(breakpoints),
              },
            })}
          >
            <MDBox component="thead" sx={{ borderBottom: "2px solid #DEE2E6" }}>
              <TableRow
                sx={({ breakpoints, typography }) => ({
                  height: "56px",
                  textAlign: "left",
                  fontFamily: typography.lexendFont,
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: 1.5,
                  "& *": {
                    textTransform: "uppercase",
                    ...descriptionTypography(breakpoints),
                  },
                })}
              >
                <MDBox
                  component="th"
                  sx={({ breakpoints }) => ({
                    paddingLeft: 2,
                    ...descriptionTypography(breakpoints),
                  })}
                >
                  Category
                </MDBox>
                <MDBox component="th">Criteria</MDBox>
                <MDBox component="th">Actual performance</MDBox>
                <MDBox component="th">Total category score</MDBox>
              </TableRow>
            </MDBox>

            <TableBody
              sx={({ functions: { pxToRem } }) => ({
                maxHeight: pxToRem(454),
                overflowY: "scroll",
              })}
            >
              {data.map((itemData, index) =>
                sample.map((item, index) => (
                  <>
                    <TableRow>
                      <TableCell rowSpan={3}>{item.name}</TableCell>
                      <TableCell>
                        <MDTypography
                          variant="span"
                          sx={({ breakpoints }) => ({
                            lineHeight: 1.625,
                            ...descriptionTypography(breakpoints),
                          })}
                        >
                          {item.label[0]}
                        </MDTypography>
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            gap: "8px",
                            flexWrap: "wrap",
                          }}
                        >
                          {Object.keys(itemData)
                            .filter((key) => key === item.detail[0])
                            .map((key) => {
                              if (itemData[key] === null) {
                                return null;
                              }
                              return (
                                <MDBox
                                  key={`${index}-${key}`}
                                  sx={({ functions: { pxToRem } }) => ({
                                    backgroundColor: "#F34F4F",
                                    px: "4px",
                                    py: "2px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: pxToRem(6),
                                    display: "inline-block",
                                  })}
                                >
                                  <MDTypography
                                    variant="span"
                                    sx={({ breakpoints }) => ({
                                      ...descriptionTypography(breakpoints),
                                      lineHeight: 1.625,
                                      color: "#FFFFFF !important",
                                    })}
                                  >
                                    {`${Math.round(itemData[key])}%`}
                                  </MDTypography>
                                </MDBox>
                              );
                            })}
                        </div>
                      </TableCell>
                      <TableCell rowSpan={3}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <MDTypography
                            variant="span"
                            sx={({ breakpoints }) => ({
                              lineHeight: 1.625,
                              ...descriptionTypography(breakpoints),
                              textAlign: "center",
                              fontWeight: "700 !important",
                              fontSize: "20px !important",
                            })}
                          >
                            {index === 0 &&
                              (itemData.engagementFinalScore === null
                                ? ""
                                : Math.round(itemData.engagementFinalScore))}
                            {index === 1 &&
                              (itemData.homeLearningFinalScore === null
                                ? ""
                                : Math.round(itemData.homeLearningFinalScore))}
                            {index === 2 &&
                              (itemData.performanceFinalScore === null
                                ? ""
                                : Math.round(itemData.performanceFinalScore))}
                          </MDTypography>
                        </div>
                      </TableCell>
                    </TableRow>
                    {item.detail
                      .filter((_, index) => index !== 0)
                      .map((detail, __index) => (
                        <TableRow
                          key={__index}
                          sx={({ breakpoints }) => ({
                            height: "50px",
                            textAlign: "left",
                            lineHeight: 1.5,
                            borderBottom:
                              __index === 1 && index === 2
                                ? "3px solid #DEE2E6"
                                : "1px solid #DEE2E6",
                            "& *": {
                              ...descriptionTypography(breakpoints),
                            },
                          })}
                        >
                          <TableCell>
                            <MDTypography
                              variant="span"
                              sx={({ breakpoints }) => ({
                                lineHeight: 1.625,
                                ...descriptionTypography(breakpoints),
                              })}
                            >
                              {item.label[__index + 1]}
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                gap: "8px",
                                flexWrap: "wrap",
                              }}
                            >
                              {Object.keys(itemData)
                                .filter((key) => key === detail)
                                .map((key, index) => {
                                  if (itemData[key] === null) {
                                    return null;
                                  }
                                  return (
                                    <MDBox
                                      key={index}
                                      sx={({ functions: { pxToRem } }) => ({
                                        backgroundColor: "#F34F4F",
                                        px: "4px",
                                        py: "2px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: pxToRem(6),
                                        display: "inline-block",
                                      })}
                                    >
                                      <MDTypography
                                        variant="span"
                                        sx={({ breakpoints }) => ({
                                          ...descriptionTypography(breakpoints),
                                          lineHeight: 1.625,
                                          color: "#FFFFFF !important",
                                        })}
                                      >
                                        {`${Math.round(itemData[key])}%`}
                                      </MDTypography>
                                    </MDBox>
                                  );
                                })}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

Performance.propTypes = {
  data: PropTypes.any,
};

export { Performance };
