import { descriptionTypography } from "shared/styles/style";

export const mySelfStyles = (theme) => {
  const { breakpoints } = theme;

  return {
    margin: `0 auto`,

    [breakpoints.up("xxl")]: {
      width: `calc(100% - 150px)`,
    },

    [breakpoints.up("xl")]: {
      width: `calc(100% - 120px)`,
    },

    [breakpoints.up("lg")]: {
      width: `calc(100% - 100px)`,
    },

    [breakpoints.up("md")]: {
      width: `calc(100% - 80px)`,
    },

    [breakpoints.down("md")]: {
      width: `calc(100% - 40px)`,
    },
  };
};

export const headerStyles = (theme) => {
  const {
    breakpoints,
    functions: { pxToRem },
  } = theme;

  return {
    margin: `${pxToRem(30)} auto 0`,
    width: "100%",
    background: "#ffffff",
    padding: pxToRem(22),
    borderRadius: pxToRem(15),

    [breakpoints.down("xxxl")]: {
      rowGap: 3,
      justifyContent: "flex-start",
    },

    [breakpoints.down("xxl")]: {
      rowGap: 2.5,
    },

    [breakpoints.down("md")]: {
      rowGap: 2,
    },
  };
};

export const sideNavItemStyles = (theme, { active }) => {
  const {
    functions: { pxToRem },
  } = theme;

  return {
    display: "flex",
    padding: "24px",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: pxToRem(12),
    height: pxToRem(24),
    cursor: "pointer",
    backgroundColor: active ? "#1576BC" : "inherit",
  };
};

export const sideNavLabelStyles = ({ palette, breakpoints }, { active }) => {
  return {
    ...descriptionTypography(breakpoints),
    lineHeight: 1.5,
    color: active ? palette.white.main : "#8392AB",

    [breakpoints.down(1367)]: {
      fontSize: 13,
    },
  };
};
