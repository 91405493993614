export const titleFormStyle = ({ functions: { pxToRem } }) => ({
  fontSize: pxToRem(16),
  fontWeight: 700,
  display: "flex",
  alignItems: "center",
  gap: pxToRem(10),
});

export const titleDownload = ({ palette, functions: { pxToRem } }) => ({
  fontSize: pxToRem(14),
  fontWeight: 400,
  color: palette.textColors.secondary,
});

export const itemList = ({ palette, functions: { pxToRem } }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: `${pxToRem(8)} ${pxToRem(16)}`,
  borderBottom: `1px solid ${palette.inputBorderColor}`,
});

export const titleItem = ({ functions: { pxToRem } }) => ({
  fontSize: pxToRem(12),
  fontWeight: 700,
  color: "#344767",
  padding: `${pxToRem(8)} ${pxToRem(12)}`,
});

export const subMail = ({ palette, functions: { pxToRem } }) => ({
  fontSize: pxToRem(14),
  fontWeight: 400,
  color: palette.textColors.secondary,
});

export const boxNotiContent = ({ breakpoints, functions: { pxToRem } }) => ({
  display: "flex",
  justifyContent: "space-between",

  [breakpoints.down("md")]: {
    flexDirection: "column",
  },
});

export const groupBoxContent = ({ breakpoints, functions: { pxToRem } }) => ({
  width: "45%",
  [breakpoints.down("md")]: {
    width: "100%",
  },
});

export const container = ({ typography, functions: { pxToRem } }) => ({
  width: "100%",
  "& *": {
    fontFamily: typography.lexendFont,
  },
});

export const groupList = ({ breakpoints, functions: { pxToRem } }) => ({
  width: "45%",

  [breakpoints.down("md")]: {
    width: "100%",
  },
});

export const boxNotiIcon = ({ breakpoints, functions: { pxToRem } }) => ({
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%)",
  display: "flex",
  flexDirection: "column",
  gap: pxToRem(20),

  [breakpoints.down("md")]: {
    display: "none",
  },
});
export const boxButton = ({ palette, typography, functions: { pxToRem } }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: pxToRem(10),
});
