import { createSlice } from "@reduxjs/toolkit";
import { api } from "../api";
import moment from "moment";

const initialState = {
  notifications: [],
  notificationsToday: [],
  currentPage: 0,
  currentPageToday: 0,
  notificationCount: {},
  notificationDetail: null,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    increasePage: (state, { payload }) => {
      state.currentPage = payload;
    },
    increasePageToday: (state, { payload }) => {
      state.currentPageToday = payload;
    },
    changeStatusNotification: (state, { payload }) => {
      const { notificationId, uniqueContentId, read, type } = payload;

      if (type === "today") {
        const currentNotifications = [...state.notificationsToday];
        state.notificationsToday = currentNotifications.map((item) => {
          if (uniqueContentId) {
            return item.notificationContentEntity.uniqueContentId === uniqueContentId
              ? { ...item, status: read ? "READ" : "UNREAD" }
              : item;
          }
          return item.notificationId === notificationId
            ? { ...item, status: read ? "READ" : "UNREAD" }
            : item;
        });
      } else if (type === "older") {
        const currentNotifications = [...state.notifications];
        state.notifications = currentNotifications.map((item) => {
          if (uniqueContentId) {
            return item.notificationContentEntity.uniqueContentId === uniqueContentId
              ? { ...item, status: read ? "READ" : "UNREAD" }
              : item;
          }
          return item.notificationId === notificationId
            ? { ...item, status: read ? "READ" : "UNREAD" }
            : item;
        });
      }
    },
    readAllNotifications: (state) => {
      const currentNotiToday = [...state.notificationsToday];
      const currentNotiOlder = [...state.notifications];
      state.notificationsToday = currentNotiToday.map((item) => {
        return { ...item, status: "READ" };
      });
      state.notifications = currentNotiOlder.map((item) => {
        return { ...item, status: "READ" };
      });
    },
    updateNotifications: (state, { payload }) => {
      const currentNotifications = [...state.notifications];
      state.notifications = currentNotifications
        .concat([...payload.data])
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.notificationId === value.notificationId)
        );
    },
    updateNotificationsToday: (state, { payload }) => {
      const currentNotifications = [...state.notificationsToday];
      state.notificationsToday = currentNotifications
        .concat([...payload.data])
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.notificationId === value.notificationId)
        );
    },
    addNewNotification: (state, { payload }) => {
      const { newNotification } = payload;
      const currentNotifications = [...state.notificationsToday];
      state.notificationsToday = [
        {
          ...newNotification,
          created: moment(newNotification.created)
            .add(-7, "hour")
            .format("MMM DD, YYYY, h:mm:ss a"),
        },
        ...currentNotifications,
      ];
    },
    setNotificationDetail: (state, { payload }) => {
      state.notificationDetail = payload.notificationDetail;
    },
    clearAllNotification: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getNotificationCount.matchFulfilled, (state, { payload }) => {
      state.notificationCount = payload.data;
    });
  },
});

export default notificationSlice.reducer;

export const {
  addNewNotification,
  increasePageToday,
  increasePage,
  updateNotifications,
  changeStatusNotification,
  updateNotificationsToday,
  setNotificationDetail,
  readAllNotifications,
  clearAllNotification,
} = notificationSlice.actions;

export const selectNotifcation = (state) => state.notification;
