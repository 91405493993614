export const teacherInfoIconBlock = {
  KPI_Score_YTD: "KPI Score - YTD",
  THIS_MONTH_RENEWAL_RATE: "This month renewal rate",
  WELEARN_COURSE_PROGRESS: "WeLearn course progress",
  TEACHING_HOUR: "Teaching hour",
  CONVERSION_RATE: "Conversion rate",
  STUDENT_AT_RISK: "Student at risk",
  APOLLO_ON_AIR: "Apollo on air",
  CENTER_LEADERBOARD: "Center Leaderboard",
  WORK_STATION: "Work Station",
  LINKS: "Links",
  WEEK_MONTH: "Week/Month",
  FILTER_TABS: "Filter tabs (to do later)",
  SMALL_CALENDAR: "Small Calendar",
  BREAKDOWN_UNDER_SMALL_CALENDAR: "Breakdown under small calendar",
  REPORT_COMPLETION: "Report completion",
  FEEDBACK_COMPLETION: "Feedback completion",
  OTHER_TASK_COMPLETION: "Other task completion",
  TODO_LIST: "To-do list",
  CLASS_LIST: "Class List",
  STUDENT_LIST: "Student List",
  EPP_LIST: "EPP List",
  COMPLETION_RATE: "Completion rate",
  PHASE_1: "Phase 1",
  PHASE_2: "Phase 2",
  PHASE_3: "Phase 3",
  BASIC_INFO: "Basic Info",
  CHANGE_PASSWORD: "Change Password",
  LEAVE_TRACKING: "Leave Tracking",
};

const teacherInfoDescriptionMap = new Map([
  [teacherInfoIconBlock.KPI_Score_YTD, "Year-to-date performance based on key metrics."],
  [
    teacherInfoIconBlock.THIS_MONTH_RENEWAL_RATE,
    "Percentage of students with fees expiring and renewing this month.",
  ],
  [teacherInfoIconBlock.WELEARN_COURSE_PROGRESS, "Percentage of the course completed on WeLearn."],
  [teacherInfoIconBlock.TEACHING_HOUR, "Month-to-date teaching hours."],
  [
    teacherInfoIconBlock.CONVERSION_RATE,
    "Percentage of new enrollments from placement tests or demos.",
  ],
  [
    teacherInfoIconBlock.STUDENT_AT_RISK,
    "Students needing support based on engagement, home learning, and performance.",
  ],
  [teacherInfoIconBlock.APOLLO_ON_AIR, "Recent Apollo events and campaigns."],
  [
    teacherInfoIconBlock.CENTER_LEADERBOARD,
    "Center rankings by learning experience, business, and service metrics.",
  ],
  [teacherInfoIconBlock.WORK_STATION, "Shortcuts to essential Apollo platforms via SSO."],
  [teacherInfoIconBlock.LINKS, "Quick access to internal resources and tools."],
  [teacherInfoIconBlock.WEEK_MONTH, "Switching between weekly and monthly view."],
  [
    teacherInfoIconBlock.FILTER_TABS,
    "Calendar tabs to filter activities. Click on any colored bar to see details.",
  ],
  [teacherInfoIconBlock.SMALL_CALENDAR, "Click any date to navigate swiftly."],
  [
    teacherInfoIconBlock.BREAKDOWN_UNDER_SMALL_CALENDAR,
    "Summary of time allocation across categories.",
  ],
  [teacherInfoIconBlock.REPORT_COMPLETION, "Overview of reports' status."],
  [teacherInfoIconBlock.FEEDBACK_COMPLETION, "Overview of customer feedback's responses."],
  [teacherInfoIconBlock.OTHER_TASK_COMPLETION, "Overview of other operational tasks."],
  [
    teacherInfoIconBlock.TODO_LIST,
    "A categorized list of tasks with status to prioritize. Click to see details.",
  ],
  [
    teacherInfoIconBlock.CLASS_LIST,
    "See your class list with student counts for each performance status.",
  ],
  [
    teacherInfoIconBlock.STUDENT_LIST,
    "See your student list with their latest performance status.",
  ],
  [teacherInfoIconBlock.EPP_LIST, "See your student list with approaching fee expiration."],
  [teacherInfoIconBlock.COMPLETION_RATE, "Percentage of the course completed on WeLearn."],
  [teacherInfoIconBlock.PHASE_1, "Click to access training modules and courses in Phase 1."],
  [teacherInfoIconBlock.PHASE_2, "Click to access training modules and courses in Phase 2."],
  [teacherInfoIconBlock.PHASE_3, "Click to access training modules and courses in Phase 3."],
  [teacherInfoIconBlock.BASIC_INFO, "View and update your personal information."],
  [teacherInfoIconBlock.CHANGE_PASSWORD, "Update your account password for secure access."],
  [
    teacherInfoIconBlock.LEAVE_TRACKING,
    "Track your leave submission records with their status of approval.",
  ],
]);

export default teacherInfoDescriptionMap;
