import * as Yup from "yup";

export const form = {
  formId: "change-password-1st-time-form",
  formField: {
    password: {
      name: "password",
      label: "Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg:
        "Password must contain at least 8 characters, at most 20 characters, one uppercase, one number and one special case character",
    },
  },
};

const {
  formField: { password },
} = form;

export const initialValues = {
  [password.name]: "",
};

export const validations = Yup.object().shape({
  [password.name]: Yup.string()
    .required(password.errorMsg)
    .matches(/^.*(?=.{8,20})(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/, password.invalidMsg),
});
