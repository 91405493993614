import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { containerStyle, headerStyle } from "../styles/ContractItem";
import typography from "assets/theme/base/typography";
import palette from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";
import { titleTypography } from "shared/styles/style";
import { noteTypography } from "shared/styles/style";
import {
  useGetLeaveInformationMutation,
  useGetRemainingLeaveMutation,
} from "../../../shared/redux/endpoints/employee";
import { useEffect, useState } from "react";
import { selectAuth } from "../../../shared/redux/slices/authSlice";
import { useSelector } from "react-redux";
import { Loading } from "../../../shared/components/ui/Loading";
import MDButton from "../../../components/MDButton";
import NotFound from "assets/images/apollo-english/not-found.png";

const customStyle = {
  "& .MuiInputBase-root, & .MuiInputBase-root .MuiInputBase-input": {
    lineHeight: "100%",
  },

  "& .MuiInputBase-root .MuiInputBase-input": {
    padding: `${pxToRem(9.5)} ${pxToRem(14)}`,
  },

  "& input, & input::placeholder": {
    fontSize: "16px !important",
    lineHeight: "1.5!important",
    fontWeight: 400,
    fontFamily: typography.lexendFont,
  },

  "& input": {
    paddingLeft: `${pxToRem(14)} !important`,
  },

  "& label": {
    fontSize: "18px !important",
    fontWeight: 700,
    fontFamily: typography.lexendFont,
    color: palette.textColors.primary,
    top: "-2px",
  },

  "& legend": {
    fontSize: "16px !important",
  },
};

// eslint-disable-next-line
export const RemainingLeaves = ({ last }) => {
  const [getLeaveInformation, { isLoading }] = useGetRemainingLeaveMutation();
  const [data, setData] = useState({});
  const [shows, setShows] = useState({});

  const auth = useSelector(selectAuth);

  const fetchLeaveInformation = async (type) => {
    const response = await getLeaveInformation({
      empCode: auth.user.empCode,
      type,
    });

    if (response?.data) {
      const result = response.data.data.Result[0];
      setData((data) => {
        return {
          ...data,
          [`type-${type}`]: { result },
        };
      });
    }
  };

  const setShow = async (type) => {
    setShows((show) => {
      return {
        ...show,
        [`type-${type}`]: true,
      };
    });
  };

  useEffect(() => {
    if (auth.user.empCode) {
      fetchLeaveInformation(3);
    }
    setShow(3);
  }, [auth]);

  const content = !auth.user.empCode ? (
    <MDBox sx={(theme) => ({ ...headerStyle(theme), mb: 3 })}>
      <MDBox
        sx={(theme) => ({
          ...containerStyle(theme),
        })}
      >
        <MDTypography sx={({ breakpoints }) => ({ ...titleTypography(breakpoints) })}>
          Data not found
        </MDTypography>
        <MDBox
          sx={{
            height: 400,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            fontSize: "24px",

            objectFit: "contain",
          }}
          component="img"
          src={NotFound}
        >
          {/* <MDTypography
            sx={({ breakpoints }) => ({
              ...titleTypography(breakpoints),
            })}
          >
            Data not found
          </MDTypography> */}
        </MDBox>
      </MDBox>
    </MDBox>
  ) : (
    <>
      <MDBox sx={(theme) => ({ ...headerStyle(theme), mb: 3 })}>
        <MDBox
          sx={(theme) => ({
            ...containerStyle(theme),
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            [theme.breakpoints.down(768)]: {
              alignItems: "flex-start",
              gap: 1.5,
              flexDirection: "column",
            },
          })}
        >
          <MDTypography sx={({ breakpoints }) => ({ ...titleTypography(breakpoints) })}>
            Remaining leaves
          </MDTypography>
          <MDBox
            sx={({ breakpoints }) => ({
              background: "linear-gradient(338.08deg, #2DCEC6 27.53%, #2DCE94 61.45%)",
              width: 250,
              height: 50,
              borderRadius: "6px",
              display: "flex",
              alignItems: "center",

              [breakpoints.down(768)]: {
                width: "100%",
              },
            })}
          >
            <MDTypography
              sx={({ breakpoints }) => ({ ...titleTypography(breakpoints), color: "#FFF", pl: 1 })}
            >
              You have {shows?.["type-3"] ? data?.["type-3"]?.result?.Value ?? 0 : "..."} days left
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox sx={(theme) => ({ ...headerStyle(theme), mb: 3 })}>
        <MDBox
          sx={(theme) => ({
            ...containerStyle(theme),
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: 0,
          })}
        >
          <MDBox
            sx={({ breakpoints }) => ({
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              [breakpoints.down(768)]: {
                alignItems: "flex-start",
                gap: 1.5,
                flexDirection: "column",
              },
            })}
          >
            <MDBox>
              <MDTypography sx={({ breakpoints }) => ({ ...titleTypography(breakpoints) })}>
                Check your total leaves
              </MDTypography>
              <MDTypography sx={({ breakpoints }) => ({ ...noteTypography(breakpoints) })}>
                Click CHECK to see your total days of leaves
              </MDTypography>
            </MDBox>

            <MDBox
              sx={({ breakpoints }) => ({
                background: "linear-gradient(338.08deg, #2DCEC6 27.53%, #2DCE94 61.45%)",
                width: 250,
                height: 50,
                borderRadius: "6px",
                display: "flex",
                alignItems: "center",

                [breakpoints.down(768)]: {
                  width: "100%",
                },
              })}
            >
              <MDTypography
                sx={({ breakpoints }) => ({
                  ...titleTypography(breakpoints),
                  color: "#FFF",
                  pl: 1,
                })}
              >
                You have {shows?.["type-1"] ? data?.["type-1"]?.result?.Value ?? 0 : "..."} days
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDButton
            sx={{
              mt: 1.5,
              background: "linear-gradient(338.54deg, #F75C3A 31.89%, #F53B58 62.81%)",
              color: "#FFFFFF",
              minHeight: 30,
            }}
            onClick={async () => {
              await fetchLeaveInformation(1);
              await setShow(1);
            }}
          >
            Check
          </MDButton>
        </MDBox>
      </MDBox>
      <MDBox sx={(theme) => ({ ...headerStyle(theme) })}>
        <MDBox
          sx={(theme) => ({
            ...containerStyle(theme),
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: 0,
          })}
        >
          <MDBox
            sx={({ breakpoints }) => ({
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              [breakpoints.down(768)]: {
                alignItems: "flex-start",
                gap: 1.5,
                flexDirection: "column",
              },
            })}
          >
            <MDBox>
              <MDTypography sx={({ breakpoints }) => ({ ...titleTypography(breakpoints) })}>
                Check your taken leaves
              </MDTypography>
              <MDTypography sx={({ breakpoints }) => ({ ...noteTypography(breakpoints) })}>
                Click CHECK to see the number of leaves that you have taken
              </MDTypography>
            </MDBox>
            <MDBox
              sx={({ breakpoints }) => ({
                background: "linear-gradient(338.08deg, #2DCEC6 27.53%, #2DCE94 61.45%)",
                width: 250,
                height: 50,
                borderRadius: "6px",
                display: "flex",
                alignItems: "center",

                [breakpoints.down(768)]: {
                  width: "100%",
                },
              })}
            >
              <MDTypography
                sx={({ breakpoints }) => ({
                  ...titleTypography(breakpoints),
                  color: "#FFF",
                  pl: 1,
                })}
              >
                You have taken {shows?.["type-2"] ? data?.["type-2"]?.result?.Value ?? 0 : "..."}{" "}
                days
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDButton
            sx={{
              mt: 1.5,
              background: "linear-gradient(338.54deg, #F75C3A 31.89%, #F53B58 62.81%)",
              color: "#FFFFFF",
              minHeight: 30,
            }}
            onClick={async () => {
              await fetchLeaveInformation(2);
              await setShow(2);
            }}
          >
            Check
          </MDButton>
        </MDBox>
      </MDBox>
    </>
  );
  return last ? <MDBox sx={{ minHeight: "calc(100vh - 24px)" }}>{content}</MDBox> : content;
};
